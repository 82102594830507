import React from "react";

const CaptureData = () => {
  return (
    <>
      <div className="container mt-3 col-12">
        <div className="col-lg-10">
          <div className="d-flex align-items-center">
            <div className="ajax">
              <img
                className="w-100"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTpBkR43AyRH6IoP0uHB3pSqOaZo98npYVrR2b6RcVvTdQR5Cz"
              />
            </div>
            <div>
              <h6 className="text-primary fw-bold ms-3">View Captured Data</h6>
            </div>
          </div>
          <div className="bg-aqua p-1 rounded mt-3">
            <h4 className="text-center fw-bold">Data Capture Page</h4>
          </div>
          <div className="mt-3">
            <p>
              This page is designed to capture any parameters sent and store
              them in a file and a database table. It loops through the POST and
              GET parameters and records them to a file named captured-data.txt.
              On this system, the file should be found at{" "}
              <b>C:\Users\hp\AppData\Local\Temp\captured-data.txt.</b> The page
              also tries to store the captured data in a database table named
              captured_data and logs the captured data. There is another page
              named <a href="#">captured-data.php</a> that attempts to list the
              contents of this table.
            </p>
          </div>
          <div>
            <h6>
              The data captured on this request is: page = capture-data.php
              twk_uuid_61008817649e0a0a5cce3e17 =
              "uuid":"1.HPzL5ZrW1NrxHh8aPqkhgj5gkM6QZ2Q2d92G1E2mWCFqkgtt5dmIIUuHCeDUcA5uodqKdKtdkA6qdqWgc60ZHck0H7Eu1bZmLOZpp",
              "version":3,"domain":null,"ts":1705901239817
              _ga = GA1.1.2043050009.1709031801 _ga_B9ZRBV36YX =
              GS1.1.1709034670.2.1.1709035086.0.0.0 PHPSESSID =
              ssfpbku5vp11evk1b312lg8feu showhints = 1
            </h6>
          </div>
          <p className="text-center mt-3">
            Would it be possible to hack the hacker? Assume the hacker will view
            the captured requests with a web browser.
          </p>
        </div>
      </div>
    </>
  );
};

export default CaptureData;
