import React from 'react'
import User from '../../../components/User';

const CodeViewer = () => {
    return (
        <div className='container mt-3'>
            <User user={"Source Code Viewer"}/>
            <div className='col-lg-6 mx-auto'>
                <div className='name  border border-black rounded-2 text-center mx-auto mt-3'>
                    <h5>To see the source of the file, choose and click "View File".
                        Note that not all files are listed.</h5>
                </div>
                <div className='d-flex mt-3'>
                    <div>
                        <h5>Source File Name</h5>
                    </div>
                    <select>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>
                        <option>add-to-your-blog-php</option>

                    </select>
                </div>
                <div className='text-center mt-3 '>
                    <button className='btn btn-secondary col-lg-3 border border-black'>View File</button>
                </div>
            </div>
        </div>
    )
}

export default CodeViewer