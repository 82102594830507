import React from 'react'
import { Link } from 'react-router-dom'
import UndoIcon from '@mui/icons-material/Undo';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TextField from '@mui/material/TextField';
import FindInPageIcon from '@mui/icons-material/FindInPage';
const AddYourBlog = () => {
    return (
        <>
            <div className='container p-3'>
                <div className='col-lg-9 mx-auto'>
                    <div className='bg border rounded text-center text-dark'>
                        <h1>Welcome To The Blog</h1>
                    </div>
                    <div className='col-lg-5 p-2'>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <UndoIcon className='fs-1 text-success' /><Link className='nav-link ms-3 text-success fs-4'>Back</Link>
                            </div>
                            <div className='d-flex align-items-center'>
                                <AssistWalkerIcon className='fs-1 text-primary' /><Link className='fs-4 ms-2 nav-link'>Help Me!</Link>
                            </div>
                        </div>
                        <div className='mt-3'>
                            <Link className='btn btn-warning w-100 text-white'><DownloadForOfflineIcon className='text-secondary' /> Hints and Videos</Link>
                        </div>
                    </div>
                    <div className='border border-dark rounded rounded-3'>
                        <div className='p-3'>
                            <p><u><b>Add New Blog Entry</b></u></p>
                            <div className='d-flex'>
                                <FindInPageIcon className='text-dark' /><p className='text-primary'><b>View Blogs</b></p>
                            </div>
                        </div>
                        <div className='col-lg-7 mx-auto'>

                            <h4 className='bg-secondary text-white rounded p-1 text-center'>Add blog for anonymous</h4>
                            <h5 className='text-center bgdanger rounded p-1 text-white'>Note: b,i,u tags are now allowed in blog entries</h5>
                            <textarea className='w-100'></textarea>
                            <div className='text-center p-2'>
                                <button className='btn btn-primary'>Save Blog Entry</button>
                            </div>
                            {/* <div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>nmap</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>wireshark</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>tcpdump</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>netcat</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>metasploit</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>kismet</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>Cain</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>Ettercap</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>paros</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>Burp Suite</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>Sysinternals</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>inSIDDer</p>
                                </div>
                                <div>
                                    <label><b>Your initials:</b></label>
                                    <input type='text' className='rounded'/>
                                </div>
                                <div className='text-center mt-3'>
                                    <button className='btn btn-light border border-dark'>submit vote</button>
                                </div>
                                <div className='mt-2'>
                            <h4 className='bg-success text-white rounded p-1 text-center'>No Choice Selected</h4>

                                </div>
                            </div> */}

                        </div>

                    </div>

                    <div className='mt-3 table-responsive'>
                        <div className='d-flex'>
                            <FindInPageIcon className='text-dark ms-3' /><p className='text-primary'><b>View Blogs</b></p>
                        </div>
                        <table className='table table-bordered border-dark'>
                            <thead>
                                <tr className='text-center'>
                                    <th colSpan={4}>1 Current Blog Entries</th>
                                </tr>
                                <tr>
                                    <th>Sno</th>
                                    <th>Name</th>
                                    <th>Date</th>
                                    <th>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>anonymous</td>
                                    <td>	2009-03-01 22:27:11</td>
                                    <td>An anonymous blog? Huh?</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddYourBlog