import React from "react";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const HTML5WebStorage = () => {
  return (
    <div>
      <div className=" border border-1 border-black title col-8   mx-auto  rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">HTML 5 Web Storage</p>
      </div>

      <div className=" border border-1 border-black title rounded-3 mt-4">
        <p className=" text-center fs-6 fw-bold p-1 m-0">Web Storage</p>
      </div>

      <table className=" mt-1 col-11 mx-auto">
        <tr>
          <th className="border border-1 border-black col-2">
            <p className="mx-auto m-0 text-center">Key</p>
          </th>

          <th className="border border-1 border-black col-7">
            <p className="mx-auto m-0 text-center">item</p>
          </th>

          <th className="border border-1 border-black col-2">
            <p className="mx-auto m-0 text-center">Storage Type</p>
          </th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>

        <tr>
          <th className="text-center">ChuckNorrisJoke4</th>
          <th className="text-center">
            Chuck Norris can slam a revolving door
          </th>
          <th className="text-center">Session</th>
        </tr>
      </table>

      <div className="d-flex justify-content-between mt-5 ">
        <div className="d-flex justify-content-between col-3">
          <input className="rounded-3 col-5" />

          <input className="rounded-3 col-5" />
        </div>

        <div className="d-flex col-4 ">
          <div className="d-flex col-4">
            <input type="radio" />
            <b className="m-0 p-0 my-auto">Session</b>
          </div>

          <div className="d-flex col-4">
            <input type="radio" />
            <b className="m-0 p-0 my-auto">Local</b>
          </div>

          <div className="col-4">
            <div className="btn btn-secondary rounded-3 col-12">
              <b className="text-light">Add New</b>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="d-flex ">
            <DisabledByDefaultIcon/>
            <b>Session Storage  </b>
        </div>

        <div className="d-flex ">
            <DisabledByDefaultIcon/>
            <b>Local Storage  </b>
        </div>

        <div className="d-flex ">
            <DisabledByDefaultIcon/>
            <b>All Storage  </b>
        </div>
      </div>
    </div>
  );
};

export default HTML5WebStorage;
