import React from "react";

const ArbitraryFileInclusion = () => {
  return (
    <div className="mx-auto">
      <div className=" border border-1 border-black title mx-auto  rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">
          Remote and Local File Inclusion
        </p>
      </div>

      <div>
        <b className="d-block">Current Page: arbitrary-file-inclusion.php</b>
        <b>
          Notice that the page displayed by Mutillidae is decided by the value
          in the "page" URL parameter. What could possibly go wrong?
        </b>
      </div>

      <div className=" border border-1 border-black bg-secondary mx-auto mt-5 rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">
          Local File Inclusion
        </p>
      </div>

      <div>
        <p className="fw-medium fs-6">
          PHP runs on an account (like any other user). The account has
          privileges to the local file system with the ability to read, write,
          and/or execute files. Ideally the account would only have enough
          privileges to execute php files in a certain, intended directory but
          sadly this is often not the case. Local File Inclusion occurs when a
          file to which the PHP account has access is passed as a parameter to
          the PHP function "include", "include_once", "require", or
          "require_once". PHP incorporates the content into the page. If the
          content happens to be PHP source code, PHP executes the file.
        </p>
      </div>

      <div className=" border border-1 border-black bg-secondary mx-auto mt-5 rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">
          Local File Inclusion
        </p>
      </div>

      <div>
        <p className="fw-medium fs-6">
          Remote File Inclusion occurs when the URI of a file located on a
          different server is passed to as a parameter to the PHP function
          "include", "include_once", "require", or "require_once". PHP
          incorporates the content into the page. If the content happens to be
          PHP source code, PHP executes the file.
        </p>
      </div>

      <div className="background border border-1 border-black rounded-3">
        <b>
          Note that on newer PHP servers the configuration parameters
          "allow_url_fopen" and "allow_url_include" must be set to "On".
        </b>
      </div>

      <div>
        <p className="fw-medium fs-6">
          By default, these may or may not be "On" in newer versions. For
          example, by default in XAMPP 1.8.1, "allow_url_fopen = On" by default
          but "allow_url_include = Off" by default. If you wish to try remote
          file inclusion, be sure these configuration parameters are set to "On"
          by going to the php.ini file, locating the parameters, setting their
          value to "On", and restarting the Apache service. An example of this
          configuration appears below.
        </p>
      </div>

      <div>
        <p className="text-danger">
          ;;;;;;;;;;;;;;;;;; ; Fopen wrappers ; ;;;;;;;;;;;;;;;;;; ; Whether to
          allow the treatment of URLs (like http:// or ftp://) as files. ;
          http://php.net/allow-url-fopen allow_url_fopen = On ; Whether to allow
          include/require to open URLs (like http:// or ftp://) as files. ;
          http://php.net/allow-url-include allow_url_include = On
        </p>
      </div>
    </div>
  );
};

export default ArbitraryFileInclusion;
