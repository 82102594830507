import React from 'react'

import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab3 = () => {
    return (
        <div>
            <Title title={"Lab 3: How the web really works - Intercepting HTTP Requests and Responses"} />
            <StyledLab3>
                <div className='box1 border border-dark col-5 mx-auto rounded'>
                    <h5 className='text-center p-0 m-0'>In Burp-Suite, under Proxy -> Options -> Intercept Web </h5>
                    <h5 className='text-center p-0 m-0'>Socket Messages, in which direction can Burp-Suite</h5>
                    <h5 className='text-center p-0 m-0'>intercept messages?</h5>
                </div>
                <div className='col-5 mx-auto m-3'>
                    <div className=' d-flex'>

                    <input type="radio"></input>

                        <h6 className='p-0 m-0'> Client-to-Server
                        </h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'>Server-to-Client</h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'> Bidirectional</h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'>  There is no support</h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>
                        <h6 className='p-0 m-0'> None of the above
                        </h6>
                    </div>
                </div>
                <div className=' submit col-1 mx-auto '>
                    <button className='rounded pe-3 ps-3 m-2'>
                        Submit
                    </button>
                </div>
                <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
                    <h6> Choose the best answer or view Hints and Videos</h6>
                </div>
                <div className='d-flex justify-content-center m-2 fs-5'>
                    <a className='  text-decoration-none p-2 '>
                        Previous
                    </a>
                    <div className='p-2'>|</div>
                    <a className='  text-decoration-none p-2 fs-5 '>
                        Next
                    </a>

                </div>
            </StyledLab3>
        </div>
    )
}

export default Lab3

const StyledLab3 = styled.div
    `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`