import React from 'react'
import { Link } from 'react-router-dom'
import './leader.css'

const LeaderBoard = () => {
  return (
    <>
            <div className='cover'>
                <div className='title'>
                    <div className='container p-5'>
                        <div className='font-size'>
                            <h1 className='text-white'><b>ALL MATCHES</b></h1>
                        </div>

                    </div>
                </div>
                <div className='container p-5'>
                    <div className=''>
                        <div className='col-lg-3 mt-3 hovers'>
                            <ul className='navbar nav'>
                                <li className='nav-item active'><a href='#' className=' btn btn-warning shadow '>All</a></li>
                                <li className='nav-item'><a href='#' className=' btn btn-dark shadow'>R6</a></li>
                                <li className='nav-item'><a href='#' className=' btn btn-dark '>COD</a></li>
                                <li className='nav-item'><a href='#' className=' btn btn-dark '>Valorant</a></li>
                                <li className='nav-item'><a href='#' className=' btn btn-dark '>CS:GO</a></li>

                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className='shadow decoration  p-3'>
                            <div className='text-center text-hover'>
                                <a href='#' className='text-warning'>First Strike North America </a>
                            </div>
                            <div className='d-flex justify-content-evenly p-3'>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-evenly'>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/11/4-210x178.jpg' />
                                        </div>
                                        <div className=''>
                                            <small className='m-0 p-0'>CS:GO</small>
                                            <h4 className='text-white m-0 p-0 size'>ANUBIS</h4>
                                        </div>
                                        <div>
                                            <strong className='text-white fs-2'>0</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='text-center'>
                                        <div className='text-white size'>
                                            <a href='#' className='btn btn-warning rounded rounded-pill '>UPCOMING</a>
                                        </div>
                                        <div className='text-light '>
                                            <small>DECEMBER 6,2024,5:51PM</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-evenly'>
                                        <div>
                                            <strong className='text-white fs-2'>0</strong>
                                        </div>
                                        <div className='text-end'>
                                            <small className='m-0 p-0'>CS:GO</small>
                                            <h4 className='text-white m-0 p-0 size'>KABUKI</h4>
                                        </div>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/11/6-210x178.jpg' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className='shadow decoration p-3'>
                            <div className='text-center text-hover'>
                                <a href='#' className='text-warning'>LVP - Radiant Weekend </a>
                            </div>
                            <div className='d-flex justify-content-evenly p-3'>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-evenly'>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/11/6-210x178.jpg' />
                                        </div>
                                        <div className='text-start'>
                                            <small className='m-0 p-0'>CS:GO</small>
                                            <h4 className='text-white m-0 p-0 size'>KABUKI</h4>
                                        </div>
                                        <div>
                                            <strong className='text-white fs-2'>0</strong>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='text-center'>
                                        <div className='text-white size'>
                                            <a href='#' className='btn btn-warning rounded rounded-pill '>UPCOMING</a>
                                        </div>
                                        <div className='text-light '>
                                            <small>JUNE 12,2024,6:03PM</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-evenly align-items-center'>
                                        <div>
                                            <strong className='text-white fs-2'>0</strong>
                                        </div>
                                        <div className='text-end'>
                                            <small className='m-0 p-0'>R6</small>
                                        </div>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/themes/arcane/img/defaults/default-team.jpg' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className=' shadow decoration p-3'>
                            <div className='text-center text-hover'>
                                <a href='#' className='text-warning'>mw2</a>
                            </div>
                            <div className='d-flex justify-content-evenly p-3'>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-evenly'>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/11/6-210x178.jpg' />
                                        </div>
                                        <div className=''>
                                            <small className='m-0 p-0'>CS:GO</small>
                                            <h4 className='text-white m-0 p-0 size'>KABUKI</h4>
                                        </div>
                                        <div>
                                            <strong className='text-white fs-2'>0</strong>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='text-center'>
                                        <div className='text-white size'>
                                            <a href='#' className='btn btn-warning rounded rounded-pill '>FINISHED</a>
                                        </div>
                                        <div className='text-light'>
                                            <small>JUNE 12,2024,6:03PM</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='d-flex align-items-center justify-content-evenly'>
                                        <div className='text-start'>
                                            <strong className='text-white fs-2'>20</strong>
                                        </div>
                                        <div className=''>
                                            <div className='text-end'>
                                                <small className='m-0 p-0'>COD</small>
                                            </div>
                                            <h6 className='text-white size'>LIOGHS TEAM</h6>
                                        </div>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/themes/arcane/img/defaults/default-team.jpg' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className='shadow decoration p-3'>
                            <div className='text-center text-hover'>
                                <a href='#' className='text-warning'>First Strike LATAM🔥</a>
                            </div>
                            <div className='d-flex justify-content-evenly p-3'>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-evenly align-items-center'>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/themes/arcane/img/defaults/default-team.jpg' />
                                        </div>
                                        <div className=''>
                                            <small className='m-0 p-0'>COD</small>
                                        </div>
                                        <div className='text-start'>
                                            <strong className='text-white fs-2'>0</strong>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='text-center'>
                                        <div className='text-white size'>
                                            <a href='#' className='btn btn-warning rounded rounded-pill '>PLAYING</a>
                                        </div>
                                        <div className='text-light'>
                                            <small>DECEMBER 6,2020,6:04PM</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='d-flex align-items-center justify-content-evenly'>
                                        <div>
                                            <strong className='text-white fs-2'>0</strong>
                                        </div>
                                        <div className=''>
                                            <div className='text-end'>
                                                <small className=' m-0 p-0'>COD</small>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/themes/arcane/img/defaults/default-team.jpg' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className='shadow decoration p-3'>
                            <div className='text-center text-hover'>
                                <a href='#' className='text-warning'>ADC Masters Round 1</a>
                            </div>
                            <div className='d-flex align-items-center justify-content-evenly p-3'>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-evenly align-items-center'>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/11/6-210x178.jpg' />
                                        </div>
                                        <div className=''>
                                            <small className='m-0 p-0'>CS:GO</small>
                                            <h4 className='text-white size'>KABUKI</h4>
                                        </div>
                                        <div>
                                            <strong className='text-white fs-2'>54</strong>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='text-center'>
                                        <div className='text-white size'>
                                            <a href='#' className='btn btn-warning rounded rounded-pill '>FINISHED</a>
                                        </div>
                                        <div className='text-light'>
                                            <small>DECEMBER 6,2020,6:04PM</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='d-flex align-items-center justify-content-evenly'>
                                        <div>
                                            <strong className='text-white fs-2'>75</strong>
                                        </div>
                                        <div className=''>
                                            <div className='text-end'>
                                                <small className='m-0 p-0'>CS:GO</small>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/themes/arcane/img/defaults/default-team.jpg' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12'>
                        <div className='shadow decoration p-3'>
                            <div className='text-center text-hover'>
                                <a href='#' className='text-warning'>Flames Cup</a>
                            </div>
                            <div className='d-flex justify-content-evenly p-3'>
                                <div className='col-lg-3'>
                                    <div className='d-flex justify-content-evenly align-items-center'>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/11/7-210x178.jpg' />
                                        </div>

                                        <div className=''>
                                            <small className='m-0 p-0'>VALORANT</small>
                                            <h4 className='text-white size'>WOLFGAMING</h4>
                                        </div>
                                        <div>
                                            <strong className='text-white fs-2'>83</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='text-center'>
                                        <div className='text-white size'>
                                            <a href='#' className='btn btn-warning rounded rounded-pill '>FINISHED</a>
                                        </div>
                                        <div className='text-light'>
                                            <small>DECEMBER 6,2020,5:44PM</small>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='d-flex align-items-center justify-content-evenly'>
                                        <div>
                                            <strong className='text-white fs-2'>62</strong>
                                        </div>
                                        <div className=''>
                                            <div className='text-end'>
                                                <small className='m-0 p-0'>VALORANT</small>
                                                <h4 className='text-white size'>ANUBIS</h4>
                                            </div>
                                        </div>
                                        <div className='item'>
                                            <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/11/4-210x178.jpg' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>






























































                    <div className='d-flex flex-wrap justify-content-evenly mt-5'>
                        <div className='col-lg-3 col-md-6 col-sm-6 col-12 p-3'>
                            <div className='border-end border-5 border-warning h-25'>
                                <div className='icon'>
                                    <img className='w-100' src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/11/logo1.png' />
                                </div>
                                <div className='text-white'>
                                    <p>Lorem ipsum dolor sit amet, consectetur<br /> adipiscing elit. Nunc non interdum justo.<br /> Pellentesque sit amet rhoncus erat.</p>
                                </div>
                                <div className='d-flex under'>
                                    <i class="bi bi-steam text-warning"></i>
                                    <i class="bi bi-instagram text-warning ms-3"></i>
                                    <i class="bi bi-discord text-warning ms-3"></i>
                                </div>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-6 col-sm-6 col-12 p-3'>
                            <div className='border-5 border-end border-warning h-25'>
                                <div>
                                    <h4 className='text-white'>RECENT POSTS</h4>
                                </div>
                                <div className='color'>
                                    <div>
                                        <a href='#'>Overwatch January 28 Update<br /> Nerfs Echo, Tracer, and<br /> Zenyatta</a>
                                    </div>
                                    <div>
                                        <a href='#'>ZenSports signs casino deal<br /> for Colorado expansion</a>
                                    </div>
                                    <div>
                                        <a href='#'>Evil Geniuses enters<br /> VALORANT with mixed-gender<br /> team</a>
                                    </div>
                                    <div>
                                        <a href='#'>Rocket League adds Gridiron<br /> Mode for NFL Super Bowl LV</a>
                                    </div>
                                    <div>
                                        <a href='#'>Valve resets CS:GO RMR<br /> esports points</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-6 col-12 p-3'>
                            <div className='border-5 border-end border-warning h-25'>
                                <h3 className='text-white'>TOURNAMENT<br />CAROUSEL</h3>
                                <div className='d-flex border-light border p-2 mt-4'>
                                    <div className='text-white'>
                                        <img src='https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/smite-85x118.jpg' />
                                        <p className='m-0 p-0'><small>START DATE:</small></p>
                                        <h5 className='m-0 p-0'>2023-01-08 12:00</h5>
                                    </div>
                                    <div className='text-white'>
                                        <a href='#' className='nav-link text-warning'>Test⭐</a>
                                        <small>Valorant</small>
                                        <p>2/100 Users</p>
                                        <p>Registered</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-lg-3 p-3'>
                            <div>
                                <h4 className='text-white'>OTHER MATCHES</h4>
                                <div className='text-white'>
                                    <Link to="#" className='btn btn-warning'>COD</Link>
                                    <div className='d-flex border p-3'>
                                        <div className='me-2'>
                                            <img className='me-2' src='https://skywarriorthemes.com/arcane/wp-content/themes/arcane/img/defaults/team25x25.jpg' />VS
                                            <img className='ms-2' src='https://skywarriorthemes.com/arcane/wp-content/themes/arcane/img/defaults/team25x25.jpg' />
                                            <div>
                                                <small>COD-November 9,2023,7:42pm</small>
                                            </div>
                                        </div>
                                        <div>
                                            <Link className='btn btn-success'>44:20</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default LeaderBoard