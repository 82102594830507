import React from 'react'

import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab24 = () => {
    return (
        <div>

            <Title title={"Lab 24: Open Redirects - Part 2"} />

            <StyledLab24>
                <div className='box1 border border-dark col-5 mx-auto rounded'>
                    <h5 className='text-center p-0 m-0'>What domain name has been encoded?</h5>
                    <h5 className='text-center p-0 m-0'>%77%77%77%2E%64%75%63%6B%64%75%63%6B%67%</h5>
                    <h5 className='text-center p-0 m-0'>6F%2E%63%6F%6D</h5>
                </div>
                <div className='col-5 mx-auto m-3'>
                    <div className=' d-flex'>

                    <input type="radio"></input>

                        <h6 className='p-0 m-0'> www.google.com
                        </h6>
                      
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'> www.walmart.com</h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'>www.duckduckgo.com
                        </h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'>C ftp.microsoft.com
</h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>
                        <h6 className='p-0 m-0'>www.dundermifflin.com
                        </h6>
                    </div>
                </div>
                <div className=' submit col-1 mx-auto '>
                    <button className='rounded pe-3 ps-3 m-2'>
                        Submit
                    </button>
                </div>
                <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
                    <h6> Choose the best answer or view Hints and Videos</h6>
                </div>
                <div className='d-flex justify-content-center m-2 fs-5'>
                    <a className='  text-decoration-none p-2 '>
                        Previous Lab
                    </a>
                    <div className='p-2'>|</div>
                    <a className='  text-decoration-none p-2 fs-5 '>
                        Next Lab
                    </a>

                </div>
            </StyledLab24>
        </div>
    )
}

export default Lab24

const StyledLab24 = styled.div
    `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`