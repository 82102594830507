import { Height } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';



const Edit = () => {

    const params = useParams()


    const [title, settitle] = useState("");

    const [descripction, setdescripction] = useState(null);

    const [version, setversion] = useState(null);

    const [size, setsize] = useState(null);

    const [img, setimg] = useState(null);

    const [details, setdetails] = useState(null);
    const editlist = async () => {

        const data = new FormData()

        data.append('id', params.id);
        data.append('title', title)
        data.append('descripction', descripction);
        data.append('version', version);
        data.append('size', size);
        data.append('img', img);

        const responce = await axios.post("https://globalhackersunited.com/showedit.php", data, { headers: { 'content-type': 'multipart/form-data' } });

    }


    const defaultdata = async () => {



        const data = new FormData();


        data.append('id', params.id);


        const res = await axios.post("https://globalhackersunited.com/get-tools-details.php", data, { headers: { 'content-type': 'multipart/form-data' } })

        if (res) {

            settitle(res.data.data.title);

            setdescripction(res.data.data.description);

            setversion(res.data.data.version);

            setsize(res.data.data.size);

            setimg(res.data.data.img_url);



        }




    }




    useEffect(() => {

        defaultdata();

    }, [])


    return (
        <div>



            <div className=' col-4 mx-auto bg-white shadow'>
                <div className=' mx-auto p-3'>
                    <h2 className=' text-black mb-3 '>Edit Here </h2>
                    <div className=' text-center'>


                        <div className='mx-auto col-6 text-center' >

                            <label className='mx-auto text-start d-block'><b>Title</b></label>
                            <input value={title} className='w-100' onChange={(e) => settitle(e.target.value)} placeholder=' Enter Your Prefered tiltle' />

                        </div>

                        <div className='mx-auto col-6 text-center'>
                            <label className='text-start d-block'><b>Version</b></label>
                            <input value={version} className='w-100' onChange={(e) => setversion(e.target.value)} placeholder=' Change Version' />
                        </div>

                        <div className='mx-auto col-6 text-center'>
                            <label className='text-start d-block'><b>Size</b></label>
                            <input value={size} className='w-100' onChange={(e) => setsize(e.target.value)} placeholder=' Change size' />
                        </div>

                        <div className='mx-auto col-6 text-center'>
                            <label className='text-start d-block' ><b>Url</b></label>
                            <input type='url' value={img} className='w-100' onChange={(e) => setimg(e.target.value)} placeholder='Enter URL' />
                        </div>

                        <div className='mx-auto col-6 text-center'>
                            <label className='mx-auto text-start d-block'><b>Descripction</b></label>
                          <textarea value={descripction} className='w-100 p-0 m-0' style={{height:100}}  onChange={(e) => setdescripction(e.target.value)} type="text" placeholder=' Change Discription' />
                        </div>


                    </div>
                </div>
                <div className='mx-auto col-6 text-center pe-3'>

                    <button onClick={editlist} className='bg-danger border-0 w-100 rounded-3 p-2'><p className='text-light fw-semibold p-0 m-0 my-auto'>Change</p></button>
                </div>
            </div>
        </div >

    )
}

export default Edit