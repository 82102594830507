import React from "react";
import lock from "../../assets/images/verified.png";
import padlock from "../../assets/images/padlock.png";
import approved from "../../assets/images/approved.png";
import bug from "../../assets/images/bug.png";
import content from "../../assets/images/content.png";
import customer from "../../assets/images/customer-care.png";
import cv from "../../assets/images/cv.png";
import compliance from "../../assets/images/compliance.png";
import learn from "../../assets/images/learning.png";
import cyber from "../../assets/images/cyber-security.png";
import hacker from "../../assets/images/hacker_1-1-1-1-1.jpg";
import "./tools.css";
const Tools = () => {
  return (
    <>
      <div className="concurrent back pt-5">

        <div className="container ">
        <div className="click mt-5">
          <div className="family">
            <p className="text-white">
              All Research Up to Blockchain Interoperability is Completed
            </p>
          </div>
          <div className="hackers">
            <h2 className="text-white">
              <b>
                Modern Information
                <br /> Protect From Hackers
              </b>
            </h2>
          </div>
          <div className="family">
            <p className="text-white">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
              architecto laborum eaque!
              <br /> Deserunt maxime, minus quas molestiae reiciendis esse natus
              nisi iure.
            </p>
          </div>
          <div className="text-lg-left">
            <button className="btn btn-danger me-2 mt-2">Get Started</button>
            <button className="btn btn-light mt-2">About Us</button>
          </div>
        </div>
        <div className="">
          <div className="d-flex flex-wrap justify-content-between mt-3">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-1 mt-2">
              <div className="bg-dark border-end border-3 border-danger p-3">
                <div className="item d-flex align-items-center">
                  <img src={lock} className="w-100 me-3" />
                  <h4 className="text-danger">Security</h4>
                </div>
                <p className="text-white mt-3">
                  Lorem ipsum dolor sit amet, consectetur
                  <br /> adipiscing elit, sed do eiusmod tempor incididunt
                  <br /> ut labore et dolore.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-1 mt-2">
              <div className="bg-dark border-end border-3 border-danger p-3">
                <div className="item d-flex align-items-center">
                  <img src={padlock} className="w-100 me-3" />
                  <h4 className="text-danger">Security</h4>
                </div>
                <p className="text-white mt-3">
                  Lorem ipsum dolor sit amet, consectetur
                  <br /> adipiscing elit, sed do eiusmod tempor incididunt
                  <br /> ut labore et dolore.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-1 mt-2">
              <div className="bg-dark border-end border-3 border-danger p-3">
                <div className="item d-flex align-items-center">
                  <img src={approved} className="w-100 me-3" />
                  <h4 className="text-danger">Security</h4>
                </div>
                <p className="text-white mt-3">
                  Lorem ipsum dolor sit amet, consectetur
                  <br /> adipiscing elit, sed do eiusmod tempor incididunt
                  <br /> ut labore et dolore.
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
       
      </div>

      <div>
        <h1 className="mt-5 text-center">Complete Website Security</h1>
        <p className="text-center">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus
          quam neque quibusdam
          <br /> corrupti aspernatur corporis alias nisi dolorum expedita
          veritatis voluptates minima sapiente.
        </p>
      </div>
      <div style={{color:'#212529'}} className="container">
        <div className="d-flex flex-wrap">
          <div className="col-3 col-lg-3 col-md-6 col-sm-6 col-12 p-3">
            <div className="bg-light rounded mb-2 p-3">
              <div className="item">
                <img className="w-100" src={bug} />
              </div>
              <h6 className="mt-3">
                <b>Malware Detection Removal</b>
              </h6>
              <p>
                Lorem ipsum dolor sit amet,
                <br /> consectetur adipiscing elit, sed do
                <br /> eiusmod tempor
              </p>
            </div>
          </div>
          <div className="col-3 col-lg-3 col-md-6 col-sm-6 col-12 p-3">
            <div className="bg-light rounded mb-2 p-3">
              <div className="item">
                <img className="w-100" src={content} />
              </div>
              <h6 className="mt-3">
                <b>Malware Detection Removal</b>
              </h6>
              <p>
                Lorem ipsum dolor sit amet,
                <br /> consectetur adipiscing elit, sed do
                <br /> eiusmod tempor
              </p>
            </div>
          </div>
          <div className="col-3 col-lg-3 col-md-6 col-sm-6 col-12 p-3">
            <div className="bg-light rounded mb-2 p-3">
              <div className="item">
                <img className="w-100" src={customer} />
              </div>
              <h6 className="mt-3">
                <b>Malware Detection Removal</b>
              </h6>
              <p>
                Lorem ipsum dolor sit amet,
                <br /> consectetur adipiscing elit, sed do
                <br /> eiusmod tempor
              </p>
            </div>
          </div>
          <div className="col-3 col-lg-3 col-md-6 col-sm-6 col-12 p-3">
            <div className="bg-light rounded mb-2 p-3">
              <div className="item">
                <img className="w-100" src={cv} />
              </div>
              <h6 className="mt-3">
                <b>Malware Detection Removal</b>
              </h6>
              <p>
                Lorem ipsum dolor sit amet,
                <br /> consectetur adipiscing elit, sed do
                <br /> eiusmod tempor
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-flex flex-wrap justify-content-between mt-5">
        <div className="col-lg-5">
          <img
            className="w-100"
            src="https://themes.envytheme.com/vaximo/wp-content/uploads/2020/08/approach-img-1-1-1.jpg"
          />
        </div>
        <div className="col-lg-6 mt-3">
          <h1>
            <b>Our Approach To Security</b>
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt
            <br /> ut labore et dolore magna aliqua. Quis ipsum
          </p>
          <div style={{color:'#212529'}} className="col-lg-12 shadow mt-3 bg-light bg-white p-4">
            <div className="d-flex">
              <div className="item">
                <img className="w-100 me-3" src={cyber} />
              </div>
              <div>
                <h3>Secure By Design</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                  <br /> tempor incididunt ut suspendisse ultrices
                </p>
              </div>
            </div>
          </div>
          <div style={{color:'#212529'}} className="col-lg-12 shadow mt-3 bg-light bg-white p-4">
            <div className="d-flex">
              <div className="item">
                <img className="w-100 me-3" src={compliance} />
              </div>
              <div>
                <h3>Compliant By Design</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                  <br /> tempor incididunt ut suspendisse ultrices
                </p>
              </div>
            </div>
          </div>
          <div style={{color:'#212529'}} className="col-lg-12 shadow mt-3 bg-light bg-white p-4">
            <div className="d-flex">
              <div className="item">
                <img className="w-100 me-3" src={learn} alt="" />
              </div>
              <div>
                <h3>Continuous Monitoring</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod
                  <br /> tempor incididunt ut suspendisse ultrices
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <h1>
          <b>High-Performance Solutions</b>
        </h1>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus
          quam neque quibusdam
          <br /> corrupti aspernatur corporis alias nisi dolorum expedita
          veritatis voluptates minima sapiente.
        </p>
      </div>
      <div>
        <div className="container d-flex flex-wrap justify-content-between">
          <div className="col-lg-5 col-md-6 col-sm-6 mt-3">
            <div className="positions backgroundimage-url">
              <div className="positions-content text-left p-4">
                <h3 className="text-white">Threat Hunter</h3>
                <p className="text-white">
                  Lorem ipsum dolor sit amet sed, consectetur adipiscing elit do
                  <br /> obcaecati praesentium. Labore sint recusandae
                  perspiciatis
                  <br /> laudantium, deleniti non{" "}
                </p>

                <a href="#" className="text-danger nav-link">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="d-flex flex-wrap">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                <div className="positions backgroundimage">
                  <div className="positions-content text-left p-3">
                    <h3 className="text-white">Threat Hunter</h3>
                    <p className="text-white">
                      Lorem ipsum dolor sit amet sed,
                      <br /> consectetur adipiscing elit do obcaecati
                      <br /> praesentium. Labore sint recusandae
                      <br /> perspiciatis laudantium, deleniti non{" "}
                    </p>

                    <a href="#" className="text-danger nav-link">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                <div className="positions backgroundimages">
                  <div className="positions-content text-left p-3">
                    <h3 className="text-white">Threat Hunter</h3>
                    <p className="text-white">
                      Lorem ipsum dolor sit amet sed,
                      <br /> consectetur adipiscing elit do obcaecati
                      <br /> praesentium. Labore sint recusandae
                      <br /> perspiciatis laudantium, deleniti non{" "}
                    </p>

                    <a href="#" className="text-danger nav-link">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex flex-wrap justify-content-evenly mt-3">
          <div className="col-lg-7">
            <div className="d-flex flex-wrap">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                <div className="positions back-ground-image">
                  <div className="positions-content text-left p-3">
                    <h3 className="text-white">Threat Hunter</h3>
                    <p className="text-white">
                      Lorem ipsum dolor sit amet sed,
                      <br /> consectetur adipiscing elit do obcaecati
                      <br /> praesentium. Labore sint recusandae
                      <br /> perspiciatis laudantium, deleniti non{" "}
                    </p>

                    <a href="#" className="text-danger nav-link">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                <div className="positions backgroundimagess">
                  <div className="positions-content text-left p-3">
                    <h3 className="text-white">Threat Hunter</h3>
                    <p className="text-white">
                      Lorem ipsum dolor sit amet sed,
                      <br /> consectetur adipiscing elit do obcaecati
                      <br /> praesentium. Labore sint recusandae
                      <br /> perspiciatis laudantium, deleniti non{" "}
                    </p>

                    <a href="#" className="text-danger">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-6 mt-3">
            <div className="positions background-image">
              <div className="positions-content text-left p-4">
                <h3 className="text-white">Threat Hunter</h3>
                <p className="text-white">
                  Lorem ipsum dolor sit amet sed, consectetur adipiscing elit do
                  <br /> obcaecati praesentium. Labore sint recusandae
                  perspiciatis
                  <br /> laudantium, deleniti non{" "}
                </p>

                <a href="#" className="text-danger">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="ground-image">
          <div className="container d-flex flex-wrap flex-column-reverse-sm mt-5 pt-5">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <h2 className="text-white">
                <b>
                  Innovative Electronic Protection Of Your
                  <br /> Office And Home Control Online
                </b>
              </h2>
              <div className="mt-5">
                <a href="" className="me-3 text-danger">
                  Intercom System
                </a>
                <a href="#" className="me-3 text-white">
                  CCTV
                </a>
                <a href="#" className="me-3 text-white">
                  GDPR
                </a>
                <a href="#" className="me-3 text-white">
                  Encryption
                </a>
                <a href="#" className="me-3 text-white">
                  Our Goal
                </a>
                <hr className="border border-danger"></hr>
              </div>
              <div className="mt-5">
                <p className="text-light">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo
                  ducimus vero, vero
                  <br /> corporis voluptates beatae pariatur laudantium, fugiat
                  illum ab deserunt nostrum
                  <br /> aliquid quisquam esse? Voluptatibus quia velit numquam
                  esse porro ipsum dolor, sit
                  <br /> amet consectetur adipisicing elit. Illo ducimus vero,
                  corporis.
                </p>
                <p className="text-light">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Perspiciatis, soluta, aspernatur
                  <br />
                  dolorum sequi quisquam ullam in pariatur nihil dolorem cumque
                  excepturi totam. Qui
                  <br />
                  excepturi quasi cumque placeat fuga. Ea, eius?
                </p>
                <button className="btn btn-danger">Learn About</button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
              <img
                className="w-100"
                src="https://themes.envytheme.com/vaximo/wp-content/uploads/2020/08/electronic-img-1-1-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className="d-flex flex-wrap">
            <div className="col-lg-6 mt-5">
              <h3 className="fs-2">
                <b>24/7 Cybersecurity Operation Center</b>
              </h3>
              <p className="mt-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt
                <br />
                ut labore et dolore magna aliqua. Quis ipsum suspendisse.
              </p>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="col-lg-6 col-sm-6 col-12">
                  <ul className="list-group">
                    <li className="bg-light mt-5 list-group-item me-2">
                      <i className="bi bi-check2-square me-2"></i>Managed Web
                      Application
                    </li>
                    <li className="bg-light mt-4 list-group-item me-2">
                      <i className="bi bi-check2-square me-2"></i>SIEM Threat
                      Detection
                    </li>
                    <li className="bg-light mt-4 list-group-item me-2">
                      <i className="bi bi-check2-square me-2"></i>Content
                      Delivery Network
                    </li>
                    <li className="bg-light mt-4 list-group-item me-2">
                      <i className="bi bi-check2-square me-2"></i>24/7 Hours
                      services
                    </li>
                    <li className="bg-light mt-4 list-group-item me-2">
                      <i className="bi bi-check2-square me-2"></i>Security
                      Management
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-12">
                  <ul className="list-group">
                    <li className="bg-light mt-5 list-group-item">
                      <i className="bi bi-check2-square me-2"></i>Managed Web
                      Application
                    </li>
                    <li className="bg-light mt-4 list-group-item">
                      <i className="bi bi-check2-square me-2"></i>SIEM Threat
                      Detection
                    </li>
                    <li className="bg-light mt-4 list-group-item">
                      <i className="bi bi-check2-square me-2"></i>Content
                      Delivery Network
                    </li>
                    <li className="bg-light mt-4 list-group-item">
                      <i className="bi bi-check2-square me-2"></i>24/7 Hours
                      services
                    </li>
                    <li className="bg-light mt-4 list-group-item">
                      <i className="bi bi-check2-square me-2"></i>Security
                      Management
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 mt-5">
              <img className="w-100 ms-3" src={hacker} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tools;
