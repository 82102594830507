import React from 'react'
import styled from 'styled-components'
import Title from '../../../components/Title'

const ViewSomeonesBlog = () => {
  return (
    <StyledViewSomeonesBlog>
    <div>
        <Title title={"View Blogs"}></Title>
      <div className='border border-2 border-dark rounded m-5 p-3'>
       <h5>View Blog Entries</h5>
       <div className='d-flex'>
        <img src='http://localhost/images/add-icon-32-32.png'></img>
        <h6 className='text-primary'>Add To Your Blog</h6>
       </div>
       <div className='col-4 mx-auto'>
       <div className='text-center p-1 error'>
        <p>Validation Error:Please choose blog entries to view </p>
      </div>
       <div className=' border border-2 border-dark user p-1 text-center rounded mt-3 '>
          <h5>Select Author and Click to View Blog</h5>
      </div>
      <div className='d-flex mt-3'>
      <select name="author" id="id_author_select" autofocus="autofocus">
		<option value="53241E83-76EC-4920-AD6D-503DD2A6BA68">&nbsp;&nbsp;&nbsp;Please Choose Author&nbsp;&nbsp;&nbsp;</option>
		<option value="6C57C4B5-B341-4539-977B-7ACB9D42985A">Show All</option>
		<option value="admin">admin</option>\n<option value="adrian">adrian</option>\n<option value="john">john</option>\n
        <option value="jeremy">jeremy</option>\n<option value="bryce">bryce</option>\n<option value="samurai">samurai</option>\n
        <option value="jim">jim</option>\n<option value="bobby">bobby</option>\n<option value="simba">simba</option>\n<option value="dreveil">dreveil
        </option>\n<option value="scotty">scotty</option>\n<option value="cal">cal</option>\n<option value="john">john</option>\n<option value="kevin">kevin
        </option>\n<option value="dave">dave</option>\n<option value="patches">patches</option>\n<option value="rocky">rocky</option>\n<option value="tim">tim</option>\n
        <option value="ABaker">ABaker</option>\n<option value="PPan">PPan</option>\n<option value="CHook">CHook</option>\n<option value="james">james</option>\n<option value="ed">ed</option>\n			
        </select>
        <button className='ms-2 box'>View Blog Entries</button>
      </div>
      </div>

      </div>
    </div>
    </StyledViewSomeonesBlog>
  )
}

export default ViewSomeonesBlog
const StyledViewSomeonesBlog = styled.div`
.user{
    background-color:#f2dfc3;
  }
  .error{
    background-color:#fbeaea
  }
  .box{
    background-color:#dddddd;
  }`