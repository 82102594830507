import React from 'react'

const User = ({user}) => {
  return (
    <div className='col-lg-12 border rounded-2  text-center p-1 user'>
       <h4>{user}</h4>
    </div>
  )
}

export default User