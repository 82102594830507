import React from 'react'
import './features.css'

const Features = () => {
  return (
   <>
    <div>

  
<div className='col-md-12 col-sm-12 background position-relative'>
    <div className='container p-5'>

        <img src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/logo-1-1.png" />

    </div>


    <div className=' container d-flex '>

        <div className=' col-lg-5 col-md-12 col-sm-12 p-5 text-white mt-5  '>

            <div className=' col-12 size fw-bold'>
                <h4>IT Agency WordPress Theme</h4>
            </div>

            <div className='col-11 increase mt-3'>
                <p>Building a website with the Digital IT Agency WordPress
                    Theme is super fast and easy.</p>
            </div>

            <div className='mt-5'>
                <button className='btn btn-warning p-4 text-dark'>PURCHASE THEME </button>
            </div>
        </div>



        <div className='col-lg-5 d-none d-lg-block  position-absolute hero-img   d-flex'>

            <div className=' col-3 hero-img1'>
                <img src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/banner-1-1.png" />
            </div>
            <div className=' col-3 hero-img2'>
                <img src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/banner-1-2.png" />
            </div>
            <div className=' col-3 hero-img3'>
                <img src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/banner-1-3.png" />
            </div>

        </div>


    </div>



</div>





<div style={{color:'#212529'}} className='bg  '>

    <div className='col-lg-10 col-md-12  col-sm-12 col-12   mx-auto  '>
        <div className='center w-100'>
            <h2 className='fw-bold text-center'>Find Everything you Need <br/>for your Start.</h2>
        </div>
    </div>



    <div className=''>
        <div className='col-lg-12 col-md-4 col-sm-4 d-flex  flex-wrap justify-content-center mx-auto'>
            <div className='col-lg-4  p-4 '>
                <div className='text-center p-4 '>
                    <img className=" bg-info  rounded rounded-circle p-4" src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-1-1.png" />
                </div>

                <div className='text-center'>
                    <h6 className='mt-3 fw-bold'>Clean Code with Extensive <br />Documentation</h6>
                </div>


            </div>
            <div className='col-lg-4  p-4 '>
                <div className='text-center p-4 '>
                    <img className=" bg-warning  rounded rounded-circle p-4" src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-1-2.png" />
                </div>

                <div className='text-center'>
                    <h6 className='mt-3 fw-bold'>Free Lifetime <br />Updates</h6>
                </div>


            </div>
            <div className='col-lg-4  p-4 '>
                <div className='text-center p-4 '>
                    <img className=" bg-white  rounded rounded-circle p-4" src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-1-3.png" />
                </div>

                <div className=' col- 10  text-center'>
                    <h6 className='mt-3 fw-bold'>Expert Ready to Provide you <br />Quick Support</h6>
                </div>


            </div>
        </div>


    </div>
</div>




<div className='mt-5 text-center p-5  font'>

    <div>
        <h5>Creative & Stunning Demo</h5>
    </div>

</div>


<div style={{color:'#212529'}}  className=' container '>

    <div className='d-flex flex-wrap justify-content-between   '>
        <div className=' col-lg-4  col-md-6 '>

            <div className='bg-white p-5'>
                <div>
                    <div>
                        <img className='border border-dark' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/demo-1-1.png" />
                    </div>

                </div>
                <h4 className="fw-bold mt-4 text-center">01 Home Page</h4>


            </div>
        </div>


        <div className=' col-lg-4 col-md-6 '>
            <div className='bg-white p-5'>
                <div>
                    <img className='border border-dark' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/demo-1-2.png" />


                </div>
                <h4 className="fw-bold mt-4 text-center">02 Home Page</h4>
            </div>

        </div>


        <div className=' col-lg-4 col-md-6 '>
            <div className='bg-white p-5'>
                <div>
                    <img className='border border-dark' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/demo-1-3.png" />
                </div>


                <h4 className="fw-bold mt-4 text-center">03 Home Page</h4>
            </div>

        </div>



       <div style={{color:'#212529'}} className='col-lg-4 col-md-6'>
       <div className='bg-white p-5'>
        
                <div>
                    <img className='border border-dark' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/demo-1-1.png" />
                </div>

            </div>
            <h4 className="fw-bold mt-4 text-center">01 Home Page</h4>
        </div>
       </div>
    
</div>









<div style={{color:'#212529'}} className='backgrounds pt-5 pb-5'>


    <div className='text-center p-5 big'>
        <h1 className='fw-bold'>Our Core Features</h1>
    </div>


    <div className='container mt-5 '>

        <div className=' d-flex  flex-wrap justify-content-between'>
            <div className='col-lg-3 col-md-6 col-sm-6 col-12  '>
                <div className='p-3'>
                    <div className='border border-2 p-5 rounded rounded-5 bg-white'>

                        <div className='text-center'>
                            <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-1.png" />

                        </div>
                        <div>
                            <h6 className=' fw-bold mt-3 text-center '>Bootstrap5</h6>
                        </div>
                    </div>
                </div>
            </div>


            <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                <div className='p-3'>
                    <div className=' border border-2 p-5 rounded rounded-5 bg-white'>

                        <div className='text-center'>
                            <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-2.png" />

                        </div>
                        <div>
                            <h6 className=' text-center fw-bold mt-3 '>WellDocumented</h6>
                        </div>

                    </div>
                </div>
            </div>


            <div className='col-lg-3  col-md-6 col-sm-6 col-12'>

                <div className='p-3'>
                    <div className=' border border-2 p-5 rounded rounded-5 bg-white'>
                        <div className='text-center'>
                            <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-3.png" />
                            
                        </div>
                        <div>
                        <h6 className='fw-bold mt-3 text-center '>Mailchimp</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-3  col-md-6 col-sm-6 col-12'>

                <div className='p-3'>
                    <div className=' border border-2 p-5 rounded rounded-5 bg-white'>
                        <div className='text-center'>
                            <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-4.png" />
                           
                        </div>
                        <div>
                        <h6 className='fw-bold mt-3 text-center '>W3CValidation</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>






    <div className='container d-flex flex-wrap justify-content-between'>


        <div className='col-lg-3  col-md-6 col-sm-6 col-12'>

            <div className='p-3'>
                <div className=' border border-2 p-5 rounded rounded-5 bg-white'>
                    <div className='text-center'>
                        <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-6.png" />
                        
                    </div>
                    <div><h6 className='fw-bold mt-3 text-center '>CrossBrowser</h6></div>
                </div>
            </div>
        </div>

        <div className='col-lg-3  col-md-6 col-sm-6 col-12'>

            <div className='p-3'>
                <div className=' border border-2 pt-5 pb-5 ps-3 pe-3 rounded rounded-5 bg-white'>
                    <div className='text-center'>
                        <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-5.png" />

                    </div>
                    <div className=''>
                        <h6 className='fw-bold mt-3 text-center '>ParallaxBackgrounds</h6>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-lg-3  col-md-6 col-sm-6 col-12'>
            <div className='p-3'>

                <div className=' border border-2 p-5 rounded rounded-5 bg-white'>
                    <div className='text-center'>
                        <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-7.png" />
                       
                    </div>
                    <div>
                    <h6 className='fw-bold mt-3 text-center '>FullyResponsive</h6>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-lg-3  col-md-6 col-sm-6 col-12'>

            <div className='p-3'>
                <div className=' border border-2 p-5 rounded rounded-5 bg-white'>
                    <div className='text-center '>
                        <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-8.png" />
                     
                    </div>
                    <div>
                    <h6 className='fw-bold mt-3 text-center '>Clean Code</h6>
                    </div>
                </div>
            </div>
        </div>



    </div>







    <div className='container   '>


        <div className='d-flex  flex-wrap justify-content-between'>
            <div className='col-lg-3  col-md-6 col-sm-6 col-12'>
                <div className='p-3'>
                    <div className=' border border-2 p-5 rounded rounded-5 bg-white'>
                        <div className='text-center'>
                            <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-9.png" />
                          
                        </div>
                        <div>
                        <h6 className='fw-bold mt-3 text-center '>ColorSchemes</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-3 col-md-6 col-sm-6 col-12'>

                <div className='p-3'>
                    <div className=' border border-2 p-5 rounded rounded-5  bg-white'>
                        <div className='text-center '>
                            <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-10.png" />
                            
                        </div>
                        <div>
                        <h6 className='fw-bold mt-3  text-center'>SpeedOptimized</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-3  col-md-6 col-sm-6 col-12'>

                <div className='p-3'>
                    <div className=' border border-2 p-5 pt-5 pb-5 ps-3 pe-3  rounded rounded-5 bg-white'>
                        <div className='text-center'>
                            <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-11.png" />
                       
                        </div>
                        <div>
                        <h6 className='fw-bold mt-3 text-center '>Modern&CleanDesign</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-lg-3  col-md-6 col-sm-6 col-12'>

                <div className='p-3'>
                    <div className=' border border-2  p-5 pt-5 pb-5 ps-3 pe-3  rounded rounded-5 bg-white'>

                        <div className='text-center'>
                            <img className='' src="https://bracketweb.com/ontechwp/tf-data/landing/assets/images/landing-page/f-2-12.png" />

                        </div>
                        <div>
                            <h6 className='fw-bold mt-3 text-center'> Clean Code</h6>
                        </div>
                    </div>
                </div>

            </div>
        </div>



    </div>

</div>



<div className='backphoto col-12 '>
    <div className='  container col-12 text-white  footer-top-text'>
        <h1>Build a Beautiful Website Right Now!</h1>
    </div>

    <div className='text-white mt-4 col-12'>
        <h5>We create this design with love and care to assure our customer to get everything<br />for start IT Agency Wordpress Theme</h5>



    </div>

    <div className='mt-5'>
        <button className='btn btn-warning py-3 px-3' >Purchase time</button>
    </div>

</div>

</div>
   </>
  )
}

export default Features