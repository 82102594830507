import React from 'react'
import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab2 = () => {
  return (
    <div>
      <Title title={"Lab 2: Capturing HTTP Packets with Wiresharki"} />
      <StyledLab2>
      <div className='box1 border border-dark col-5 mx-auto rounded'>
        <h5 className='text-center'>Use the Firefox browser to connect to Mutillidae. What is </h5>
        <h5 className='text-center'>the first word in the user-agent string?</h5>
      </div>
      <div className='col-5 mx-auto m-3'>
        <div className=' d-flex'>

        <input type="radio"></input>

          <h6 className='p-0 m-0'>Firefox</h6>
        </div>
        <div className=' d-flex p-0 m-0'>
        <input type="radio"></input>

          <h6 className='p-0 m-0'>Gecko</h6>
        </div>
        <div className=' d-flex p-0 m-0'>
        <input type="radio"></input>
          <h6 className='p-0 m-0'> Linux</h6>
        </div>
        <div className=' d-flex p-0 m-0'>
        <input type="radio"></input>

          <h6 className='p-0 m-0'> Mozilla</h6>
        </div>
        <div className=' d-flex p-0 m-0'>
        <input type="radio"></input>
          <h6 className='p-0 m-0'> Trident
          </h6>
        </div>
      </div>
      <div  className=' submit col-1 mx-auto '>
      <button className='rounded pe-3 ps-3 m-2'>
Submit
      </button>
      </div>
      <div className= 'janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
     <h6> Choose the best answer or view Hints and Videos</h6>
      </div>
      <div className='d-flex justify-content-center m-2 fs-5'>
          <a className='  text-decoration-none p-2 '>
           Previous
          </a>
          <div className='p-2'>|</div>
          <a className='  text-decoration-none p-2 fs-5 '>
           Next
          </a>

        </div>
      </StyledLab2>
    </div>
  )
}

export default Lab2

const StyledLab2=styled.div
`
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`