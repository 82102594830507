import React from "react";
import styled from "styled-components";

const SetBackGroundColor = () => {
  return (
    <Style>
      <div className="col-12 setbackground p-3 mt-5">
        <div className="mx-auto my-auto">
          <div className="mx-auto title border border-1 border-black col-6 rounded rounded-3 ">
            <b className="fs-6 d-block text-center">
              Please enter the background color you would like to see
            </b>

            <b className="col-6 fs-6  d-block text-center mx-auto ">
              Enter the color in RRGGBB format (Example: Red = FF0000)
            </b>
          </div>

          <div className="d-flex justify-content-between col-5 mt-3  mx-auto ">
            <b>String to Repeat</b>
            <input className="rounded-3" />
          </div>

          <div className=" mt-3 text-center">
            <div className="mx-auto btn btn-secondary border border-1 border-black m-0 p-1 ">
              <b className="m-0 p-0">set background color</b>
            </div>
          </div>

          <div className=" border border-1 border-black title col-6 mx-auto mt-3 rounded-3">
            <p className=" text-center fs-6 fw-bold p-1 m-0">
            The current background color is eecccc
            </p>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default SetBackGroundColor;

export const Style = styled.div`
  .title {
    background-color: #bc6c2550 !important;
  }
  .setbackground{
    background-color:#eecccc
  }
`;
