import React from 'react'
import User from '../../components/User'

const UsageInstruction = () => {
  return (
    <div className='container mt-3'>
        <User user={"Usage Instructio"}/>
      <div className='col-lg-10 mx-auto'>
      <>
  Mutillidae implements vulnerabilities from the
  <a
    href="http://www.owasp.org/index.php/OWASP_Top_Ten_Project"
    target="_blank"
  >
    OWASP Top 10
  </a>
  2013, 2010 and 2007 in PHP. Additionally vulnerabilities from the SANS Top 25
  Programming Errors and select information disclosure vulnerabilities have been
  added on various pages.
  <br />
  <br />
  <br />
  <span className="report-header">Optional Configuration</span>
  <br />
  <br />
  Instructional videos are available to help set up an HTTPS TLS certificate or
  Apache virtual hosts
  <br />
  <br />
  <a href="https://www.youtube.com/watch?v=sJd0ir9-jSc" target="_blank" className='text-decoration-none'>
    <img
      style={{ marginRight: 5 }}
      src="http://localhost/images/youtube-play-icon-40-40.png"
      alt="YouTube"
    />
    <span className="label">
      How to Create Self-Signed Certificate in Apache
    </span>
  </a>{" "}
  <br />
  <a href="https://www.youtube.com/watch?v=79mOiU3GfnQ" target="_blank" className='text-decoration-none'>
    <img
      style={{ marginRight: 5 }}
      src="http://localhost/images/youtube-play-icon-40-40.png"
      alt="YouTube"
    />
    <span className="label">How to Create Virtual Hosts in Apache</span>
  </a>{" "}
  <br />
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-2 text-center">Top Menu Bar</h5>
  <br />
  <br />
  <h5 className="label">Home:</h5> Takes user to Home page
  <br />
  <h5 className="label">Login/Register:</h5> Takes user to Login page
  <br />
  <h5 className="label">Toggle Hints:</h5> Shows or hides the Hints on
  vulnerable pages
  <br />
  <h5 className="label">Toggle Security:</h5> Changes the security level
  between insecure, client-side security and secure
  <br />
  <h5 className="label">Enforce TLS:</h5> When enforced, Mutillidae
  automatically redirects all HTTP requests to HTTPS
  <br />
  <h5 className="label">Reset DB:</h5> Drops and rebuilds all database
  tables and resets the project
  <br />
  <h5 className="label">View Log:</h5> Takes the user to view the log
  <br />
  <h5 className="label">View Captured Data:</h5> Takes the user to the view
  the captured data
  <br />
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-2 text-center">Left Menu Bar</h5>
  <br />
  <br />
  The menu on the left is organized by category then vulnerability. Some
  vulnerabilities will be in more than one category as there is overlap between
  categories. Each page in Mutillidae will expose multiple vulnerabilities. Some
  pages have half a dozen and/or multiple critical vulnerabilities on the same
  page. The page will appear in the menu under each vulnerability.
  <br />
  <br />A{" "}
  <a
    title="Listing of vulnerabilities"
    href="./index.php?page=./documentation/vulnerabilities.php"
    className='text-decoration-none'
  >
    listing of vulnerabilities
  </a>
  is available in menu under documentation or by clicking
  <a
    title="Listing of vulnerabilities"
    href="./index.php?page=./documentation/vulnerabilities.php"
    className='text-decoration-none'
  >
    here
  </a>
  .
  <br />
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-2 text-center">Videos</h5>
    
  <br />
  <br />
  The videos on the Webpwnized YouTube Channel are likely to be a some
  assistance. Videos cover installation, using tools like Burp-Suite and
  exploits for various vulnerabilities.
  <br />
  <br />
  <a href="http://www.youtube.com/user/webpwnized" target="_blank" className='text-decoration-none'>
    <img
      align="middle"
      alt="Webpwnized YouTube Channel"
      src="http://localhost/images/youtube-play-icon-40-40.png"
    />
    Video Tutorials
  </a>
  <br />
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-2 text-center">PageHints</h5>
  <br />
  <br />
  Besides the menus, this will be the most important feature for newcomers. To
  enable hints, toggle the "Show Hints" button (top menu bar). A hints section
  will appear IF the page contains vulnerabilities. The Hints are "smart"
  showing only those hints that will help on the particular page.
  <br />
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-2 text-center">Security Modes</h5>
  <br />
  <br />
  Mutillidae currently has three modes: completely insecure, client-side
  security and secure. In insecure and client-side mode, the pages are
  vulnerable to at least the topic they fall under in the menu. Note that
  client-side security mode is just as vulnerable as insecure mode, but
  JavaScript validation or HTML controls make exploits somewhat more difficult.
  <br />
  <br />
  In secure mode, Mutillidae attempts to protect the pages with server side
  scripts. Also, hints are disabled.
  <br />
  <br />
  The mode can be changed using the "Toggle Security" button on the top menu
  bar.
  <br />
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-2 text-center">"Help Me" Button</h5>
  <br />
  <br />
  The "Help Me" button provides a basic description of the vulnerabilities on
  the page for which the user should try exploits. Use this button to get a
  quick list of issues. Use the Hints to see more details.
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-3 text-center">Just give me the exploit</h5>
  <br />
  <br />
  Hints will typically provide some exploits. Known exploits that are used in
  testing Mutillidae are located in /documentation/mutillidae-test-scripts.txt.
  There is some documentation for each exploit which explains usage and
  location.
  <br />
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-2 text-center">Be Careful</h5>
  <br />
  <br />
  Mutillidae is a "live" system. The vulnerabilities are real rather than
  emulated. This eliminates the frustration of having to "know what the author
  wants". Because of this, there are likely undocumented vulnerabilities. Also,
  this project endangers any machine on which it runs. Best practice is to run
  Mutillidae in a virtual machine isolated from the network which is only booted
  when using Mutillidae. Every effort has been made to make Mutillidae ables run
  entirely off-line.
  <br />
  <br />
  <br />
  <h5 className="report-header user border border-dark rounded-2 col-lg-2 text-center">White Paper</h5> 
  <br />
  <br />
  A project whitepaper is available to explain the features of Mutillidae and
  suggested use-cases.
  <br />
  <br />
  <a
    href="https://www.sans.org/reading-room/whitepapers/application/introduction-owasp-mutillidae-ii-web-pen-test-training-environment-34380"
    target="_blank"
    title="Whitepaper: Introduction to OWASP Mutillidae II Web Pen Test Training Environment"
    className='text-decoration-none'
  >
    <img
      align="middle"
      alt="Webpwnized Twitter Channel"
      src="http://localhost/images/pdf-icon-48-48.png"
    />
    Introduction to OWASP Mutillidae II Web Pen Test Training Environment
  </a>
</>
      </div>

    </div>
  )
}

export default UsageInstruction