import React from 'react'
import styled from 'styled-components';
const UnrestrictedFileUpload = () => {
  return (
    <StyledUnrestrictedFileUpload>   
    <div>
  <div className=' box border w-100 rounded-3 border-2 mx-auto border-dark text-center  mt-5'>
    <h1>Upload a File</h1>
    </div>
 
  <div className=' box1 border col-5 rounded-3 border-2 mx-auto border-dark text-center  mt-5'>
  <h1>Please Choose file to upload</h1>
  </div>
  </div>
    
  <div className='col-5 d-flex text-center mx-auto mt-4'>
    <h3>Filename</h3>
    <div className='border border-4 col-8 rounded-2 ms-3  '></div>
    
    <img className='fs-3 ms-2' src='http://localhost/images/upload-32-32.png'></img>
  </div>
  <div  className='col-5 text-center mx-auto mt-4'>
    <button className='rounded-2 border-2 border-dark ps-4 pe-4'><h3>Upload File</h3></button>
  </div>


    </StyledUnrestrictedFileUpload> 
  )
}

export default UnrestrictedFileUpload
const StyledUnrestrictedFileUpload = styled.div`
.box{
    background-color:#E1E1E1;}
.box1 {background-color:#F2DFC3 ; }


`