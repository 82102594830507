import React from 'react'
import Login from '../../OWASP-2017/injection(sql)/Bypass-Authentication/Login'
import { useState } from 'react'
import axios from 'axios'
import Endpoint from '../../../assets/Endpoint';
import SecurityHeaders from '../../../assets/SecurityHeaders';
import styled from 'styled-components';
import UserDetails from '../../../components/UserDetails';
import LoginToast from '../../../components/LoginToast';
import Title from '../../../components/Title';
const InsufficientLogin = () => {

  const [username, setUsername] =useState(null)
  const [password, setPassword] = useState(null)
  const [data,setData] = useState('')
  const [show,setShow] = useState(false)

  const HandleLogin = async () => {
      try {
          const data = new FormData()
          data.append('user_name', username);
          data.append('password', password);

          const res = await axios.post(Endpoint + "oswap-2010/A9/transport-layer-protection-login.php", data, SecurityHeaders);
          if(res.data.status==='success'){
            setData(res.data)
          }
          if(res.data.message){
            setShow(true);
          }

        //   if (res && res.data.status === "success") {
        //       if (res.data.user_id !== null && res.data.user_id !== "" && res.data.user_id !== undefined) {
        //           localStorage.setItem("user_id", JSON.stringify(res.data.user_id))
        //           window.location.reload()
        //       }
        //   }
      } catch (error) {
          console.error("Error occurred during login:", error);
          // Handle the error appropriately, e.g., show a message to the user
      }
  }


  return (
    <>
  <Style>

<Title title={'User Lookup'}/>
  <div className="col-5 mx-auto mt-4">
            <div className="info-box rounded border-black border">
              <h5 className="text-center">
                Please enter username and password to view account details
              </h5>
            </div>
            <form onSubmit={(e) => { e.preventDefault(); HandleLogin(); }}>
              <div className="d-flex align-items-center mt-4 ">
                <div className="col-3">
                  <h6>Name</h6>
                </div>
                <div className="col-7">
                  <input
                    className="form-control ms-3 border border-black"
                    placeholder="User name" value={username} onChange={(e) => setUsername(e.target.value)}
                    required />
                </div>
              </div>
              <div className="d-flex align-items-center mt-3 ">
                <div className="col-3">
                  <h6>Password</h6>
                </div>
                <div className="col-7">
                  <input
                    className="form-control ms-3 border border-black"
                    placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}
                    required />
                </div>
              </div>

              <div className="d-flex justify-content-center col-12 mt-3">
                <button className="btn  btn-primary">view account details</button>
              </div>

              </form>





              <div>
                <p className="text-center mt-3">Don't have an account?<a className="nav-link" href="#"> Please register here</a></p>
              </div>
            
          </div>
          <div className="col-12 mt-3 mx-auto">


{data.data ?
  <UserDetails data={data} />
  : null}
<>
  {
    data.message ? <LoginToast toast={show} /> : null
  }</>

</div>
</Style>
    </>
  )
}

export default InsufficientLogin


export const Style = styled.div`
  .buttonAD {
    background-color: #e8eae2 !important;
  }
  .headbox {
    background-color: #bc6c2550 !important;
  }


`;