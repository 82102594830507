import React from 'react'
import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab25 = () => {
    return (
        <div>

            <Title title={"Lab 25: Cross-Site Scripting - Creating a Cross-site Script Proof of Concept (PoC)"} />

            <StyledLab25>
                <div className='box1 border border-dark col-5 mx-auto rounded'>
                    <h5 className='text-center p-0 m-0'>In the particular cross-site script used in the lab, what is</h5>
                    <h5 className='text-center p-0 m-0'>the purpose of the script element?{`<script>`} alert('XSS');</h5>
                    <h5 className='text-center p-0 m-0'>{`</script>`}</h5>
                </div>
                <div className='col-5 mx-auto m-3'>
                    <div className=' d-flex'>
                        <div>
                            <input type="radio"></input></div>
                        <div>
                            <h6 className='p-0 m-0'> The script element provides the padding needed to offset the
                            </h6>
                            <h6>injection</h6></div>

                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <input type="radio"></input>

                        <h6 className='p-0 m-0'> The element restarts the browser engine</h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <input type="radio"></input>

                        <h6 className='p-0 m-0'>The element initializes the cross-site scripting filters
                        </h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <div>
                            <input type="radio"></input></div>
                        <div>
                            <h6 className='p-0 m-0'>This injection happens to land within HTML. The script element tells
                            </h6>
                            <h6 className='p-0 m-0'>the browser to pause processing HTML and execute the JavaScript
                            </h6>
                            <h6 className='p-0 m-0'>within
                            </h6>
                        </div>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <input type="radio"></input>
                        <h6 className='p-0 m-0'>The element refreshes the page
                        </h6>
                    </div>
                </div>
                <div className=' submit col-1 mx-auto '>
                    <button className='rounded pe-3 ps-3 m-2'>
                        Submit
                    </button>
                </div>
                <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
                    <h6> Choose the best answer or view Hints and Videos</h6>
                </div>
                <div className='d-flex justify-content-center m-2 fs-5'>
                    <a className='  text-decoration-none p-2 '>
                        Previous Lab
                    </a>
                    <div className='p-2'>|</div>
                    <a className='  text-decoration-none p-2 fs-5 '>
                        Next Lab
                    </a>

                </div>
            </StyledLab25>
        </div>
    )
}

export default Lab25

const StyledLab25 = styled.div
    `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`