import React from 'react'
import styled from 'styled-components';
import Title from '../../../components/Title';
const DirectoryBrowsing = () => {
  return (
   
    <StyledDirectoryBrowsing> 
   <div>
   <Title title={"Directory Browsing"}/>
   <div className=' box1 border col-5 rounded-3 border-2 mx-auto border-dark text-center  mt-5'>
    <h1> Directory Browsing</h1>
</div>
<div className='mt-4  col-5 mx-auto  fs-4'>
    <p>Some web servers are misconfigured and allow directory browsing. This an easy
    mistake to make. While most sites disable directory browsing on the "home" or root
    page, some allow browsing on other directories. For each folder found in the site,
    attempt to browse to the folder without the page name. If using grep, look for "Index
    Of" as a match.</p>
    <p className='mt-4 fs-4 '>OWASP Mutillidae II seems to disallow directory browsing on the root page. Try
    browsing to http://localhost/mutillidae. Likely this will load the home page. However,
    the site may not be configured perfectly. Perhaps if a folder name was known, we
    could try to browse to that folder {`(i.e. - http://localhost/mutillidae/<folder/>)`}.</p>
    <p className='mt-4 fs-4'>If help is needed figuring out folder names, try activating hints.</p>

</div>


   
    </div>
    </StyledDirectoryBrowsing>
  )
}

export default DirectoryBrowsing
const StyledDirectoryBrowsing = styled.div`
.box1 {background-color:#F2DFC3 ;}
.box{
    background-color:#E1E1E1;}

`

