import React from 'react'
import User from '../../components/User'

const Linux = () => {
  return (
    <div className='container mt-3'>
        <User user ={"Installation"}/>
        <a href="" className='text-decoration-none'>mutillidae-installation-on-xampp-win7.pdf</a>
    </div>
  )
}

export default Linux