import React from 'react'
import styled from 'styled-components'
const CreateUserPage = () => {
    return (
        <StyledHelloPage>
            <div>

                <div className='container '>
                    <a className='text-light ms-4'>Close</a>
                    <div className='d-flex'>
                        <p className='text-light ms-4'>Name :</p>
                        <p className='ms-2'>createUser</p>
                    </div>
                    <div className='d-flex ms-4'>
                        <p className='text-light'>Binding :</p>
                        <p className='ms-2'>ws-user-accountBinding</p>
                    </div>
                    <div className='d-flex ms-4'>
                        <p className='text-light'>EndPoint :</p>
                        <p className='ms-2'>http://localhost/webservices/soap/ws-hello-world.php</p>
                    </div>
                    <div className='d-flex ms-4'>
                        <p className='text-light'>SoapAction :</p>
                        <p className='ms-2'>urn:ws-user-account#createUser</p>
                    </div>
                    <div className='d-flex ms-4'>
                        <p className='text-light'>Style :</p>
                        <p className='ms-2'>rpc</p>
                    </div>
                    <div className='ms-4'>
                        <p className='text-light'>Input:</p>

                        <p> use: encoded</p>
                        <p> namespace: urn:hellowsdl</p>
                        <p> encodingStyle: http://schemas.xmlsoap.org/soap/encoding/</p>
                        <p> message: createUser</p>
                        <p> parts:</p>
                        <p> username: xsd:string</p>
                        <p> password: xsd:string</p>
                        <p> signature: xsd:string</p>

                    </div>
                    <div className='ms-4'>
                        <p className='text-light'>Output:</p>

                        <p> use: encoded</p>
                        <p> namespace: urn:ws-user-account</p>
                        <p> encodingStyle: http://schemas.xmlsoap.org/soap/encoding/</p>
                        <p> message: createUserResponse</p>
                        <p> parts:</p>
                        <p> name: xsd:xml</p>

                    </div>
                    <div className='d-flex ms-4'>
                        <p className='text-light'>Namespace :</p>
                        <p className='ms-2'> urn:ws-user-account</p>
                    </div>
                    <div className='d-flex ms-4'>
                        <p className='text-light'>Transport :</p>
                        <p className='ms-2'>http://schemas.xmlsoap.org/soap/http</p>
                    </div>
                    <div className='d-flex ms-4'>
                        <p className='text-light'>Documents :</p>
                        <p className='ms-2'>Creates new user account</p>
                    </div>

                    <div className='m-4'>
                        <p>Sample Request (Copy and paste into Burp Repeater)</p>

                        <p>POST /mutillidae/webservices/soap/ws-hello-world.php HTTP/1.1</p>
                        <p>Accept-Encoding: gzip,deflate</p>
                        <p>Content-Type: text/xml;charset=UTF-8</p>
                        <p>SOAPAction: "urn:hellowsdl#hello"</p>
                        <p>Content-Length: 587</p>
                        <p>Host: localhost</p>
                        <p>Connection: Keep-Alive</p>
                        <p>User-Agent: Apache-HttpClient/4.1.1 (java 1.5)</p>
                    </div>

                    <div className='m-4'>
                        <pre >
                            {`<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:ws-user-account">
<soapenv:Header/>
<soapenv:Body>
<urn:createUser soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
<username xsi:type="xsd:string">Joe2</username>
<password xsi:type="xsd:string">Holly</password>
<signature xsi:type="xsd:string">Try Harder</signature>
</urn:createUser>
</soapenv:Body>
</soapenv:Envelope>
`}
                        </pre>

                    </div>


                </div>

            </div>






        </StyledHelloPage>
    )
}

export default CreateUserPage
const StyledHelloPage = styled.div`
p,h1,h2,h3,h4,h5,h6{
    margin:0px;
    padding:0px;
}
.container{
    background-color:#b2b2b2;
}

`