import React from "react";
import styled from "styled-components";

const Repeater = () => {
  return (
    <div>
      <Style>
        <div className="col-6 mx-auto">
          <div className=" border border-1 border-black title rounded-3">
            <p className=" text-center fs-6 fw-bold p-1 m-0">Please enter string to repeat</p>
          </div>

          <div className="mt-5 ">
            <div className="d-flex justify-content-between col-8 mx-auto ">
                <b>String to Repeat</b>
                <input className="rounded-3" />
            </div>

            <div className="d-flex justify-content-between col-8 mx-auto mt-3 ">
                <b>Number of times to Repeat</b>
                <input className="rounded-3" />
            </div>

          </div>

          <div className=" mt-5 text-center">
            <div className="mx-auto btn btn-secondary border border-1 border-black m-0 p-1 " ><b className="m-0 p-0">Repeat String</b></div>
          </div>

        </div>
      </Style>
    </div>
  );
};

export default Repeater;

export const Style = styled.div`
  .title {
    background-color: #bc6c2550 !important;
  }
`;
