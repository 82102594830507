import React from 'react'
import Login from '../Bypass-Authentication/Login'

const ViaRestUserAccountManagement = () => {
  return (
   <>
   <Login></Login>
   </>
  )
}

export default ViaRestUserAccountManagement