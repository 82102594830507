import React from 'react'


import DownloadSection from '../DownloadSection'
import Title from '../Title'

const WebLogs = (props) => {

    const data = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    return (


        <div>

            <section className='container-fluid'>
                <Title title='Log' />

                <div className='col-lg-4 m-4'>
                    <DownloadSection />
                </div>

                <table className='col-lg-12 table  striped bodered hover'>
                    <thead>
                        <tr className='header text-center border border-dark'>
                            <td colspan="10">
                                <span>
                                    <img width={32} className='' src={props.path} />
                                    <b>37 log records found</b>
                                </span>
                                <span title="Click to refresh log file" >
                                    <img width="32px" src={props.path1} />
                                    <b>Refresh Logs</b>
                                </span>
                                <span title="Click to delete log file">
                                    <img width="32px" src={props.path2} />
                                    <b> Delete Logs</b>
                                </span>
                            </td>
                        </tr>
                        <tr class="report-header border border-dark">
                            <td ><b>Hostname</b></td>
                            <td ><b>IP</b></td>
                            <td ><b>Browser Agent</b></td>
                            <td ><b>Message</b></td>
                            <td ><b>Date/Time</b></td>
                        </tr>

                    </thead>

                    <tbody>
                        {data.map((index) => (
                            <tr className='border border-dark'>
                                <td>::1</td>
                                <td>::1</td>
                                <td>Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36</td>
                                <td>User visited: text-file-viewer.php</td>
                                <td>2024-03-04 15:50:20</td>
                            </tr>

                        ))}

                    </tbody>
                </table>
            </section>


        </div>
    )
}

export default WebLogs