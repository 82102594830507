import React from "react";
import styled from "styled-components";

const ViewUserPrivilege = () => {
  return (
    <div>
      <Style>
        <div className=" border border-1 border-black title rounded-3">
          <p className=" text-center fs-6 fw-bold p-1 m-0">
            User Privilege Level
          </p>
        </div>

        <div className="mt-5 ">
          <div className="d-flex justify-content-between col-6 mx-auto m-0 ">
            <b>Application ID</b>
            <div>
              <p className="m-0"> A1B2</p>
            </div>
          </div>

          <div className="d-flex justify-content-between col-6 mx-auto m-0 ">
            <b>User ID</b>
            <div>
              <p className="m-0">100 ( Hint: 0X31 0X30 0X30 )</p>
            </div>
          </div>

          <div className="d-flex justify-content-between col-6 mx-auto m-0 ">
            <b>Group ID</b>
            <div>
              <p className="m-0">100 ( Hint: 0X31 0X30 0X30 )</p>
            </div>
          </div>
        </div>

        <div className="mx-auto col-3  mt-3">
          <b className="text-center mx-auto d-block">
            Note: UID/GID "000" is root.
          </b>
          <b className="text-center mx-auto d-block">
            {" "}
            You need to make User ID and Group ID equal to "000" to become root
            user.
          </b>
        </div>

        <div className="mx-auto col-3  mt-3">
          <b className="text-center mx-auto d-block">
            
          </b>
          <b className="text-center mx-auto d-block">
            {" "}
            Security level 1 requires three times more work but is not any
            harder to solve.    
          </b>
        </div>
      </Style>
    </div>
  );
};

export default ViewUserPrivilege;

export const Style = styled.div`
  .title {
    background-color: #bc6c2550 !important;
  }
`;
