import React from 'react'
import Login from '../../injection(sql)/Bypass-Authentication/Login'

const JsonCurrentUserInformation = () => {
  return (
    <>
    <Login></Login>
    </>
  )
}

export default JsonCurrentUserInformation