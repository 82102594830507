import React from 'react'

const CurrentUserInformation = () => {
  return (
    <div>
        <h5 className='m-4'>Not logged in. Please <a href="/login">login/register</a> first...</h5>
    </div>
  )
}

export default CurrentUserInformation