import React from 'react'

const UserPoll = () => {
  return (
    <>
    
    <div className='border rounded rounded-3'>
                        <div className='p-3'>
                        <p><u><b>User Poll</b></u></p>
                        </div>
                        <div className='col-lg-6 mx-auto'>
                            
                            <h4 className='bg-secondary text-white rounded p-1 text-center'>Choose Your Favorite Security Tool</h4>
                            <h5 className='text-center'>Initial your choice to make your vote count</h5>
                            
                            <div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>nmap</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>wireshark</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>tcpdump</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>netcat</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>metasploit</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>kismet</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>Cain</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>Ettercap</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>paros</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>Burp Suite</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>Sysinternals</p>
                                </div>
                                <div className='d-flex align-items-center'>
                                <input type='radio'/><p className='ms-2 m-0 p-0'>inSIDDer</p>
                                </div>
                                <div>
                                    <label><b>Your initials:</b></label>
                                    <input type='text' className='rounded'/>
                                </div>
                                <div className='text-center mt-3'>
                                    <button className='btn btn-light border border-dark'>submit vote</button>
                                </div>
                                <div className='mt-2'>
                            <h4 className='bg-success text-white rounded p-1 text-center'>No Choice Selected</h4>

                                </div>
                            </div>

                        </div>

                    </div>
    </>
  )
}

export default UserPoll