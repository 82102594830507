import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./lab/home/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Common from "./components/Common";
import Credits from "./lab/2013csrf/unvalid/Credits";
import Reset from "./lab/2013csrf/unvalid/Reset";
import ViaCookies from './lab/OWASP-2017/A2-Broken Authentication and Session Management/Authentication Bypass/ViaCookies';
import AddYourBlog from "./lab/2013csrf/AddYourBlog";
import RegisterAccount from "./lab/2013csrf/RegisterAccount";
import UserPoll from "./lab/2013csrf/UserPoll";
import CaptureData from "./lab/A1-others/applicationloginjection/CaptureData";
import DnsLookup from "./lab/A1-others/applicationloginjection/DnsLookup";
import DocumentViewer from "./lab/A1-others/applicationloginjection/DocumentViewer";
import EchoMessage from "./lab/A1-others/applicationloginjection/EchoMessage";
import EchoMessageCsp from "./lab/A1-others/applicationloginjection/EchoMessageCsp";
import TestConnectivityServer from "./lab/A1-others/applicationloginjection/TestConnectivityServer";
import Repeater from "./lab/A1-others/bufferoverflow/Repeater";
import ViewUserPrivilege from "./lab/A1-others/cbc-bit-flipping/ViewUserPrivilege";
import StyleToMutilidae from "./lab/A1-others/command-injections/StylingToMutilidae";
import SetBackGroundColor from "./lab/A1-others/CssInjection/SetBackGroundColor";
import SiteFooter from "./lab/A1-others/htmlhttpheaders/SiteFooter";
import ArbitraryFileInclusion from "./lab/A1-others/htmlinjections/ArbitraryFileInclusion";
import BackButtons from "./lab/A1-others/htmlinjections/BackButtons";
import Webstorage from "./lab/A1-others/htmlinjections/HTML5WebStorage";
import Mutilidae from "./lab/A1-others/htmlinjections/Mutillidae";
import PasswordGenerator from "./lab/A1-others/htmlinjections/PasswordGenerator";
import ConferenceRoom from "./lab/A1-others/LDAPInjection/ConferenceRoomLookup";
import XmlValidation from "./lab/A1-others/XMLexternalinjection/XmlValidation";
import InstallLinux from "./lab/documention/InstallationLinux";
import Linux from "./lab/documention/Linux";
import UsageInstruction from "./lab/documention/UsageInstruction";
import Vulnerabilities from "./lab/documention/Vulnerabilities";
import CodeViewer from "./lab/oswap2010/insecure cryptographic/CodeViewer";
import EditProfile from "./lab/oswap2010/insecure cryptographic/EditProfile";
import HtmlStorage from "./lab/oswap2010/insecure cryptographic/EditProfile";
import Robots from "./lab/oswap2010/insecure cryptographic/Robots";
import Secret from "./lab/oswap2010/insecure cryptographic/Secret";
import UserLookup from "./lab/oswap2010/insecure cryptographic/UserLookup";
import ViewUser from "./lab/oswap2010/insecure cryptographic/ViewUser";
import Lab53 from "./lab/labs/csp/Lab53";
import Lab54 from "./lab/labs/csp/Lab54";
import Lab55 from "./lab/labs/csp/Lab55";
import Lab50 from "./lab/labs/lab50/TestingHttpResponce";
import Lab51 from "./lab/labs/lab51/ServerConfigurationScanningwithNikto";
import Lab52 from "./lab/labs/lab52/ScanningwithSSLScan";
import ClickJacking from "./lab/Others/farming/ClickJacking";
import ThirdPartyFarming from "./lab/Others/farming/ThirdPartyFarming";
import ControlChallenge from "./lab/Others/securitycontrol/ControlChallenge";
import TextFileViewer from "./lab/Others/Service/TextFileViewer";
import WebLogs from "./lab/Others/Service/WebLogs";
import FileUpload from "./lab/Others/unrestricted/FileUpload";
import DownloadSection from "./lab/Others/DownloadSection";
import Lab1 from "./lab/labs/how the web works/Lab1";
import Lab2 from "./lab/labs/how the web works/Lab2";
import Lab3 from "./lab/labs/how the web works/Lab3";
import Lab4 from "./lab/labs/how the web works/Lab4";
import Lab5 from "./lab/labs/how the web works/Lab5";
import Lab6 from "./lab/labs/Sqlinjection/Lab6";
import Lab7 from "./lab/labs/Sqlinjection/Lab7";
import Lab8 from "./lab/labs/Sqlinjection/Lab.8";
import Lab9 from "./lab/labs/Sqlinjection/Lab9";
import Lab10 from "./lab/labs/Sqlinjection/Lab10";
import Lab11 from "./lab/labs/Sqlinjection/Lab11";
import Labs12 from "./lab/labs/Commandinjection/Lab12";
import Lab13 from "./lab/labs/Commandinjection/Lab13";
import Lab14 from "./lab/labs/Commandinjection/Lab14";
import Lab15 from "./lab/labs/Commandinjection/Lab15";
import Lab16 from "./lab/labs/Commandinjection/Lab16";
import Lab17 from "./lab/labs/LDAP/Lab17";
import Lab18 from "./lab/labs/IDOR/Lab18";
import Lab19 from "./lab/labs/IDOR/LAb19";
import Lab20 from "./lab/labs/IDOR/Lab20";
import Lab21 from "./lab/labs/IDOR/Lab21";
import Lab22 from "./lab/labs/IDOR/Lab22";
import Lab23 from "./lab/labs/OpenRedirects/Lab23";
import Lab24 from "./lab/labs/OpenRedirects/Lab24";
import Lab25 from "./lab/labs/XSS/Lab25";
import Lab26 from "./lab/labs/XSS/Lab26";
import Lab27 from "./lab/labs/XSS/Lab27";
import Lab28 from "./lab/labs/XSS/Lab28";
import Lab29 from "./lab/labs/CSRF/Lab29";
import Lab30 from "./lab/labs/CSRF/Lab30";
import Lab31 from "./lab/labs/HTML5/Lab31";
import EditUserProfile from "./lab/OWASP-2017/A3-SensitiveDataExposure/InformationDisclosure/EditUserProfile";
import PHPInfoPage from "./lab/OWASP-2017/A3-SensitiveDataExposure/InformationDisclosure/PHPInfoPage";
import XMLValidator from "./lab/OWASP-2017/A4-XML-ExternalEntities/XML-ExternalEntityInjection/XMLValidator";
import CurrentUserInformation from "./lab/OWASP-2017/JasonWebToken/CurrentUserInformation";
import UserAccountManagement from "./lab/webservices/rest/SQLInjection/UserAccountManagement";
import CreateUsers from "./lab/webservices/soap/SQLinjection/CreateUserPage";
import DeleteUsers from "./lab/webservices/soap/SQLinjection/DeleteUserPage";
import GetUsersPage from "./lab/webservices/soap/SQLinjection/GetUserPage";
import LookupUserPage from "./lab/webservices/soap/SQLinjection/LookupUserPage";
import UpdateUserPage from "./lab/webservices/soap/SQLinjection/UpdateUserPage";
import HelloPage from "./lab/webservices/soap/testpages/HelloPage";
import HelloWorld from "./lab/webservices/soap/testpages/HelloWorld";
import ViewSomeonesBlog from "./lab/OWASP-2017/PersistentSecondorder/ViewSomeonesBlog";
import PenTestToolLookup from "./lab/OWASP 2007/improper error handling/PenTestToolLookup";
import PenTestToolLookupAjax from "./lab/A1-others/jsoninjection/PenTestToolLookupAjax";
import Register from "./lab/OWASP 2007/improper error handling/Register";
import DirectoryBrowsing from "./lab/OWASP-2017/A6-Miss Configuration/DirectoryBrowsing";
import UnrestrictedFileUpload from "./lab/OWASP-2017/A6-Miss Configuration/UnrestrictedFileUpload";
import UserAgentImpersonation from "./lab/OWASP-2017/A6-Miss Configuration/UserAgentImpersonation";
import CatcheControl from "./lab/OWASP 2007/A6-Information  Leakage/CacheControl";
import ClientsideComments from "./lab/OWASP 2007/A6-Information  Leakage/ClientsideComments";
import UserInfo from "./lab/OWASP-2017/injection(sql)/extact-data/UserInfo";
import AddBlog from "./lab/OWASP-2017/injection(sql)/insert-injection/AddBlog";
import SqlmapTarget from './lab/OWASP-2017/injection(sql)/sqlmap/SqlmapTarget';
import ViewBlog from './lab/OWASP-2017/injection(sql)/sqlmap/ViewBlog'
import Lab63 from './lab/labs/software-composition/OWASPDependencyCheck63'
import Lab62 from './lab/labs/software-composition/Retire62'
import A1IRegisterUser from './lab/A1-others/applicationloginjection/A1IRegisterUser';
import Login from "./lab/OWASP-2017/injection(sql)/Bypass-Authentication/Login";
import EchoCORS from './lab/A1-others/htmlinjections/EchoMessage';
import SSLMisconfigration from './lab/oswap2010/insecure cryptographic/SSLMisconfiguration'
import LFI from './lab/OWASP-2017/A5-Broken Access Control/Insecure Direct Object Refrences (IDOR)/Local File Inclusiion (LFI)/LocalFileInclusiion';
import RFI from './lab/OWASP-2017/A5-Broken Access Control/Insecure Direct Object Refrences (IDOR)/Remote File Inclusiion (RFI)/RemoteFileInclusion';
import CookiesasTokens from './lab/OWASP-2017/A5-Broken Access Control/Missing Function Level Access Control/CookiesAsAuthTokens';
import BindSqlLogin from "./lab/OWASP-2017/injection(sql)/Blindsql-Timing/BlindSqlLogin";
import BindSqlUserInfo from "./lab/OWASP-2017/injection(sql)/Blindsql-Timing/BlindSqlUserInfo";
import SqliMapLogin from "./lab/OWASP-2017/injection(sql)/sqlmap/SqliMapLogin";
import SqliMapUserInfo from "./lab/OWASP-2017/injection(sql)/sqlmap/SqliMapUserInfo";
import ApplicationLogin from "./lab/A1-others/applicationloginjection/ApplicationLogin";
import InsertRegister from "./lab/OWASP-2017/injection(sql)/insert-injection/InsertRegister";
import HtmlInjectionUserInfo from "./lab/A1-others/htmlinjections/HtmlInjectionUserInfo";
import HtmlInjectionUserInfoXpath from "./lab/A1-others/htmlinjections/HtmlInjectionUserInfoXpath";
import HtmlInjectionRegister from "./lab/A1-others/htmlinjections/HtmlInjectionRegister";
import XpathInjectionUserInfoXpath from "./lab/A1-others/Xpath-Injection/XpathInjectionUserInfoXpath";
import ViaAccountHijackingLogin from "./lab/OWASP-2017/A2-Broken Authentication and Session Management/Authentication Bypass/ViaAccountHijackingLogin";
import ViaBruteForceLogin from "./lab/OWASP-2017/A2-Broken Authentication and Session Management/Authentication Bypass/ViaBruteForceLogin";
import ViaSqlInjectionLogin from "./lab/OWASP-2017/A2-Broken Authentication and Session Management/Authentication Bypass/ViaSqlInjectionLogin";
import PriviliegeEscalationLogin from "./lab/OWASP-2017/A2-Broken Authentication and Session Management/Priviliege Escalation/PriviliegeEscalationLogin";
import UsernameEnumerationLogin from "./lab/OWASP-2017/A2-Broken Authentication and Session Management/Username-Enumeration/UsernameEnumerationLogin";
import CurrentUserInformationLogin from "./lab/OWASP-2017/A3-SensitiveDataExposure/JSON Web Token(JWT)/CurrentUserInformationLogin";
import JsonCurrentUserInformation from "./lab/OWASP-2017/A5-Broken Access Control/JSON Web Token (JWT)/JsonCurrentUserInformation";
// import MethodTamperingUserInfo from "./lab/OWASP-2017/MethodTamperingGetforPOST/MethodUserInfo";
// import MethodTamperingUserInfoXpath from "./lab/OWASP-2017/MethodTamperingGetforPOST/MethodUserInfoXpath";
import ReflectedRegister from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Reflected(first-order)/ReflectedRegister";
import ReflectedUserInfo from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Reflected(first-order)/ReflectedUserInfo";
import ReflectedUserInfoXpath from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Reflected(first-order)/ReflectedUserInfoXpath";
import PersistentRegister from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Persistent(second-order)/PersistentRegister";
import CsrfRegister from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/cross-site-request-forgery(csrf)/CsrfRegister";
import ViaInputUserInfo from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Via-input(Get/ViaInputUserInfo";
import ViaInputUserInfoXpath from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Via-input(Get/ViaInputUserInfoXpath";
import BeefRegister from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Beef-framework-targets/BeefRegister";
import Beefuserinfo from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Beef-framework-targets/Beefuserinfo";
import BeefUserInfoXpath from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Beef-framework-targets/BeefUserInfoXpath";
import CsrfRegisterUser from "./lab/2013csrf/A8-crosssite-requestforgery(csrf)/CsrfRegisterUser";
import InsecureUserInfo from "./lab/oswap2010/insecure cryptographic/InsecureUserInfo";
import InsecureUserInfoXpath from "./lab/oswap2010/insecure cryptographic/InsecureUserInfoXpath";
import InsufficientLogin from "./lab/oswap2010/A9-InsufficientTransportLayerProtection/InsufficientLogin";
import InsufficientUserInfo from "./lab/oswap2010/A9-InsufficientTransportLayerProtection/InsufficientUserInfo";
import InsufficientUserInfoXpath from "./lab/oswap2010/A9-InsufficientTransportLayerProtection/InsufficientUserInfoXpath";
import ImproperLookupUser from "./lab/OWASP 2007/A6-Improper-Error-Handling/ImproperLookupUser";
import ImproperLogin from "./lab/OWASP 2007/A6-Improper-Error-Handling/ImproperLogin";
import ImproperUserInfo from "./lab/OWASP 2007/A6-Improper-Error-Handling/ImproperUserInfo";
import ImproperUserInfoXpath from "./lab/OWASP 2007/A6-Improper-Error-Handling/ImproperUserInfoXpath";
import JvbLogin from "./lab/Others/javascript-validation/JvbLogin";
import JvbUserInfo from "./lab/Others/javascript-validation/JvbUserInfo";
import JvbUserInfoXpath from "./lab/Others/javascript-validation/JvbUserInfoXpath";
import ViaRestUserAccountManagement from "./lab/OWASP-2017/injection(sql)/Via-rest-webservices/ViaRestUserAccountManagement";
 import MethodUserInfoXpath from "./lab/OWASP-2017/A6-Miss Configuration/MethodTamperingGetforPOST/MethodUserInfoXpath";
import RegisterUsers from "./lab/A1-others/applicationloginjection/A1IRegisterUser";
import ImproperRegister from "./lab/OWASP 2007/A6-Improper-Error-Handling/ImproperRegister";
import UsernameEnumerationLookupUser from "./lab/OWASP-2017/A2-Broken Authentication and Session Management/Username-Enumeration/UsernameEnumerationLookupUser";
import UsernameEnumerationUAM from "./lab/OWASP-2017/A2-Broken Authentication and Session Management/Username-Enumeration/UsernameEnumerationUAM";
import Delete from '../src/pages/home/Delete';
import Template from "./components/Template";
import PriceEdit from '../src/pages/home/PriceEdit';
import ToolsDetails from '../src/pages/tools/ToolsDetails';
import Edit from '../src/pages/toolslist/Edit';
import Signup from "./components/Signup";
import Tools from '../src/pages/tools/Tools';
import Labs from '../src/pages/labs/Labs';
import About from '../src/pages/about/About';
import Features from '../src/pages/features/Features';
import OurHackers from '../src/pages/our-hachers/OurHackers';
import LeaderBoard from '../src/pages/leader-board/LeaderBoard';
import Home from '../src/pages/home/Home';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Facebook from "./lab/socialmedia/Facebook";
import BlindSqlLogin from "./lab/OWASP-2017/injection(sql)/Blindsql-Timing/BlindSqlLogin";
import ViaLookupUser from "./lab/OWASP-2017/injection(sql)/Viasoap-webservices/ViaLookupUser";
import ViaAccountHijacking from "./lab/OWASP-2017/A5-Broken Access Control/Insecure Direct Object Refrences (IDOR)/ViaAccountHijacking";
import PollQuestion from "./lab/OWASP-2017/A6-Miss Configuration/MethodTamperingGetforPOST/PollQuestion";
import MethodUserInfo from "./lab/OWASP-2017/A6-Miss Configuration/MethodTamperingGetforPOST/MethodUserInfo";
import Test from "./Test";
import ViaXpathInjectionUserInfo from "./lab/OWASP-2017/A7-cross-site-scripting(xss)/Via-Xpath-Injection.jsx/ViaXpathInjectionUserInfo";

function App() {
  return (
    <BrowserRouter>
    

<Header/>



<Routes>
          <Route
            path="/"
            exact
            element={
             
                <Home />
              
            }
          />
          <Route
            path="/test-card"
            exact
            element={
             
                <Test />
              
            }
          />
          <Route
            path="/leader-board"
            element={
             
                <LeaderBoard />
              
            }
          />
          <Route
            path="/our-hackers"
            element={
              
                <OurHackers />
             
            }
          />
          <Route
            path="/features"
            element={
              
                <Features />
              
            }
          />
          <Route
            path="/about"
            element={
              <>
                
                  <About />
                
              </>
            }
          />
          <Route
            path="/labs"
            element={
              
                <Labs />
              
            }
          />
          <Route
            path="/tools"
            element={
             
                <Tools />
              
            }
          />
       {/*<Route
            path="/login"
            element={
              <Template>
                <Login />
              </Template>
            }
          />*/}
          <Route
            path="/signup"
            element={
              
                <Signup />
              
            }
          />

          <Route path="/edit/:id"
            element={
             
                <Edit />
              
            }
          />

          <Route path="/ToolsDetails/:id"
            element={
              
                <ToolsDetails />
              
            }
          />



          <Route
            path="/priceEdit/:id"
            element={
            
                <PriceEdit />
              
            }
          />
          <Route
            path="/Delete/:id"
            element={
             
                <Delete />
              
            }
          />

        </Routes>
    
       

<Routes>



<Route path="/lab-home" element={<Common><HomePage /></Common>} />

{/* 2013 csrf */}

  <Route path="/reset" element={<Common><Reset /></Common>} />
  <Route path="/addyourblog" element={<Common><AddYourBlog /></Common>} />
  <Route path="/registeraccount" element={<Common><RegisterAccount /></Common>} />
  <Route path="/userpoll" element={<Common><UserPoll /></Common>} />

{/* A1-others */}

  <Route path="/application-login" element={<Common><ApplicationLogin /></Common>} />
  <Route path="/application-user-register" element={<Common><A1IRegisterUser /></Common>} />

  <Route path="/capturedata" element={<Common><CaptureData /></Common>} />
  <Route path="/dnslookup" element={<Common><DnsLookup /></Common>} />
  <Route path="/documentviewer" element={<Common><DocumentViewer /></Common>} />
  <Route path="/echomessage" element={<Common><EchoMessage /></Common>} />
  <Route path="/echomessagecsp" element={<Common><EchoMessageCsp /></Common>} />
  <Route
    path="/testconnectivityserver"
    element={<Common><TestConnectivityServer /></Common>}
  />
  <Route path="/repeater" element={<Common><Repeater /></Common>} />
  <Route path="/view-user-privilege" element={<Common><ViewUserPrivilege /></Common>} />
  <Route path="/dnslookup" element={<Common><DnsLookup /></Common>} />
  <Route path="/stylemutilidae" element={<Common><StyleToMutilidae /></Common>} />
  <Route path="/setbackground" element={<Common><SetBackGroundColor /></Common>} />
  <Route path="/sitefooter" element={<Common><SiteFooter /></Common>} />
  <Route
    path="/arbiteraryfileinclusion"
    element={<Common><ArbitraryFileInclusion /></Common>}
  />
  <Route path="/html-injection-user-info" element={<Common><HtmlInjectionUserInfo /></Common>} />
  <Route path="/html-injection-user-info-xpath" element={<Common><HtmlInjectionUserInfoXpath /></Common>} />
  <Route path="/html-injection-register" element={<Common><HtmlInjectionRegister /></Common>} />


  <Route path="/backbutton" element={<Common><BackButtons /></Common>} />
  <Route path="/webstorage" element={<Common><Webstorage /></Common>} />
  <Route path="/mutilidae" element={<Common><Mutilidae /></Common>} />
  <Route path="/password-generator" element={<Common><PasswordGenerator /></Common>} />
  <Route path="/pentesttoolajax" element={<Common><PenTestToolLookupAjax /></Common>} />
  <Route path="/Conferenceroom" element={<Common><ConferenceRoom /></Common>} />
  <Route path="/xml-validation" element={<Common><XmlValidation /></Common>} />
  <Route path="/xpath-injection-userinfo-xpath" element={<Common><XpathInjectionUserInfoXpath /></Common>} />


{/* Documention */}

  <Route path="/install-linux" element={<Common><InstallLinux /></Common>} />
  <Route path="/linux" element={<Common><Linux /></Common>} />
  <Route path="/UsageInstruction" element={<Common><UsageInstruction /></Common>} />
  <Route path="/Vulnerabilities" element={<Common><Vulnerabilities /></Common>} />

{/* 2010 insecure */}

  <Route path="ssl" element={<Common><SSLMisconfigration /></Common>} />
  <Route path="/codeviewer" element={<Common><CodeViewer /></Common>} />
  <Route path="/Editprofile" element={<Common><EditProfile /></Common>} />
  <Route path="/htmlstorage" element={<Common><HtmlStorage /></Common>} />
  <Route path="/robots" element={<Common><Robots /></Common>} />
  <Route path="/secret" element={<Common><Secret /></Common>} />
  <Route path="/userlookup" element={<Common><UserLookup /></Common>} />
  <Route path="/viewuser" element={<Common><ViewUser /></Common>} />
  <Route path="/2010/A9/login" element={<Common><InsufficientLogin /></Common>} />




{/* Labs */}



  <Route path="/lab1" element={<Common><Lab1 /></Common>} />
  <Route path="/lab2" element={<Common><Lab2 /></Common>} />
  <Route path="/lab3" element={<Common><Lab3 /></Common>} />
  <Route path="/lab4" element={<Common><Lab4 /></Common>} />
  <Route path="/lab5" element={<Common><Lab5 /></Common>} />
  <Route path="/lab6" element={<Common><Lab6 /></Common>} />
  <Route path="/lab7" element={<Common><Lab7 /></Common>} />
  <Route path="/lab8" element={<Common><Lab8 /></Common>} />
  <Route path="/lab9" element={<Common><Lab9 /></Common>} />
  <Route path="/lab10" element={<Common><Lab10 /></Common>} />
  <Route path="/lab11" element={<Common><Lab11 /></Common>} />
  <Route path="/lab12" element={<Common><Labs12 /></Common>} />
  <Route path="/lab13" element={<Common><Lab13 /></Common>} />
  <Route path="/lab14" element={<Common><Lab14 /></Common>} />
  <Route path="/lab15" element={<Common><Lab15 /></Common>} />
  <Route path="/lab16" element={<Common><Lab16 /></Common>} />
  <Route path="/lab17" element={<Common><Lab17 /></Common>} />
  <Route path="/lab18" element={<Common><Lab18 /></Common>} />
  <Route path="/lab19" element={<Common><Lab19 /></Common>} />
  <Route path="/lab20" element={<Common><Lab20 /></Common>} />
  <Route path="/lab21" element={<Common><Lab21 /></Common>} />
  <Route path="/lab22" element={<Common><Lab22 /></Common>} />
  <Route path="/lab23" element={<Common><Lab23 /></Common>} />
  <Route path="/lab24" element={<Common><Lab24 /></Common>} />
  <Route path="/lab25" element={<Common><Lab25 /></Common>} />
  <Route path="/lab26" element={<Common><Lab26 /></Common>} />
  <Route path="/lab27" element={<Common><Lab27 /></Common>} />
  <Route path="/lab28" element={<Common><Lab28 /></Common>} />
  <Route path="/lab29" element={<Common><Lab29 /></Common>} />
  <Route path="/lab30" element={<Common><Lab30 /></Common>} />
  <Route path="/lab31" element={<Common><Lab31 /></Common>} />
  <Route path="/lab50" element={<Common><Lab50 /></Common>} />
  <Route path="/lab51" element={<Common><Lab51 /></Common>} />
  <Route path="/lab52" element={<Common><Lab52 /></Common>} />
  <Route path="/lab53" element={<Common><Lab53 /></Common>} />
  <Route path="/lab54" element={<Common><Lab54 /></Common>} />
  <Route path="/lab55" element={<Common><Lab55 /></Common>} />
  <Route path="/lab62" element={<Common><Lab62 /></Common>} />
  <Route path="/lab63" element={<Common><Lab63 /></Common>} />

{/* Others */}

  <Route path="/clickjacking" element={<Common><ClickJacking /></Common>} />
  <Route path="/thirdparty-farming" element={<Common><ThirdPartyFarming /></Common>} />
  <Route path="/control-challenge" element={<Common><ControlChallenge /></Common>} />
  <Route path="/textfileviewer" element={<Common><TextFileViewer /></Common>} />
  <Route element={<Common><WebLogs /></Common>} path="/weblogs" />
  <Route path="/fileupload" element={<Common><FileUpload /></Common>} />
  <Route path="/downloadsection" element={<Common><DownloadSection /></Common>} />

{/* 2017 */}

  <Route path="/user-enumeration-lookup" element={<Common><UsernameEnumerationLookupUser /></Common>} />
  <Route path="/uam" element={<Common><UsernameEnumerationUAM /></Common>} />

  <Route path="/bind-sql-login" element={<Common><BindSqlLogin /></Common>} />
  <Route path="/bind-sql-user-info" element={<Common><BindSqlUserInfo /></Common>} />
  <Route path="/sqli-map-login" element={<Common><SqliMapLogin /></Common>} />
  <Route path="/sqli-map-user-info" element={<Common><SqliMapUserInfo /></Common>} />
  <Route path="/via-lookup-user" element={<Common><ViaLookupUser/></Common>} />  
  <Route path="/insertregister" element={<Common><InsertRegister /></Common>} />
  <Route path="/via-account-hijacking-login" element={<Common><ViaAccountHijackingLogin /></Common>} />
  <Route path="/via-account-hijacking" element={<Common><ViaAccountHijacking /></Common>} />

  <Route path="/via-brute-Force-login" element={<Common><ViaBruteForceLogin /></Common>} />
  <Route path="/via-sql-injection-login" element={<Common><ViaSqlInjectionLogin /></Common>} />
  <Route path="/priviliege-escalation-login" element={<Common><PriviliegeEscalationLogin /></Common>} />
  <Route path="/username-enumeration-login" element={<Common><UsernameEnumerationLogin /></Common>} />
  <Route path="/json-currentuser-information-login" element={<Common><JsonCurrentUserInformation /></Common>} />
  <Route path="/reflected-register" element={<Common><ReflectedRegister /></Common>} />
  <Route path="/reflected-userinfo" element={<Common><ReflectedUserInfo /></Common>} />
  <Route path="/reflected-userinfo-xpath" element={<Common><ReflectedUserInfoXpath /></Common>} />
  <Route path="/persistent-register" element={<Common><PersistentRegister /></Common>} />
  <Route path="/csrf-register" element={<Common><CsrfRegister /></Common>} />
  <Route path="/via-input-user-info" element={<Common><ViaInputUserInfo /></Common>} />
  <Route path="/via-input-user-info-xpath" element={<Common><ViaInputUserInfoXpath /></Common>} />
  <Route path="/via-xpath-injection-user-info" element={<Common><ViaXpathInjectionUserInfo /></Common>} />

  <Route path="/beefregister" element={<Common><BeefRegister /></Common>} />
  <Route path="/beefuserinfo" element={<Common><Beefuserinfo /></Common>} />
  <Route path="/beefuserinfoxpath" element={<Common><BeefUserInfoXpath /></Common>} />
  <Route path="/csrf-register-user" element={<Common><CsrfRegisterUser /></Common>} />
  <Route path="/insecureuserinfo" element={<Common><InsecureUserInfo /></Common>} />
  <Route path="/insecure-userinfo-xpath" element={<Common><InsecureUserInfoXpath /></Common>} />
  <Route path="/insufficient-login" element={<Common><InsufficientLogin /></Common>} />
  <Route path="/insufficient-user-info" element={<Common><InsufficientUserInfo /></Common>} />
  <Route path="/insufficient-user-info-xpath" element={<Common><InsufficientUserInfoXpath /></Common>} />
  <Route path="/improper-lookupuser" element={<Common><ImproperLookupUser /></Common>} />
  <Route path="/improper-login" element={<Common><ImproperLogin /></Common>} />
  <Route path="/improper-userinfo" element={<Common><ImproperUserInfo /></Common>} />
  <Route path="/improper-userinfo-xpath" element={<Common><ImproperUserInfoXpath /></Common>} />
  <Route path="/jvblogin" element={<Common><JvbLogin /></Common>} />
  <Route path="/jvbuserinfo" element={<Common><JvbUserInfo /></Common>} />
  <Route path="/jvbuserinfoxpath" element={<Common><JvbUserInfoXpath /></Common>} />
  <Route path="/method-user-info" element={<Common><MethodUserInfo /></Common>} />
 <Route path="/method-user-info-xpath" element={<Common><MethodUserInfoXpath /></Common>} />
  <Route path="/improper-register" element={<Common><ImproperRegister /></Common>} />


  <Route path="/via-rest-user-account" element={<Common><ViaRestUserAccountManagement /></Common>} />

  <Route path="/cookies-as-Tokens" element={<Common><CookiesasTokens /></Common>} />

  <Route path="rfi" element={<Common><RFI /></Common>} />
  <Route path="lfi" element={<Common><LFI /></Common>} />
  <Route path="via-cookies" element={<Common><ViaCookies /></Common>} />
  <Route path="/login" element={<Common><Login /></Common>} />
  <Route path="/edituserprofile" element={<Common><EditUserProfile /></Common>} />
  <Route path="/phpinfopage" element={<Common><PHPInfoPage /></Common>} />
  <Route path="/xmlvalidator" element={<Common><XMLValidator /></Common>} />
  <Route
    path="/CurrentUserInformation"
    element={<Common><CurrentUserInformation /></Common>}
  />
  <Route path="/echo-cors" element={<Common><EchoCORS /></Common>} />
  <Route path="/registerusers" element={<Common><RegisterUsers /></Common>} />
  <Route path="/view-someone-blog" element={<Common><ViewSomeonesBlog /></Common>} />
  <Route path="/directory-browser" element={<Common><DirectoryBrowsing /></Common>} />
  <Route path="/unrestricted" element={<Common><UnrestrictedFileUpload /></Common>} />
  <Route
    path="/user-agent-impresion"
    element={<Common><UserAgentImpersonation /></Common>}
  />
  <Route path="/poll-question" element={<Common><PollQuestion /></Common>} />
  <Route path="/userinfo" element={<Common><UserInfo /></Common>} />
  <Route path="/add-blog" element={<Common><AddBlog /></Common>} />
  <Route path="/sqlmap-target" element={<Common><SqlmapTarget /></Common>} />
  <Route path="/ViewBlog" element={<Common><ViewBlog /></Common>} />

{/* webservices*/}

  <Route
    path="/user-account-management"
    element={<Common><UserAccountManagement /></Common>}
  />
  <Route path="/createusers" element={<Common><CreateUsers /></Common>} />
  <Route path="/deleteusers" element={<Common><DeleteUsers /></Common>} />
  <Route path="/getuserspage" element={<Common><GetUsersPage /></Common>} />
  <Route path="/lookupuserpage" element={<Common><LookupUserPage /></Common>} />
  <Route path="/updateuserpage" element={<Common><UpdateUserPage /></Common>} />
  <Route path="/hellopage" element={<Common><HelloPage /></Common>} />
  <Route path="/helloworld" element={<Common><HelloWorld /></Common>} />
  <Route path="/lookupuserpage" element={<Common><LookupUserPage /></Common>} />


{/* 2007*/}


  <Route path="/pentesttoollookup" element={<Common><PenTestToolLookup /></Common>} />
  <Route path="/register" element={<Common><Register /></Common>} />
  <Route path="/catch-control" element={<Common><CatcheControl /></Common>} />
  <Route path="/client-side-comments" element={<Common><ClientsideComments /></Common>} />

  <Route path="/facebook" element={<Common><Facebook /></Common>} />




</Routes>


      
      
    </BrowserRouter>
  );
}

export default App;
