import { TextField } from "@mui/material";
import React from "react";

const XmlValidation = () => {
  return (
    <div>
      <div className=" border border-1 border-black title rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">
          Please Enter XML to Validate
        </p>
      </div>

      <div>
        <p>
          Example:
          {`<somexml>
            <message>Hello World</message>
          </somexml>`}
        </p>
      </div>

      <div className="mx-auto text-center ">
        <b className="my-auto">XML</b>
        <textarea
          className="my-auto"
          name="xml"
          rows="8"
          cols="50"
          id="idXMLTextArea"
          title="Please enter XML to validate"
          autofocus="autofocus"
        ></textarea>
      </div>

      <div className=" text-center mx-auto mt-5 w-100">
        <div className="mx-auto text-center btn btn-secondary btn">Validate XML</div>
      </div>
    </div>
  );
};

export default XmlValidation;
