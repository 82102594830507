import React from 'react'
import AddCircleIcon from '@mui/icons-material/AddCircle';
const ViewBlog = () => {
  return (
    <div>
     <div className="container">
        <div className='col-lg-10'>
             <div className='relative mt-3 p-3'>
                   <div className='absolute bg-white'>
                       <h6 className='p-2'>Current Content Security Policy (CSP) Report To Endpoints</h6>
                   </div>
                   <div className='p-1'>
                        <p>Report-To: "group": "csp-endpoint", "max_age": 10886400, "endpoints":"url": "includes/capture-data.php"</p>
                       </div>
             </div>
        </div>
    </div>

    </div>
  )
}

export default ViewBlog