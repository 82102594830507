import React from 'react'
import SecurityIcon from '@mui/icons-material/Security';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
const EchoMessage = () => {
    return (
        <>
            <div className="container">
                <div className='col-lg-9 p-3'>
                    <div className='d-flex align-items-center'>
                        <div className='d-flex align-items-center me-5'>
                            <SecurityIcon className='fs-1 me-2' /><a href='#' className='nav-link text-success'> <b>Switch to Content Security Policy (CSP)</b> </a>
                        </div>
                        <div className='d-flex align-items-center'>
                            <ViewInArIcon className='fs-1 me-2' /><a href='#' className='nav-link text-success'>  <b>Switch to Cross-Origin Resouce Sharing</b> </a>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 mx-auto'>
                    <div className='text-center bg-secondary p-1 rounded'>
                        <h5 className='text-white'>Enter message to echo</h5>
                    </div>
                    <div className='d-flex mt-3'>
                        <div>
                            <h6>Message</h6>
                        </div>
                        <div className='ms-5'>
                            <input type='text' className='rounded' />
                            <div className='mt-2'>
                                <a href='#' className='btn btn-primary ms-5'>Lookup DNS</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default EchoMessage