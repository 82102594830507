import React from 'react'
import Login from '../../injection(sql)/Bypass-Authentication/Login'
import { useState } from 'react'
import axios from 'axios'
import Endpoint from '../../../../assets/Endpoint'
import SecurityHeaders from '../../../../assets/SecurityHeaders'
import styled from 'styled-components'

const ViaBruteForceLogin = () => {

  const [username, setUsername] = useState(null)
  const [password, setPassword] = useState(null)

  const HandleLogin = async () => {
    try {
      const data = new FormData()
      data.append('user_name', username);
      data.append('password', password);

      const res = await axios.post(Endpoint + "owasp-2017/A1/bypass-authentication-login.php", data, SecurityHeaders);

      if (res && res.data.status === "success") {
        if (res.data.user_id !== null && res.data.user_id !== "" && res.data.user_id !== undefined) {
          localStorage.setItem("user_id", JSON.stringify(res.data.user_id))
          window.location.reload()
        }
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
      // Handle the error appropriately, e.g., show a message to the user
    }
  }



  return (
    <>
      <Style>



        <div className="d-flex mt-4">
          <div className="d-flex align-items-center">
            <div className="col-2">
              <img
                className="w-100"
                src="http://localhost/images/ajax_logo-75-79.jpg"
                alt="ajax logo"
              />
            </div>
            <h5 className="text-primary ms-3">
              {" "}
              Switch to SOAP Web Service version{" "}
            </h5>{" "}
          </div>

          <div className="d-flex align-items-center ms-5">
            <div className="col-2">
              <img
                className="w-100"
                src="http://localhost/images/xml-logo-64-64.png"
                alt="ajax logo"
              />
            </div>
            <h5 className="text-primary ms-3"> Switch to XPath version</h5>{" "}
          </div>
        </div>

        <div className='mx-auto col-5'>
          <div className='border-dark border rounded  headbox '>

            <p className='text-center fw-semibold fs-5 p-0 m-0'>
              Please Sign-in</p>
          </div>

          <form onSubmit={(event) => { event.preventDefault(); HandleLogin() }}>
            <div className='mt-3 '>
              <div className='d-flex col-9 justify-content-between'>
                <p className='fw-semibold m-0 p-0 ms-5'>Username</p>
                <input onChange={(event) => setUsername(event.target.value)} className='border-2 h-25 rounded border-black me-5' type="text" required />

              </div>


              <div className='d-flex mt-3 col-9 justify-content-between'>
                <p className='fw-semibold m-0 p-0 ms-5'>Password</p>
                <input onChange={(event) => setPassword(event.target.value)} className='border-2 h-25 rounded border-black me-5' type="password" required />


              </div>


            </div>
            <div className='d-flex mt-4 justify-content-center'>

              <button className='btn col-4 ms-5 btn-primary h-25'>Login </button>
            </div>
          </form>
        </div>

        <div className='d-flex justify-content-center mt-3 fs-5'>
          <i>Dont have an account? </i>
          <i><a className='text-decoration-none' href="#">Please register here</a></i>

        </div>
      </Style>
    </>
  )
}

export default ViaBruteForceLogin







export const Style = styled.div`
  .buttonAD {
    background-color: #e8eae2 !important;
  }
  .headbox {
    background-color: #bc6c2550 !important;
  }


`;