import React, { useState } from 'react'
import { Col, Row, Toast, ToastContainer } from 'react-bootstrap'

const LoginToast = ({toast,message}) => {

    const [show,setShow] = useState(toast)
  return (
    <div>
      <Row>
      <Col xs={6}>
      <ToastContainer className='ms-5 mb-5' position='bottom-center'
      style={{ zIndex: 1 }}
      >
        <Toast bg='warning' onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">{message?message:'Login Field'}</strong>
           
          </Toast.Header>
          <Toast.Body><strong>{message?null:'Wrong credentials'}</strong></Toast.Body>
        </Toast>
        </ToastContainer>
      </Col>
     
    </Row>
    </div>
  )
}

export default LoginToast
