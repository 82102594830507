import React from 'react'
import styled from 'styled-components'
import Title from '../../../components/Title'

const PenTestToolLookup = () => {
  return (
    <StyledPenTestToolLoopup>
    <div>
        <Title title={"Pen Test Tool Lookup"}/>
     <div className='d-flex mt-3'>
        <img src='http://localhost/images/ajax_logo-75-79.jpg'></img>
        <h5 className='text-primary my-auto'>Switch to SOAP Web Service version</h5>
        </div>

         <div className='border border-1 border-dark m-5 p-2 col-4 mx-auto rounded-1 '>
          <h4>Pen Test Tools</h4>

          <div className='border border-1 border-dark mt-3 user text-center p-1 w-100 mx-auto rounded'>
           <h4>Select Pen Test Tool</h4>
          </div>

          <div className='mt-3 d-flex'>
          <h6>PenTestTool</h6>
          <div className='ms-5 mx-auto'>
          <select id="idToolSelect" name="ToolID" autofocus="autofocus">
						<option value="0923ac83-8b50-4eda-ad81-f1aac6168c5c" selected="selected">Please Choose Tool</option>
						<option value="c84326e4-7487-41d3-91fd-88280828c756">Show All</option>
						<option value="1">WebSecurify</option>
<option value="2">Grendel-Scan</option>
<option value="3">Skipfish</option>
<option value="4">w3af</option>
<option value="5">Burp-Suite</option>
<option value="6">Netsparker Community Edition</option>
<option value="7">NeXpose</option>
<option value="8">Hailstorm</option>
<option value="9">Tamper Data</option>
<option value="10">DirBuster</option>
<option value="11">SQL Inject Me</option>
<option value="12">XSS Me</option>
<option value="13">GreaseMonkey</option>
<option value="14">NSLookup</option>
<option value="15">Whois</option>
<option value="16">Dig</option>
<option value="17">Fierce Domain Scanner</option>
<option value="18">host</option>
<option value="19">zaproxy</option>
<option value="20">Google intitle</option>
					</select>
            <button className='rounded p-1 box mt-2 ms-5'>Lookup Tool</button>
          </div>

          </div>
          </div>

    </div>
    </StyledPenTestToolLoopup>
  )
}

export default PenTestToolLookup
const StyledPenTestToolLoopup= styled.div`
.user{
    background-color:#f2dfc3;
  }
  .box{
    background-color:#dddddd;
  }`
