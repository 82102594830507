import React from 'react'
import User from '../../../components/User';
const Robots = () => {
  return (
    <div className='container mt-3'>
        <User user={"Robots.txt"}/>
       <div className='col-lg-6 mx-auto mt-4'>
       <div className='name border border-black rounded-2 text-center'>
            <h5>
Robots.txt</h5>
        </div>

        <div className='mt-3'>
           <p>
           Sites use the robot.txt file in the site root to discourage web crawlers from indexing site content. Robots.txt is a plain text file which can be read by site visitors. In some cases, the robots.txt file will point to sensitive pages or directories. If a sensitive file is placed in robots.txt without proper authorization controls protecting the file, site visitors may discover the contents and browse to the files. More information is available at Robots Exclusion Standard
 
           </p>
        </div>
       </div>
    </div>
  )
}

export default Robots

