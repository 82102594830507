import React from 'react'

const SqlmapTarget = () => {
  return (
<div className='col-12 mt-3 container'>
<table>
  <tbody>
    <tr>
      <td className=" info-box rounded h4 text-center">SQLMap Practice Targets</td>
    </tr>
    <tr>
      <td />
    </tr>
    <tr>
      <td>
        Several pages in this training environment have SQL injection flaws
        added. A few listed below are the easiest on which to practice using{" "}
        <a className=' text-decoration-none' href="http://sqlmap.org/" target="_blank">
          sqlmap
        </a>
        ; an advanced, automated sql injection audit tool.
        <br />
        <ul style={{ fontWeight: "bold" }}>
          <li>
            <a className=' text-decoration-none'  href="./index.php?page=login.php">Login</a>
            <br />
          </li>
          <li>
            <a className=' text-decoration-none'  href="./index.php?page=view-someones-blog.php">
              View Someones Blog
            </a>
            <br />
          </li>
          <li>
            <a className=' text-decoration-none'  href="./index.php?page=user-info.php">User Info</a>
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td>
        A{" "}
        <a className=' text-decoration-none'  href="http://www.youtube.com/watch?v=vTB3Ze901pM" target="_blank">
          video using SQLMap
        </a>{" "}
        against the OWASP Mutillidae II login page is available on the
        webpwnized YouTube channel. Additional help is available on the bottom
        of this page when the "Hints" are enabled.
      </td>
    </tr>
  </tbody>
</table>
</div>

  )
}

export default SqlmapTarget