import React from 'react'

const Title = ({data}) => {
    return (
        <div>

            <div className=' bg-light border border-dark text-center rounded m-4'>
                <h4>{data.title}</h4>
            </div>

        </div>
    )
}

export default Title