import React from 'react'
import styled from 'styled-components';
import Title from '../../../Others/Title';

const PollQuestion = () => {
  return (
    
    <StyledPollQuestion>
        
        <Title data={{title:"Userpoll"}}/>  
      <div className='border border-2 w-100 border-dark mt-3  '>
        <h3 className=''>User Poll</h3>
        <div className=' box1 border col-6 rounded-3 border-2 mx-auto border-dark text-center  mt-5'>
    <h1>Choose your Favourite Security Tool</h1>
</div>
<h3 className='text-center mt-2'>initial your choose to make your vote count</h3>


<div className='col-6 mx-auto mt-4 d-flex'>
   <input type='radio'></input>
   <h5 className='ms-2'>nmap</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5 className='ms-2'>  wireshark</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5  className='ms-2'>tcpdump</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input  type='radio'></input>
   <h5  className='ms-2'>netcat</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5  className='ms-2'>metasploit</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5  className='ms-2'> kismet</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5  className='ms-2'>  Cain</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5  className='ms-2'>  Ettercap</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5  className='ms-2'>  Paros</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5  className='ms-2'>  Burp Suite</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5 className='ms-2'>  Sysinternals</h5>
</div>
<div className='col-6 mx-auto mt-2 d-flex'>
   <input type='radio'></input>
   <h5 className='ms-2'>inSIDDer</h5>
   
</div>
<div  className='col-6 mx-auto mt-2 d-flex '>
<h3>Your Initials:</h3>
<input className='col-4 rounded-3 border-3'></input>
</div>

<div className=' mx-auto text-center mt-3 col-4'>
    <button className='rounded-1'><h4>submit poll</h4></button>

</div>
<div className='nmap border border-2 col-6 border-dark rounded-2  mx-auto text-center mt-4 '>
    <h3>No choice selected </h3>

</div>




 </div>
 </StyledPollQuestion>

    
  )
}

export default PollQuestion
const StyledPollQuestion = styled.div`
.box1 {background-color:#F2DFC3 ; }
.nmap{ background-color:#E1E1E1;}
`




