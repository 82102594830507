import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import Title from "../../../components/Title";
import Register from "../../OWASP 2007/improper error handling/Register";

import { TextField } from "@mui/material";
import axios from "axios";
import Endpoint from "../../../assets/Endpoint";
import SecurityHeaders from "../../../assets/SecurityHeaders";
import UserDetails from "../../../components/UserDetails";
import LoginToast from "../../../components/LoginToast";

const HtmlInjectionRegister = () => {

  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [conPassword, setConfPassword] = useState('')
  const [signature, setSignature] = useState('')
  const [show, setShow] = useState(false)
  const [data, setData] = useState('')
  const [message, setMessage] = useState(null)


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== conPassword) {
      setShow(true)
      setMessage('Passwords do not match')
      console.error("Passwords do not match");
      return;
    }

    try {
      const data = new FormData();
      data.append('user_name', name);
      data.append('password', password);
      data.append('signature', signature);

      const response = await axios.post(
        Endpoint + "owasp-2017/A1/html-injection-register.php",
        data,
        SecurityHeaders
      );

      if (response.data.status === "success") {
        setData(response.data)
        // localStorage.setItem("user_id", JSON.stringify(response.data.user_id));
      } else {
        // Handle error here
        setShow(true)
        setMessage('Registration failed')

        console.error("Registration failed:", response.data.error);
      }
    } catch (error) {
      console.error("An error occurred during registration:", error);
      // Handle other kinds of errors (e.g., network errors)
    }
  };

  useEffect(() => {
    if (show) {
      const time = setTimeout(() => {
        setMessage('')
        setShow(false)
      }, 2000)
      return () => clearTimeout(time)
    }

  }, [show]);



  return (
    <>
      <div className='container'>
        <div className='col-lg-6'>
          <div className='d-flex align-items-center'>
            <div className='ajax'>
              <img className='w-100' src='https://scottiestech.info/wp-content/uploads/2009/12/form_ajax-150x150.jpg' />
            </div>
            <div>
              <h5 className='text-primary ms-3'>Switch to RESTful Web Service Version of this Page</h5>
            </div>
          </div>
        </div>
        <div className='col-lg-6 mx-auto'>
          <h5 className='bg-warning rounded text-center p-1'>Please choose your username, password and signature</h5>
          <form onSubmit={handleSubmit} className='mt-5'>
            <TextField className='w-100'
              id="outlined-multiline-static"
              label="User Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              className='w-100 mt-3'
              id="outlined-multiline-static"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              className='w-100 mt-3'
              id="outlined-multiline-static"
              label="Confirm Password"
              value={conPassword}
              onChange={(e) => setConfPassword(e.target.value)}
            />
            <TextField className='w-100 mt-3'
              id="outlined-multiline-static"
              label="Signature"
              multiline
              rows={4}
              value={signature}
              onChange={(e) => setSignature(e.target.value)}
            />

            <div className='text-center mt-3'>
              <button className='btn btn-primary w-50'>Create Account</button>
            </div>
          </form>
        </div>

        <div className="col-12 mt-3 mx-auto">


          {data.data ?
            <UserDetails data={data} />
            : null}
          <>
            {
              message ? <LoginToast message={message} toast={show} /> : null
            }</>

        </div>
      </div>
    </>
  )


};

export default HtmlInjectionRegister;

const StyledRegister = styled.div`
  .input {
    padding-left: 50px;
  }
  .box {
    background-color: #dddddd;
  }
  .user {
    background-color: #f2dfc3;
  }
  .error {
    background-color: #fbeaea;
  }
`;
