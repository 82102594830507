import React from "react";

const Mutillidae = () => {
  return (
    <div>
      <div className=" border border-1 border-black title rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">I've been framed!</p>
      </div>
      <p>I've been framed by /mutillidae-main/styling.php</p>
    </div>
  );
};

export default Mutillidae;
