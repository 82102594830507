import React from 'react'
import styled from 'styled-components';
import { TitleOutlined } from '@mui/icons-material';
import Title from '../../../components/Title';
const UserAgentImpersonation = () => {
  return (
    <StyledUserAgentImpersonation>
        <Title title={"User-Agent Impersonation"}/>
    <div>

      <div className='col-10 mx-auto mt-5'>
        <div className='position p-3 mt-3'>
         <div className='select bg-white'>
            <h3>Browser Fingerprint</h3>
            </div>

            <div className='mt-3 p-5'>
            <table class="table table-bordered border-dark">
    <thead>
      <tr>
        <th colSpan="2" className='p-4 text-center bg-warning'><h4>Sorry. You do not look like an Apple iPad using Safari Browser.
This page uses JavaScript browser .<br/> and O/S fingerprinting to decide if the user-agent is allowed. </h4></th>
      </tr>
      <tr>
        <th colSpan="2" className='p-4'></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td> <h4><b>User Agent</b></h4></td>
        <td className='fs-4'>Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36</td>
        
      </tr>      
      <tr>
        <td><h4><b>App Code Name</b></h4>	</td>
        <td className='fs-4'>Mozilla</td>
        
      </tr>
      <tr>
        <td><h4><b>App Name</b></h4></td>
        <td className='fs-4'>Netscape</td>
       
      </tr>
      <tr>
        <td><h4><b>Browser Version</b></h4></td>
        <td className='fs-4'>5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36</td>
       
      </tr>
      <tr>
        <td> <h4><b>Platform</b></h4></td>
        <td className='fs-4'>Win32</td>
        
      </tr>
      <tr>
        <td><h4><b>Vendor</b></h4></td>
        <td className='fs-4'>Google Inc.</td>
        
      </tr>
      <tr>
        <td><h4><b>Vendor Sub</b></h4></td>
        <td className='fs-4'></td>
      
      </tr>
      <tr>
        <td><h4><b>Build ID</b></h4></td>
        <td className='fs-4'>undefined</td>
        
      </tr>
      <tr>
        <td><h4><b>O/S CPU</b></h4></td>
        <td className='fs-4'>undefined</td>
       
      </tr>
      <tr>
        <td><h4><b>Product</b></h4></td>
        <td className='fs-4'>	Gecko</td>
       
      </tr>

      <tr>
        <td><h4><b>Product Sub</b></h4></td>
        <td className='fs-4'>20030107</td>
       
      </tr>
    </tbody>
  </table>  
                
                
     </div>   
        </div>
         
      </div>
    </div>
    </StyledUserAgentImpersonation>
  )
}

export default UserAgentImpersonation
const StyledUserAgentImpersonation = styled.div`
.position{
    position:relative;
    border:2px solid black;
    border-radius:5px;

}

.select{
    position:absolute;
    top:-16px
}

.box{
    background-color:#E1E1E1;}
`
