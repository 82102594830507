import React from 'react'

const PenTestToolLookupAjax = () => {
    return (
        <>
            <div className="container">
                <div className="col-lg-9">
                    <div className='d-flex align-items-center mt-3'>
                        <div className='ajax'>
                            <img className='w-100' src='https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSArz5d27GVUnVCMIjQaCx0TLwAt6njArSDEob6M9wX8j19_VFv' />
                        </div>
                        <div>
                            <a href='#' className='text-primary nav-link fw-bold ms-4'>Switch to POST Version of page</a>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 mx-auto'>
                    <div className='relative p-3 mt-3'>
                        <div className='absolute bg-white'>
                            <h6 className='p-2'>Pen Test Tools</h6>
                        </div>
                        <div className='bg-aqua p-1 rounded text-center mt-3'>
                            <h6 className='fw-bold'>Select Pen Test Tool</h6>
                        </div>
                        <div className='d-flex mt-4'>
                            <div className='ms-5 me-2'>
                                <h6>Pen Test Tool</h6>
                            </div>
                            <div>
                                <select>
                                    <option>please choose tool</option>
                                    <option>please choose tool</option>
                                    <option>please choose tool</option>
                                    <option>please choose tool</option>
                                    <option>please choose tool</option>
                                    <option>please choose tool</option>
                                    <option>please choose tool</option>
                                    <option>please choose tool</option>
                                </select>
                            </div>
                        </div>
                             <div className='text-center mt-4'>
                                <a href='#' className='btn btn-warning rounded w-50'>Lookup Tool</a>
                             </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PenTestToolLookupAjax