import React from 'react'
import styled from 'styled-components'
import Title from '../../../components/Title';

const Lab1 = () => {
  return (
    <div>

<Title title={"Lab 1: Sending HTTP Requests with Netcat?"} />


      <StyledLab1>
        <div className='box1 border border-dark col-5 mx-auto rounded'>
          <h5 className='text-center'>What version of web server is running according to the </h5>
          <h5 className='text-center'>Server response header?</h5>
        </div>
        <div className='col-5 mx-auto m-3'>
          <div className=' d-flex'>

         <input type="radio"></input>

            <h6 className='p-0 m-0'>Apache 2.x</h6>
          </div>
          <div className=' d-flex p-0 m-0'>
          <input type="radio"></input>

            <h6 className='p-0 m-0'>NGinX 1.x</h6>
          </div>
          <div className=' d-flex p-0 m-0'>
          <input type="radio"></input>

            <h6 className='p-0 m-0'> IIS 8.5</h6>
          </div>
          <div className=' d-flex p-0 m-0'>
          <input type="radio"></input>

            <h6 className='p-0 m-0'> Apache Lite 1.x</h6>
          </div>
          <div className=' d-flex p-0 m-0'>
          <input type="radio"></input>
            <h6 className='p-0 m-0'> None of the Above
            </h6>
          </div>
        </div>
        <div className=' submit col-1 mx-auto '>
          <button className='rounded pe-3 ps-3 m-2'>
            Submit
          </button>
        </div>
        <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
          <h6> Choose the best answer or view Hints and Videos</h6>
        </div>
        <div className='d-flex justify-content-center'>
          <a className='  text-decoration-none fs-5 p-2 '>
            Next Lab
          </a>
        </div>
      </StyledLab1>
    </div>
  )
}

export default Lab1

const StyledLab1 = styled.div
  `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`