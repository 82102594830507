import React from 'react'
import { Link } from 'react-router-dom'

const Reset = () => {
    return (
        <>
            <div className='container p-3'>
                <div>
                    <h6>Developed by <Link>Jeremy "webpwnized" Druin.</Link> Based on Mutillidae 1.0 from Adrian <Link>"Irongeek"</Link> Crenshaw.</h6>
                </div>
                <div>
                    <Link className='nav-link text-primary fw-bold'>OWASP</Link>
                    <Link className='nav-link text-primary fw-bold'>ISSA Kentuckiana</Link>
                    <Link className='nav-link text-primary fw-bold'>OWASP Louisville</Link>
                    <Link className='nav-link text-primary fw-bold'>Helpful Firefox Add-Ons</Link>
                </div>
            </div>
        </>
    )
}

export default Reset