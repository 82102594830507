import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Edit from '../toolslist/Edit';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom/dist'
import { useParams } from 'react-router-dom/dist'
import learn from '../../assets/images/learn-with-academy.svg'
import kali from '../../assets/images/kali-linux.webp'
import Endpoint from '../../assets/Endpoint';
import { Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ctfimage from '../../assets/images/ctf.png'
import scenario from '../../assets/images/scenario.png'
const Home = () => {

    const [price, setPrice] = useState([])

    const [tools, settools] = useState([]);

    const [edit, setedit] = useState(false);

    const GetPlans = async () => {
        const data = new FormData()
        const response = await axios.post("https://globalhackersunited.com/get-plans.php", data, { headers: { 'content-type': 'multipart/form-data' } })
        if (response) {
            setPrice(response.data.data);
        }
    }


    const deletedata = async (id) => {
        const data = new FormData()
        data.append('id', id);

        const response = await axios.post("https://globalhackersunited.com/deletedata.php", data, { headers: { 'content-type': 'multipart/form-data' } })
        if (response) {

            if (response.data.status === "success") {
                console.log(response.data)
               // GetPlans()
            }
        }
    }






    const toolslist = async () => {

        const data = new FormData()

        const res = await axios.post(Endpoint + "get-tools.php", data, { headers: { 'content-type': 'multipart/form-data' } });

        if (res) {
            settools(res.data.data);
        }
    }




    const lines = [
        "This is the first line of the paragraph.",
        "Here is the second line of the text.",
        "Now, we see the third line appearing.",
        "The fourth line comes after this one.",
        "Next, it's time for the fifth line.",
        "Almost there with the sixth line.",
        "Finally, this is the seventh line."
    ];



    useEffect(() => {

        toolslist()

      //  GetPlans()

    }, [])
    const [descripction, setDescription] = useState([])
    console.log(descripction)


    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';

        }
        return text;
    };
    const [showDescriptions, setShowDescriptions] = useState(
        new Array(tools.length).fill(false)
      );
    
      const toggleDescription = (index) => {
     
        const updatedShowDescriptions = [...showDescriptions];
        updatedShowDescriptions[index] = !updatedShowDescriptions[index];
        setShowDescriptions(updatedShowDescriptions);
     
      };





    return (
        <>
            <div class="position-relative overflow-hidden   text-center bg-body-tertiary">

                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item d-flex align-items-center pb-5 active home-hero min-vh-90">

                            <div class="container-fluid   min-vh-80 d-flex justify-content-center align-items-end">
                                <div class="col-md-7 p-lg-5 h-auto mx-auto my-5 ">

                                    <h2 class="display-4 fw-bold text-shadow text-light">Skills Uprise Hacking Lab</h2>
                                    <h4 class="fw-normal  mb-3 text-light text-shadow">982 Virtual Hacking Labs to Master Hacking</h4>

                                    <div class="mt-4 justify-content-evenly mx-auto d-flex">
                                        <button class="btn btn-theme-success btn-lg px-4 rounded ">Get started now</button>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>


                <div class="position-absolute professionals-scroll text-light">
                    <div class="text-left">
                        <h4>Connect with Professionals</h4>
                        <h4>From 117 Countries &amp; 200+ Companies</h4>
                    </div>
                </div>


            </div>



            <div className="container py-5 mt-5">


                <div className="">
                    <div className="d-flex  p-3">
                        <div className="col-lg-6">
                            <h1>From Zero to Hero</h1>
                            <h3>Learn from basics to advanced Hacking</h3>

                        </div>
                        <div className="col-lg-4">


                        </div>

                    </div>


                    <div className="container d-flex flex-wrap">

                        <div className="col-lg-4 col-md-4 col-12 p-2">


                            <div className="bg-theme-blue theme-border text-light rounded p-3">
                                <h4>Hacking | Bug Bounty | CTF</h4>

                                <p>Become a pro-hacker. Learn everything about hacking 100% practically. </p>
                                <img src={ctfimage} className='w-100'></img>
                            </div>

                        </div>


                        <div className="col-lg-4 col-md-4 col-12 p-2">


                            <div className="bg-theme-blue theme-border text-light rounded p-3">
                                <h4>Real world hacking scenarios</h4>

                                <p>Cutting edge labs and tools to master hacking from basics to advanced levels </p>
                                <img src={scenario} className='w-100'></img>
                            </div>

                        </div>



                        <div className="col-lg-4 col-md-4 col-12 p-2">


                            <div className="bg-theme-blue theme-border text-light rounded p-3">
                                <h4>Master Hackers Machine with Industry Experts Guidance </h4>

                                <p>Cutting edge labs and tools to master hacking  </p>
                                <img src={kali} className='w-100'></img>
                            </div>

                        </div>

                    </div>
                </div>
            </div>




            <div className="container d-none">


                <div className="text-center">

                    <h1>587 Hackable Machines</h1>
                    <h1>Learn to Hack Whatever It is</h1>

                </div>
                <div className="d-flex flex-wrap container justify-content-center">


                    <div className="col-lg-3  p-2">

                        <div className="bg-dark rounded">
                            <div className="machine-cover">

                            </div>
                            <div className="p-3">
                                <h4>Website Hacking</h4>
                                <p>Learn the necessary skills to start a career as a penetration tester</p>
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3  p-2">

                        <div className="bg-dark rounded">
                            <div className="machine-cover">

                            </div>
                            <div className="p-3">
                                <h4>Website Hacking</h4>
                                <p>Learn the necessary skills to start a career as a penetration tester</p>
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3  p-2">

                        <div className="bg-dark rounded">
                            <div className="machine-cover">

                            </div>
                            <div className="p-3">
                                <h4>Website Hacking</h4>
                                <p>Learn the necessary skills to start a career as a penetration tester</p>
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3  p-2">

                        <div className="bg-dark rounded">
                            <div className="machine-cover">

                            </div>
                            <div className="p-3">
                                <h4>Website Hacking</h4>
                                <p>Learn the necessary skills to start a career as a penetration tester</p>
                            </div>

                        </div>


                    </div>

                    <div className="col-lg-3  p-2">

                        <div className="bg-dark rounded">
                            <div className="machine-cover">

                            </div>
                            <div className="p-3">
                                <h4>Website Hacking</h4>
                                <p>Learn the necessary skills to start a career as a penetration tester</p>
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3  p-2">

                        <div className="bg-dark rounded">
                            <div className="machine-cover">

                            </div>
                            <div className="p-3">
                                <h4>Website Hacking</h4>
                                <p>Learn the necessary skills to start a career as a penetration tester</p>
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3  p-2">

                        <div className="bg-dark rounded">
                            <div className="machine-cover">

                            </div>
                            <div className="p-3">
                                <h4>Website Hacking</h4>
                                <p>Learn the necessary skills to start a career as a penetration tester</p>
                            </div>

                        </div>


                    </div>
                    <div className="col-lg-3  p-2">

                        <div className="bg-dark rounded">
                            <div className="machine-cover">

                            </div>
                            <div className="p-3">
                                <h4>Website Hacking</h4>
                                <p>Learn the necessary skills to start a career as a penetration tester</p>
                            </div>

                        </div>


                    </div>

                </div>



                <div className="text-center mt-5">
                    <button className='btn btn-primary rounded rounded-pill'>View All Machines</button>
                </div>


            </div>





          

            <div className='p-5'>



                <div class=" p-3 pb-md-4 mx-auto text-center">
                    <h1 class="display-4 fw-normal"><b>600+ Hacking Tools</b></h1>

                    <h4><b>Learn Tools for every hacking operation</b></h4>
                </div>


                <div class="album py-5 container ">
                    <div class="p-2">

                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-3">

                   {tools.map((item, index) => (
        <div key={index} className="col-lg-3 col-sm-6 col-md-4">
          <Card
            className="col-12 bg-dark text-white"
            style={{ margin: '1rem auto', textAlign: 'center',border: '1px solid #6e6e6e' }}
          >
            <Card.Img variant="top" src={item.img_url} />
            <Card.Body>
              <Card.Title>{item.title}</Card.Title>
             
              {/* <div className={`description ${showDescriptions[index] ? 'show' : ''}`}> */}
              <div>
                <div className="text-left">
                {truncateText(item.description, 100)}
                </div>
              </div>
            <div className="mt-4">
            <Link className=" w-100 " to={`/ToolsDetails/${item.id}`}>
       <button className='btn btn-primary w-100'>View Details</button>
              </Link>
            </div>
            </Card.Body>
          </Card>
        </div>
      ))}
                        </div>


                    </div>
                </div>


                {/* jumbotron */}


                <div class="container py-4 d-none">




                    <div class="row align-items-md-stretch">
                        <div class="col-md-6">
                            <div class="h-100 p-5 text-white text-start bg-dark rounded-3">
                                <h2>Change the background</h2>
                                <p>Swap the background-color utility and add a `.text-*` color utility to mix up the jumbotron look. Then, mix and match with additional component themes and more.</p>
                                <button class="btn btn-outline-light" type="button">Example button</button>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="h-100 p-5 bg-light border text-start rounded-3">
                                <h2>Add borders</h2>
                                <p>Or, keep it light and add a border for some added definition to the boundaries of your content. Be sure to look under the hood at the source HTML here as we've adjusted the alignment and sizing of both column's content for equal-height.</p>
                                <button class="btn btn-outline-secondary" type="button">Example button</button>
                            </div>
                        </div>
                    </div>


                </div>


                {/* pricing */}
                {price ? <>
                    <div className='text-center d-none mt-3'>
                        <h1 className='fw-bold display-5'>Pricing Plans</h1>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between text-center p-5">
                        {price.map((item) => (
                            <div class="col-lg-4 col-md-12 col-sm-12 col-12 p-3">
                                <div class="card mb-4 rounded-3 shadow-sm">
                                    <div class={item.recommended === "0" ? "card-header bg-primary text-white py-3" : "card-header py-3"}>
                                        <h4 class="my-0 fw-normal">{item.title}</h4>
                                        <p>{item.description}</p>
                                    </div>
                                    <div class="card-body ">
                                        <h1 class="card-title pricing-card-title">{item.monthly_price}/<small class="text-muted fw-light">{item.yearly_price}</small></h1>
                                        {JSON.parse(item.features).map((data) => (
                                            <>
                                                <p>{data}</p>
                                            </>
                                        ))}
                                        <button type="button" class={item.recommended === "0" ? "w-100 btn btn-lg btn-primary" : "w-100 btn btn-lg btn-outline-primary"}>Sign up for free</button>
                                        <Link to={"/priceEdit/" + item.id} type='button' className='btn btn-warning w-50 mt-3'>Edit</Link ><br />
                                        <button type='button' className='btn btn-warning w-50 mt-3' onClick={() => { deletedata(item.id) }} >Remove</button >

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </> :
                    <>
                    </>}
            </div>



            <div className="border container rounded p-4 mb-5 mt-0">

<div className="d-flex justify-content-between">
    <div className="col-lg-6">
        <h2>Cloud training</h2>
        <p>Get hands-on experience with Amazon Web Service. Experience first-hand how attackers target and exploit various services within AWS and what mitigations can be implemented to prevent this from happening.</p>
    </div>
    <div className="col-lg-4">
        <img className='w-100' src={learn}></img>
    </div>
</div>
</div>


<div className="mb-5 pb-5">

</div>

        </>
    )
}

export default Home