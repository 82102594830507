import React from 'react';
import EditNoteIcon from '@mui/icons-material/EditNote';
import LanguageIcon from '@mui/icons-material/Language';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './about.css'

const About = () => {
  return (

    <div>

    <div className="margin-top">
        <div className='center'>
            <div className='hacker'>
                <h1 className='text-center'>Your Cyber Security Is<br /> In Your Hand Now </h1>
            </div>
            <div className="text-center mt-4 text-white p-3">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing eleifend dictum potenti mattis
                    viverra.<br />Eget quam lacus enim porttitor bibendum elit, dui nisl. Aenean turpis ut pretium et.</p>
            </div>
            <div class=" text-center mt-4  ">
                <button class="btn btn-danger  mt-2 p-2 ">Reqest Free Access</button>
                <button class="btn btn-transperant mt-2 border p-2 ms-5 text-white">Get Start Now</button>
            </div>
        </div>
    </div>



    <div className='text-center fw-bold p-1'>
        <h1 className=''>Our Approach Is Simple But Not Time-Wasting</h1>
    </div>



    <div className=" container p-1  d-flex flex-wrap mt-5 ">
        <div style={{color:'#212529'}} className="col-lg-4 ">
            <div className="col-lg-10 col-sm-12  mb-3 p-2 ">
                <div className="bg-light p-3">
                    <h4><b>Structural Weaknesses</b></h4>

                    <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing eleifend
                        dictum potenti
                        mattis viverra</p>
                </div>

            </div>

            <div className="col-lg-10 col-sm-12  mb-3 ">
                <div className="bg-light p-3">
                    <h4><b>Structural Weaknesses</b></h4>

                    <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing eleifend
                        dictum potenti
                        mattis viverra</p>
                </div>

            </div>

            <div className="col-lg-10 col-sm-12  p-1 ">
                <div className="bg-light p-3">
                    <h4><b>Structural Weaknesses</b></h4>

                    <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing eleifend
                        dictum potenti
                        mattis viverra</p>
                </div>

            </div>

        </div>
        <div class="col-lg-4 pb-2 ">
            <img class="w-100 "
                src="https://themes.envytheme.com/cyarb/wp-content/uploads/2022/01/approach-1.jpg" />
        </div>
        <div class=" col-lg-4 p-2">
            <div className='w-50 d-sm-none'>
                <img className='w-100'
                    src="https://themes.envytheme.com/cyarb/wp-content/uploads/2022/01/approach-shape.png" />
            </div>
            <div >
                <h2 class="text-danger">Evolving Threat</h2>
            </div>
            <div>
                <p>Lorem ipsum dolor sit amet, consecte adipi scing elit. Adipiscing eleifend dictum potentirder
                    mattis viverra.</p>
            </div>
            <button class="btn btn-transparent text-danger">learn more →</button>
        </div>
    </div>




    <div className='bg-danger p-4 mt-5'>
        <div className='container d-flex flex-wrap justify-content-between p-5 text-white'>
            <div className='col-lg-3 col-md-6 col-sm-6 d-flex align-items-center'>
                <EditNoteIcon sx={{ fontSize: 80 }} />
                <div className='font ms-2'>
                    <h1 className="ms-3 fw-bold ">3 K+</h1>
                    <p className='fw-bold'> Cyber Security Projects</p>
                </div>

            </div>


            <div className='col-lg-3 col-md-6 col-sm-6 d-flex align-items-center'>
                <LanguageIcon sx={{ fontSize: 60 }} />
                <div className='font ms-2'>
                    <h1 className="ms-3 fw-bold ">1,575</h1>
                    <p className='fw-bold'> Customers Globally</p>
                </div>

            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 d-flex align-items-center'>
                <EditNoteIcon sx={{ fontSize: 70 }} />
                <div className='font ms-2'>
                    <h1 className="ms-3 fw-bold "> 250 +</h1>
                    <p className='fw-bold'> Cyber Security Experts</p>
                </div>

            </div>
            <div className='col-lg-3 col-md-6 col-sm-6 d-flex align-items-center'>
                <PermIdentityIcon sx={{ fontSize: 70 }} />
                <div className='font ms-2'>
                    <h1 className="ms-3 fw-bold ">150%</h1>
                    <p className='fw-bold'> Client Retention Rate</p>
                </div>

            </div>

        </div>

    </div>


    <div style={{color:'#212529'}} className=' bg-light mt-5 p-5'>

        <div className='container p-3'>

            <div className='d-flex flex-wrap justify-content-between'>
                <div>
                    <h1 className='fw-bold'>Future-proof Security &<br /> Infrastructure</h1>
                </div>
                <div className='mt-3'>
                    <button className='btn btn-danger px-4 py-3 '>view all</button>
                </div>
            </div>
            <div className=' d-flex justify-content-evenly flex-wrap mt-5 '>
                <div className='col-lg-5 col-md-12 col-sm-12  '>
                    <div className='bg-lighgt shadow p-4'>
                        <div className='d-flex '>
                            <div className='mt-2'>
                                <MonitorWeightIcon sx={{ fontSize: 80 }} />
                            </div>
                            <div className='ms-3'>
                                <h4 className='fw-bold'> Industry Certified</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna</p>
                            </div>
                        </div>

                    </div>

                </div>
                <div className='col-lg-5 col-md-12 col-sm-12 '>
                    <div className='bg-lighgt shadow p-4'>
                        <div className='d-flex '>
                            <div className='mt-2'>
                                <MonitorWeightIcon sx={{ fontSize: 80 }} />
                            </div>
                            <div className='ms-3'>
                                <h4 className='fw-bold'> Industry Certified</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna</p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


            <div className='d-flex flex-wrap justify-content-evenly mt-3'>
                <div className='col-lg-5 col-md-12 col-sm-12'>
                    <div className='bg-lighgt shadow p-4'>
                        <div className='d-flex '>
                            <div className='mt-2'>
                                <MonitorWeightIcon sx={{ fontSize: 80 }} />
                            </div>
                            <div className='ms-3'>
                                <h4 className='fw-bold'> Industry Certified</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna</p>
                            </div>
                        </div>

                    </div>

                </div>
                <div className='col-lg-5 col-md-12 col-sm-12'>
                    <div className='bg-lighgt shadow p-4'>
                        <div className='d-flex'>
                            <div className='mt-2'>
                                <MonitorWeightIcon sx={{ fontSize: 80 }} />
                            </div>
                            <div className='ms-3'>
                                <h4 className='fw-bold'> Industry Certified</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna</p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>



    <div className=' container '>
        <div className='d-flex flex-wrap p-2'>
            <div className='col-lg-5 p-2'>
                <img className='w-100 rounded rounded-3 ' height={500} width={400} src='https://www.ediiie.com/blog/assets/admin/uploads/Virtual-Reality-Gaming.jpg'></img>
            </div>
            <div className='col-lg-6 p-2 mt-5  ms-3 '>
                <div>
                    <h2 className='fw-bold'>24/7 Cybersecurity Operation Center</h2>

                    <div className='mt-3'>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod <br />tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div className=' col-lg-12  col-md-6 col-sm-12    '>
                    <div className=' d-flex flex-wrap mt-4'>
                    <div>
                
                    
                         <p ><CheckCircleIcon/>Malware Detection Removal</p>
                        <p> <CheckCircleIcon />Content Delivery Network</p>
                        <p> <CheckCircleIcon />Security Management</p>
                        <p> <CheckCircleIcon />SIEM Threat Detection</p>
                        <p> <CheckCircleIcon />Website Hack Repair </p>



            
                    </div>

                    <div className='ms-5'>
                        
                            <p> <CheckCircleIcon />Malware Detection Removal</p>
                            <p> <CheckCircleIcon />Content Delivery Network</p>
                            <p> <CheckCircleIcon />Security Management</p>
                            <p> <CheckCircleIcon />SIEM Threat Detection</p>
                            <p> <CheckCircleIcon />Website Hack Repair </p>
                             
                        </div>
                    </div>
                    </div>
                    
            </div>
        </div>




    </div>
</div>

  
  )
}

export default About