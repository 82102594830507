import React from 'react'
import styled from 'styled-components';
import Title from '../../../components/Title';
const ClientsideComments = () => {
  return (
<StyledClientsideComments>
    <div>
    <Title title={ " Client Side Comments "}/>
<div className=' box1 border col-6 rounded-3 border-2 mx-auto border-dark text-center  mt-5'>
    <h1>Client-side Comments</h1>
</div>

<div className='col-6 mt-4 mx-auto fs-3'>
    <p>Most pages have comments that are inapproriate to be shared on the client-side. The comments are included by the main frame in which pages appear, so almost
    <br/>any page will have the comments.</p>
    <p className='mt-3'>You may want to try to "View Source" of this page and see if database credentials
    might be present.</p>
    <p className='mt-3 mx-auto'>Click<b> "Hints and Videos".</b> Open the hint on <b>"Client-side Comments".</b> There are
    videos at the bottom that show different techniques that may be useful.</p>
</div>
<div className='last text-center mt-5 '>
  <h5>Browser: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebkit?537.36 (KHTML, like Gecko)chrome?122.0.0.0 safari?537.36
  <h5> PHP Version: 7.4.26</h5>  
  </h5>


</div>
</div>

    </StyledClientsideComments>
  )
}

export default ClientsideComments
const StyledClientsideComments = styled.div`
.last{background-color:#F2F2F2; }
.box1 {background-color:#F2DFC3 ;}
..box{
  background-color:#E1E1E1;   }
`
