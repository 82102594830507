import React from "react";
import styled from "styled-components";
import Title from "../../../components/Title";
const CacheControl = () => {
  return (
    <>
      <StyledCacheControl>
        <div className="col-lg-8 mt-5">
          <div className="d-flex align-items-center justify-content-end">
            <div>
              <img src="http://localhost/images/arrow-45-degree-left-up.png" />
            </div>
            <div>
              <h5 className="ms-4">
                TIP:Click
                <i className="text-primary"> Hint and Videos </i>
                <br />
                <b>on each page</b>
              </h5>
            </div>
          </div>
        </div>

        <div className="d-flex pt-5 mt-2 ms-4">
          <img src="http://localhost/images/question-mark-40-61.png"></img>
          <h3 className="text-primary">What Should I Do ?</h3>
          <img
            className=" image ms-5"
            src="http://localhost/images/help-easy-button-48-48.png"
          ></img>
          <h3 className="">Help Me !</h3>
        </div>
        <div className="d-flex pt-5 ms-4">
          <img src="http://localhost/images/siren-48-48.png"></img>
          <h3 className="text-primary">Listening of Vulnerabiities</h3>
          <img
            className="ms-4"
            src="http://localhost/images/youtube-play-icon-40-40.png"
          ></img>
          <h3 className="text-primary  ">Video Tutorials</h3>
        </div>

        <div className="d-flex pt-5 ms-4">
          <img src="http://localhost/images/twitter-bird-48-48.png"></img>
          <h3 className="text-primary">Release Announcements</h3>
          <img
            className="ms-4"
            src="http://localhost/images/installation-icon-48-48.png"
          ></img>
          <h3 className="text-primary  "> Latest Version</h3>
        </div>

        <div className="d-flex pt-5 ms-4">
          <img src="http://localhost/images/help-icon-48-48.png"></img>
          <h3 className="text-primary"> Helpful hints and scripts</h3>
          <img
            className="ms-4"
            src="http://localhost/images/ldap-server-48-59.png"
          ></img>
          <h3 className="text-primary  "> Mutillidae LDIF File </h3>
        </div>

        <div className="last text-center mt-5 ">
          <h5>
            Browser: Mozilla/5.0 (Windows NT 10.0; Win64; x64)
            AppleWebkit?537.36 (KHTML, like Gecko)chrome?122.0.0.0 safari?537.36
            <h5> PHP Version: 7.4.26</h5>
          </h5>
        </div>
      </StyledCacheControl>
    </>
  );
};

export default CacheControl;
const StyledCacheControl = styled.div`
  .image {
    padding-left: 50px;
  }
  .last {
    background-color: #e8e8e8;
  }
  .box {
    background-color: #e1e1e1;
  }
`;
