import axios from 'axios'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom/dist'

const Delete = () => {
    const params = useParams()
    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [monthlyprice, setMonthlyprice] = useState()
    const [yearlyprice, setYearlyprice] = useState()
    const [features, setFeatures] = useState()
    const deletedata = async () => {
        const data = new FormData()
        data.append('id', params.id);
        data.append('title', title);
        data.append('description', description);
        data.append('monthly_price', monthlyprice);
        data.append('yearly_price', yearlyprice);
        data.append('features', features);
        const response = await axios.post("https://globalhackersunited.com/deletedata.php", data, { headers: { 'content-type': 'multipart/form-data' } })
        if (response) {

            if (response.data.status === "success") {
                console.log(response.data)
            }
        }
    }

    return (
        <>

            <div className='col-lg-3 container p-5'>

                <button onClick={deletedata} type='button' className='btn btn-danger w-50'>Delete</button>

            </div>

        </>
    )
}

export default Delete