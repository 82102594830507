import React from "react";

const BackButtons = () => {
  return (
    <div className="mt-4">
      <div className=" border border-1 border-black title rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">
          Discussion of Back Button
        </p>
      </div>

      <div className="col-11 mx-auto ">
        <p className="fs-6 mt-5 ">
          The large back button image appears automatically on most pages in the
          site. If the image is clicked the user is redirected to the previous
          page. The button works by executing a javascript statement which sets
          document.location.href equal to the HTTP header referrer. The HTTP
          referrer is automatically set and sent by the browser. Some browsers
          allow the referrer to be set. In all cases, the user can alter the
          referrer using an interception proxy. A mallicious agent can override
          the referrer using a machine in the middle attack.
        </p>

        <p className="fs-6 d-block text-center ">
          Alter the HTTP referrer to a page other than the one intended such as
          www.google.com in order to redirect a user to an arbitrary page.
        </p>

        <p className="fs-6 d-block text-center">
          Alter the HTTP referrer to be a valid JavaScript statement in order to
          execute a XSS attack.
        </p>

        <p className="fs-6 d-block text-center ">
          Alter the referrer to break out of the JavaScript context then write
          HTML to the page to execute and HTML injection attack.
        </p>

      </div>
    </div>
  );
};

export default BackButtons;
