import React from 'react'
import User from '../../../components/User';
import { Link } from 'react-router-dom';

const EditProfile = () => {
  return (
    <div className='container mt-3'>
      <User user={"EDIT Profile"}/>
         <div>
            <div className='d-flex align-items-center'>
              <div>
                <img src="http://localhost/images/ajax_logo-75-79.jpg" alt=""/>
              </div>
              <h5 className='text-primary'> Switch to RESTful Web Service Version of this Page</h5>
            </div>



             <div className='text-center'>
              <h6>User profile not found.you may <Link to='/login' >Login here</Link></h6>
                 <p>Don't have account.<Link  to='/registerusers' >Please register here</Link></p>
             </div>

         </div>
         
    </div>
  )
}

export default EditProfile