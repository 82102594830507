import React from 'react'

import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab13 = () => {
    return (
        <div>

            <Title title={"Lab 13: Command injection - Testing for Reverse Connection"} />

            <StyledLab13>
                <div className='box1 border border-dark col-5 mx-auto rounded'>
                    <h5 className='text-center p-0 m-0'>The ping command uses ICMP Echo packets. When using </h5>
                    <h5 className='text-center p-0 m-0'>ping packets to test connectivity, what two types of ICMP</h5>
                    <h5 className='text-center p-0 m-0'>Echo packets show up in the tcpdump output?</h5>
                </div>
                <div className='col-5 mx-auto m-3'>
                    <div className=' d-flex'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'>echo request and echo reply
                        </h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'>echo locate and echo network</h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'> echo device and echo network
                        </h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <h6 className='p-0 m-0'> echo first and echo second</h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>
                        <h6 className='p-0 m-0'> echo pass and echo fail
                        </h6>
                    </div>
                </div>
                <div className=' submit col-1 mx-auto '>
                    <button className='rounded pe-3 ps-3 m-2'>
                        Submit
                    </button>
                </div>
                <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
                    <h6> Choose the best answer or view Hints and Videos</h6>
                </div>
                <div className='d-flex justify-content-center m-2 fs-5'>
                    <a className='  text-decoration-none p-2 '>
                        Previous
                    </a>
                    <div className='p-2'>|</div>
                    <a className='  text-decoration-none p-2 fs-5 '>
                        Next
                    </a>

                </div>
            </StyledLab13>
        </div>
    )
}

export default Lab13

const StyledLab13 = styled.div
    `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`