import React from 'react'
import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab26 = () => {
    return (
        <div>

            <Title title={"Lab 26: Cross-Site Scripting - Browser Exploitation Framework"} />

            <StyledLab26>
                <div className='box1 border border-dark col-5 mx-auto rounded'>
                    <h5 className='text-center p-0 m-0'>In the Browser Exploitation Framework lab, how does the</h5>
                    <h5 className='text-center p-0 m-0'>Browser Exploitation Framework infect the browser?</h5>
                   
                </div>
                <div className='col-5 mx-auto m-3'>
                    <div className=' d-flex'>
                        <div>
                            <input type="radio"></input></div>
                        
                            <h6 className='p-0 m-0'> BeEF logs into the database and poisons fields of datatype varchar
                            </h6>
                         

                    </div>
                    <div className=' d-flex p-0 m-0'>
                     <div>  <input type="radio"></input></div> 
<div>
                        <h6 className='p-0 m-0'> The reflected cross-site script downloads a self-starting script from</h6>
                        <h6 className='p-0 m-0'> the BeEF server</h6>
                        
                        </div>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <input type="radio"></input>

                        <h6 className='p-0 m-0'>The reflected cross-site script infects the user's email
                        </h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <div>
                            <input type="radio"></input></div>
                        <div>
                            <h6 className='p-0 m-0'>BeEF executes code on the application server that pulls down the
                            </h6>
                            <h6 className='p-0 m-0'>infection
                            </h6></div>
                            
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <input type="radio"></input>
                        <h6 className='p-0 m-0'>BeEF is magic
                        </h6>
                    </div>
                </div>
                <div className=' submit col-1 mx-auto '>
                    <button className='rounded pe-3 ps-3 m-2'>
                        Submit
                    </button>
                </div>
                <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
                    <h6> Choose the best answer or view Hints and Videos</h6>
                </div>
                <div className='d-flex justify-content-center m-2 fs-5'>
                    <a className='  text-decoration-none p-2 '>
                        Previous Lab
                    </a>
                    <div className='p-2'>|</div>
                    <a className='  text-decoration-none p-2 fs-5 '>
                        Next Lab
                    </a>

                </div>
            </StyledLab26>
        </div>
    )
}

export default Lab26

const StyledLab26 = styled.div
    `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`