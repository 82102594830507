import React from 'react'


import DownloadSection from '../DownloadSection'
import Title from '../Title'

const ThirdPartyFarming = (props) => {

const info = [0,1,2,3,4,5,6,7]

    return (



        <div>

            <section className='container-fluid'>

                <div className='col-lg-5 mx-auto'>
                    <Title title='Farmer' />

                    <div className='col-lg-12 mx-auto px-4'>


                        <div className='bg-warning border border-dark rounded text-center mb-4'>
                            <h6><b>This page attempts to frame Mutillidae. If it succeeds, we <br /> have a defect in our configuration. <br />
                                Are we being framed?</b></h6>
                        </div>
                        <p><b>Hint: Toggle through the security levels. What happens at security level 5?
                            Is there a new HTTP header at security level 5 that prevents framing?</b></p>


                    </div>
                </div>

                <div className='col-lg-7 mx-auto border border-3'>
                    <div className='col-lg-7 m-4'>
                        <DownloadSection />
                    </div>
                    <div className='clo-lg-5 d-flex justify-content-end'>
                        <img src="http://localhost/images/arrow-45-degree-left-up.png" alt="" />
                        <span className='text-center pe-4'><b>TIP: Click Hint and Videos <br /> on each page</b></span>
                    </div>
                    <div className='col-lg-8 d-flex flex-wrap align-items-center ms-4'>
                        {info.map((data)=>(
                            <a className='w-50 p-3' href="">
                            <img src={props.path} alt="" />
                            <b>{props.text} </b>
                        </a>
                        ))}
                        
                      
                    </div>
                </div>



            </section >




        </div >
    )
}

export default ThirdPartyFarming