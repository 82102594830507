import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import LinkIcon from '@mui/icons-material/Link';
const DownloadSection = () => {
    return (
        <div>


            <Dropdown className='col-12'>

                <Dropdown.Toggle variant="light" id="dropdown-basic" className='w-100 border border-dark'><b>Hints and Videos</b></Dropdown.Toggle>
                <Dropdown.Menu className='w-100 px-3'>
                    <Dropdown.Item href="#/action-1"><LinkIcon className='text-dark'/><a href=""><b>Cross-origin Resource Sharing (CORS)</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-2"><LinkIcon className='text-dark'/><a href=""><b>Using OWASP Zed Attack Proxy (ZAP)</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><LinkIcon className='text-dark'/><a href=""><b>Using Burp-Suite</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><LinkIcon className='text-dark'/><a href=""><b>Application Log Injection</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><LinkIcon className='text-dark'/><a href=""><b>Method Tampering</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><LinkIcon className='text-dark'/><a href=""><b>Command Injection (CMDi)</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><LinkIcon className='text-dark'/><a href=""><b>JavaScript Validation Bypass</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><LinkIcon className='text-dark'/><a href=""><b>HTML Injection (HTMLi)</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><LinkIcon className='text-dark'/><a href=""><b>Cross-site Scripting with BeEF Framework</b></a></Dropdown.Item>
                    <Dropdown.Item href="#/action-3"><LinkIcon className='text-dark'/><a href=""><b>Cross-site Scripting (XSS)</b></a></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
          
            
        </div>
    )
}

export default DownloadSection