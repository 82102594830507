import React from 'react'


import DownloadSection from '../DownloadSection'
import Title from '../Title'

const TextFileViewer = () => {
    return (


        <div>

            <section className='container-fluid'>

                <Title title='Hacker Files of Old' />


                <div className='col-lg-4 m-4'>
                    <DownloadSection />
                </div>


                <div className='col-lg-6 mx-auto'>
                    <div className='col-lg-9 mx-auto'>
                        <div className='col-lg-12 bg-warning border border-dark rounded text-center'>
                            <h6><b>Take the time to read some of these great old school hacker <br /> text files.
                                <br /> Just choose one form the list and submit</b></h6>
                        </div>

                        <div className='col-lg-12 d-flex align-items-center my-3'>
                            <p><b>Drop-down Box</b></p>
                            <select name="select" className=' ms-3 col-lg-9' id="id_select" required="required">
                                <option value="http://www.textfiles.com/hacking/auditool.txt">Intrusion Detection in Computers by Victor H. Marshall (January 29, 1991)</option>
                                <option value="http://www.textfiles.com/hacking/atms">An Overview of ATMs and Information on the Encoding System</option>
                                <option value="http://www.textfiles.com/hacking/backdoor.txt">How to Hold Onto UNIX Root Once You Have It</option>
                                <option value="http://www.textfiles.com/hacking/hack1.hac">The Basics of Hacking, by the Knights of Shadow (Intro)</option>
                                <option value="http://www.textfiles.com/hacking/hacking101.hac">HACKING 101 - By Johnny Rotten - Course #1 - Hacking, Telenet, Life</option>
                            </select>
                        </div>


                        <div className='text-center pb-2'>
                            <button className='btn btn-outline-secondary py-0 px-3 '>Echo Message</button>
                        </div>
                        <h6>For other great old school hacking texts, check out <a href="">http://www.textfiles.com/</a>.</h6>
                    </div>
                </div>


            </section >

        </div >




    )
}

export default TextFileViewer