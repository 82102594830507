import React from "react";

const Lab55 = () => {
  return (
    <div className="mt-2 p-4">
      <div className=" border border-1 border-black title rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">
          Using the Content Security Policy page in Mutillidae, CSP was
          effective against this attack
        </p>
      </div>

      <div>
        <tr>
          <input type="radio" />
          <th>Cross-site Scripting</th>
        </tr>

        <tr>
          <input type="radio" />
          <th>HTML injection</th>
        </tr>

        <tr>
          <input type="radio" />
          <th> Command injection</th>
        </tr>

        <tr>
          <input type="radio" />
          <th> All of these</th>
        </tr>

        <tr>
          <input type="radio" />
          <th>
          None of these
          </th>
        </tr>
      </div>

      <div className="text-center">
        <div className="btn btn-secondary border border-1 border-black mx-auto ps-3 pe-3 m-0 ">
          Submite
        </div>
      </div>

      <div className="bg-secondary text-center border border-1 border-black rounded-3 mt-4">
        <b>Choose the best answer or view Hints and Videos</b>
      </div>

      <div className="d-flex  mt-2 justify-content-center">
        <a className=" text-decoration-none fw-semibold " href="#">
          Privew Lab
        </a>{" "}
        |
        <a className=" text-decoration-none fw-semibold " href="#">
          Next Lab
        </a>
      </div>
    </div>
  );
};

export default Lab55;
