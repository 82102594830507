import React, { useEffect, useState } from 'react'
import HalfRating from '../../components/HalfRating';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Endpoint from '../../assets/Endpoint';


const ToolsDetails = () => {
  const params = useParams()
  const [toolsdetails, setToolsdetails] = useState('')
  console.log(params.id)

  const Details = async () => {


    const data = new FormData()
   

    data.append('id',params.id);
    
    const res = await axios.post(Endpoint+"get-tool-details.php", data, { headers: { 'content-type' : 'mutlipart/form-data' } })
    if (res) {
      if (res.data.status === 'success') {
        setToolsdetails(res.data.data)
      }
    }
  }

  console.log(toolsdetails)

  useEffect(() => {
    Details()
  }, [])







  return (
    <div>
    {
      toolsdetails ?
        <div className='col-lg-12 container '>
          <div className='d-flex flex-wrap'>
            <div className=' '>
              <img className='p-3 img' src={toolsdetails.img_url} alt="" />
            </div>
            <div className=' col-lg-4 col-sm-4 col-md-4 col-12'>
              <h4 className='mt-4' >{toolsdetails.title}</h4>
              <div className='d-flex '>
                <div>
                  <p className='me-2 bg-secondary rounded-1 text-white p-1'> version:{toolsdetails.version}</p>
                </div>
                <p className='bg-secondary rounded-1 text-white p-1'>size:{toolsdetails.size}</p>
              </div>
             <HalfRating/>
            </div>
          </div>
          <hr></hr>

          <div>
            <h1>Packages And Binaries</h1>
            <div className='col-10'>
              <h5>{toolsdetails.title}</h5>
              <p className='col-10'>{toolsdetails.description}</p>
            </div>

          </div>

        </div> : null
    }
  </div> 
)
}

export default ToolsDetails

