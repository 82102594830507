import React from 'react'


import DownloadSection from '../DownloadSection'
import Title from '../Title'


const ClickJacking = (data) => {
    return (


        <div>

            <section className='container-fluid'>

                <Title title='Page Viewer' />


                <div className='col-lg-4 m-4'>
                    <DownloadSection />
                </div>


                <div className='col-lg-12'>
                    <div className='col-lg-7 mx-auto'>
                        <div className='col-lg-12 bg-warning border border-dark rounded text-center mb-2'>
                            <h6><b>Click on portion of pictures to enlarge</b></h6>
                        </div>
                        <p>Starting with the mouse off of the picture, move the mouse over the pitcher, then click the enlarge a portion of the picture. </p>
                    </div>
                    <div className='col-lg-4'>
                        <img className='w-100' src={data.path} alt="" />
                    </div>
                </div>


            </section>

        </div>



    )
}

export default ClickJacking