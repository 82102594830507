import React from 'react'

const DnsLookup = () => {
    return (
        <>
            <div className="container">
                <div className='col-lg-6'>
                    <div className='d-flex align-items-center'>
                        <div className='ajax'>
                            <img className='w-100' src='https://scottiestech.info/wp-content/uploads/2009/12/form_ajax-150x150.jpg' />
                        </div>
                        <div>
                            <h5 className='text-primary ms-3'>Switch to RESTful Web Service Version of this Page</h5>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 mx-auto'>
                    <div className='text-center bg-secondary p-1 rounded'>
                        <h5 className='text-white'>Enter IP or hostname</h5>
                    </div>
                    <div className='d-flex mt-3'>
                        <div>
                            <h6>Hostname/IP</h6>
                        </div>
                        <div className='ms-5'>
                            <input type='text' />
                            <div className='mt-2'>
                                <a href='#' className='btn btn-success'>Lookup DNS</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DnsLookup