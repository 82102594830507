import React from "react";
import "./Homepage.css";
import {
  Dropdown,
  DropdownButton,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";
import { Select } from "@mui/material";
import SelectInput from "@mui/material/Select/SelectInput";
import SideBar from "../../components/SideBar";
import styled from "styled-components";

const HomePage = () => {
  return (
    <Style>
      <div className="d-flex p-1">


        <div className="col-xl-10 col-lg-9 ">
          <div className="mx-auto d-flex border border-1 border-black rounded-3 col-7 title ">
            <b className="mx-auto fs-5">
              Please enter flage into all form feilds
            </b>
          </div>

          <div className="col-6 mx-auto my-5">
            <small className="text-start text-dark fw-normal fs-6">
              Please enter the following flag into each field and choose the
              flag in each control. For example enter the flag into all text
              fields and choose the flag in the drop down, check the box next to
              the flag, and select the radio button for the flag.
            </small>

            <p className="text-dark fw-normal fs-6 my-4">
              Be certain <b>every</b> control contains the value of the flag.
            </p>

            <p>When all controls have the value of the flag submit the form.</p>

            <div className="col-8">
              <div className="d-flex justify-content-between">
                <div>
                  <b>Flage</b>
                </div>
                <div>
                  <b>123456789</b>
                  <a className="text-decoration-none ms-4">
                    <b>Get New Value</b>
                  </a>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-5">
                <div>
                  <b>Text Box</b>
                </div>
                <div className="">
                  <input className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Read-Only Text Box</b>
                </div>
                <div className="">
                  <input readOnly className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Short Text Box</b>
                </div>
                <div className="">
                  <input className="text-start rounded-3 col-4" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Disable Text Box</b>
                </div>
                <div className="">
                  <input disabled className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Hidden Text Box</b>
                </div>
                <div className="">
                  <input hidden className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Secured by java Script Text Box</b>
                </div>
                <div className="">
                  <input className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Vanishing Text Box</b>
                </div>

                <div className="">
                  <input hidden className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Shy Text Box</b>
                </div>
                <div className="">
                  <input className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Search Text Box</b>
                </div>
                <div className="">
                  <input className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Password</b>
                </div>
                <div className="">
                  <input className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Drop-down Box</b>
                </div>
                <div className="">
                  <select className="rounded-2 w-100 ps-5 pe-5">
                    <option value="volvo">Volvo</option>
                    <option value="saab">Saab</option>
                    <option value="mercedes">Mercedes</option>
                    <option value="audi">Audi</option>
                  </select>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Check Box</b>
                </div>

                <div className="">
                  <div className="d-flex">
                    <input type="checkbox" className="text-start rounded-3 " />
                    <b className="fs-4">Select 123456</b>
                  </div>

                  <div className="d-flex">
                    <input type="radio" className="text-start rounded-3 " />
                    <b className="fs-6">1</b>
                  </div>

                  <div className="d-flex">
                    <input type="radio" className="text-start rounded-3 " />
                    <b className="fs-6">2</b>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Email Control</b>
                </div>
                <div className="">
                  <input className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1 ">
                <div>
                  <b>File Upload</b>
                </div>
                <div className=" ">
                  <input type="file" className=" rounded-3 " />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Email Control</b>
                </div>
                <div className="">
                  <input type="number" className="text-start rounded-3" />
                </div>
              </div>

              <div className="d-flex justify-content-between mt-1">
                <div>
                  <b>Email Control</b>
                </div>
                <div className="">
                  <input type="range" className="text-start rounded-3" />
                </div>
              </div>

              <div className="mt-4 mx-auto text-center ">
                <div className="btn btn-secondary ">
                  <b className="m-0 ">Submite</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
};

export default HomePage;

export const Style = styled.div`
  .title {
    background-color: #bc6c2550 !important;
  }
`;
