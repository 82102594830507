import React from 'react'


import DownloadSection from '../DownloadSection'
import Title from '../Title'

const FileUpload = (props) => {
    return (


        <div>

            <section className='container-fluid'>


                <div className='col-lg-4 m-4'>
                    <DownloadSection />
                </div>

                <Title title='Upload a File' />


                <div className='col-lg-12'>
                    <div className='col-lg-5 mx-auto'>
                        <div className='col-lg-12 bg-warning border border-dark rounded text-center mb-2'>
                            <h6><b>Please choose file to upload </b></h6>
                        </div>
                        <div className='col-lg-12 d-flex align-items-center py-3'>
                            <h6>FileName</h6>
                            <input type="text" className='rounded border border-dark mx-3' disabled="disabled" name="filename-text" id="idFilenameText" size="60" />
                            <img src={props.data} alt='' />
                        </div>
                        <div className='text-center'>
                            <button className='btn btn-outline-secondary py-0 px-3 '>Upload File</button>
                        </div>
                    </div>

                </div>


            </section>

        </div>




    )
}

export default FileUpload