import React from 'react'
import User from '../../../components/User'

const ViewUser = () => {
  return (
    <div className='container mt-4'>
       <User user={"View User Privilege Level"}/>
       <div className='mt-4'>
        <div className='name col-lg-6 border border-black rounded text-center mx-auto'>
            <h6>User Privilage Level</h6>
        </div>
        <div>
        <div className='d-flex justify-content-evenly'>
           <div>
             <h5>Application ID</h5>
           </div>
           <p>	A1B2</p>
        </div>

        <div className='d-flex justify-content-evenly'>
           <div>
             <h5>User ID</h5>
           </div>
           <p>100(Hint: 0X31 0X30 0X30)</p>
        </div>

        <div className='d-flex justify-content-evenly'>
           <div>
             <h5>Group ID</h5>
           </div>
           <p>100(Hint:0X31 0X30 0X30)	</p>
        </div>
        </div>

        <div className='col-lg-5 mx-auto text-center'>
        <h5>
        Note: UID/GID "000" is root.
You need to make User ID and Group ID equal to
"000" to become root user.
        </h5>
       </div>


       <div className='col-lg-5 mx-auto text-center mt-3'>
        <h5>
        Security level 1 requires three times more work
but is not any harder to solve.
        </h5>
       </div>
       </div>

      
    </div>
  )
}

export default ViewUser