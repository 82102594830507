import React from 'react'
import styled from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css';
import Title from '../../../components/Title';

const Register = () => {
  return (
    <StyledRegister>
    <div>
      <Title title={"Register for an Account"}/>
    <div className='border border-2 border-dark text-center rounded p-1 error m-3 '>
        <h3>Username cannot be blank</h3>
      </div>
        <div className='d-flex mt-3'>
        <img src='http://localhost/images/ajax_logo-75-79.jpg'></img>
        <h5 className='text-primary my-auto'>Switch to SOAP Web Service version</h5>
        </div>

        <div className='col-5 mx-auto m-5'>
        <div className='border border-2 border-dark text-center rounded-2 p-1 user'>
        <h5>Please choose your username,password and signature</h5>
         </div>

         <div className='d-flex'>
           <div className='mt-3'>
            <h5>Username</h5>
            <h5 className='mt-3'>Password</h5>
            <h5 className='mt-3'>ConfirmPassword</h5>
            <h5 className='mt-3'>Signature</h5>
            </div>

           <div className='input'>
            <div>
             <input className='mt-3 border border-2 border-dark'></input>
             </div>
             <div className='d-flex'>
             <input className='mt-3 border border-2 border-dark'></input>
             <h5 className='ms-3 text-primary'>Password Generator</h5>
             </div>
             <div>
             <input className='mt-3 border border-2 border-dark'></input>
             </div>
             <div className='mt-3 h-25'>
              <textarea className='w-100 h-100'></textarea>
             </div>
         </div>
       </div>
          </div>

          <div className='d-flex justify-content-center'>
             <button className='col-3 ms-5 rounded mt-2'>Create  Account</button>
          </div>

      <div className='border border-1 border-dark m-5 p-2'>
        <h5 >CSRF Protection Information</h5>
        <div className='col-3 mx-auto mt-5'>
          <div className='border border-dark text-center box'>
            <h5>Posted Token:</h5>
            <h5>(Validation not performed)</h5>
          </div>
          <div>
           <p className='mt-2'>Expected Token For This Request:</p>
           <p>Token Passed By Used For This Request:</p>
           </div>
           <div>
           <p className='mt-4'>New Token For Next Request</p>
           <p>Token Stored in Session:</p>
           </div>

        </div>
        </div>
    </div>
    </StyledRegister> 
  )
}

export default Register
const StyledRegister= styled.div`
.input{
    padding-left:50px;
}
.box{
    background-color:#dddddd;
  }
  .user{
    background-color:#f2dfc3;
  }
  .error{
    background-color:#fbeaea
  }
`