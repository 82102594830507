import React from 'react'
import User from '../../../components/User'

const Secret = () => {
  return (
    <div className='container mt-4'>
        <User user={"Secret Administrative Pages"}/>
       <div className='col-lg-6 mx-auto mt-4'>
       <div className='name border border-black rounded-2 text-center'>
            <h5>
"Secret" administrative or configuration pages</h5>
        </div>

        <div>
            <p>
            Showing server configurations on pages allowed through the firewall is a bad idea. "Hiding" pages by not linking to them so you believe you are the only one who knows the URL doesnt work. There are tools to brute force the URL, shoulder surfing, log history, browser history, router-firewall-proxy history, scanners, guessing and other methods can get these URLs. or admin functions, create a second site inside the firewall to segregate these pages from the Internet facing site.  
            </p>

            <p>
            I wonder what clever name the server admin would give to a PHP page that shows server configuration information? Hint: What is the function in PHP that dumps server configuration information into a nice table? Enable hints if you need more help.
            </p>
        </div>
       </div>
    </div>
  )
}

export default Secret