import React from 'react'
import Title from '../../../../components/Title'
import styled from 'styled-components'
const PHPInfoPage = () => {
    return (
        <StyledPHPInfoPage>
            <div>
                <Title title={"Robotics.txt"}></Title>
                <div className='col-4 mx-auto'>
                    <div className='heading border border-2 border-dark mt-5 text-center rounded' >
                        <h4>robotics.txt</h4>
                     </div>
                    <div className='mt-2'>
                        <button className='rounded' >Reconnaissance</button>
                    </div>
                   
                        <p className='mt-2'>
                        Web applications may cache information locally to increase performance. Caching a copy on client avoids retransmission and caching is useful for images and static content
                        </p>
                        <p className='mt-3'>Dynamic (i.e. interactive) pages such as forms tend to collect or display sensitive information. Some information is sensitive in any context such as SSN, CC, user profile, etc.</p>
                    <p className='mt-3'>Some information may not be sensitive to the custodian (i.e. corporation, government) but is sensitive to the owner such as pharmacy invoice, travel arrangements, etc.</p>
                    <p className='mt-3'>Regardless of sensitivity, information leakage may raise privacy concerns. Any content from a domain reveals the user visited the page. Even when content itself not sensitive, disclosing browsing history may be privacy violation.</p>
                    <p className='mt-3'>Cache controls must be used when the content-type indicates the content may contain user data. Of particular concern are media types that carry user data like HTML, JSON, XML, etc. Browsers also cache documents. Document caching leaves document on the browser. This may result in information disclosure of sensitive information.</p>
                    <button className='rounded mt-3' >Exploitation</button>
                    <h6 className='mt-2'>How to view cached items in Firefox</h6>
                    <p>Type about:cache in the address bar</p>
                    <h6>How to view cached items in Internet Explore</h6>
                    <p className='mt-2'> .   In the Tools menu, choose Internet Options</p>
                    <p> .  On the General tab under Temporary Internet Files, click the Settings button</p>
                    <p> .  From the Settings dialog, click the View Files button</p>
                    
                    <button className='mt-3 rounded' >Reporting</button>
                    <p className='mt-2'>RFC-7234 from the Internet Engineering Task Force (IETF) specifies caching controls. HTTP headers are used to specify caching directives (Section 5.2.1). "Cache-Control" is standard for HTTP/1.1. "Pragma": provides backwards compatibility with HTTP/1.0 clients</p>
                    <p className='mt-2'>Strategy for Implementing Cache Contro</p>
                    <p >The correct cache-control to use depends on the type of document. Browsers can natively parse HTML, JSON, XML, CSS, JavaScript and other formats. Document formats such as PDF, DOCX, XLSX and PPTX must be handed off to other applications. Native content cache-control (aka "forms cache control") is used when the document is a type the browser parses natively</p>
                    <h6 className='mt-2' >Cache-Control: no-store, no-cache</h6>
                    <p className='mt-2'>Static document cache-control is used when the static document is handled by an external application:</p>
                    <h6 className='mt-2'>Cache-Control: no-store, no-cache, max-age=0, must-revalidate</h6>
                    <p className='mt-2' >URI tagging or streaming document cache-control is used for streamed content.</p>
                    
                </div>
            </div>
        </StyledPHPInfoPage>
    )
}

export default PHPInfoPage
const StyledPHPInfoPage = styled.div`
.heading{
    background-color:#f2dfc3;
}
p{padding:0px;
    margin:0px;}
`