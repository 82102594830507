import React from "react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CampaignIcon from "@mui/icons-material/Campaign";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "react-bootstrap";
import { List } from "@mui/material";

const SideBar = () => {
  return (
    <div>
      <div className="col-12 p-1 sidebackground ">
      <div className="sidebar m-1 text-center p-1 col-9 mx-auto">
          <Dropdown>
            <DropdownButton
              variant="none"
              title={<b className="p-0 m-0 text-light">OWASP 2021</b>}
              drop="end"
            >
              <DropdownButton
                title={<b>A1 - Injection(SQL)</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>SQLi - Extract Data</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info (SQL)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>SQLi - Bipass Authentication</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>SQLi - Interset Injetion</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/add-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/insertregister"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Capture Data
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Bind SQL via Timing</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>SQLiMAP Practice</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/sqlmap-target"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      SQLMAP Practice Target
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/view-someone-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Someone Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info (SQL)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Java Script Object Notation</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/pentesttoolajax"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup(Ajex)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via SOAP WEB Service</b>}
                  drop="end"
                  variant="none"
                >
                  {" "}
                  <DropdownItem>
                    <Link
                      to="/userlookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Lookup User
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Rest Web Service</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/user-account-management"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Account Management
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A1 - Injection (Other)</b>}
                variant="none"
                drop="end"
              >
                <DropdownButton
                  title={<b>Application Log Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Dns Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo message
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo message(CORS)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo message(CSP)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      {" "}
                      Capture Data page
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/application-login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/register-user"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register User
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/codeviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Source Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivety Server
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test File Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Buffer Overflow</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/repeater"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Repeater
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Cascading Style Injection </b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/setbackground"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      setbackground
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem variant="none" drop="end">
                  <Link to="/view-user-privilege">
                    <b>View User Privilige</b>
                  </Link>
                </DropdownItem>

                <DropdownButton
                  title={<b>Command Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Dns Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Dns Lookup(SOAP Web Services)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivety Server
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test File Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Frame Source Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/stylemutilidae"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Styling With Mutilidae
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTML Injection(HTMLi)</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/add-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Dns Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echo-cors"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivity to server
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Text File Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info (SQL)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info (Xpath)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/setbackground"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      SetbackgroundColor
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML5 Web Storage
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Capture Data
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Capture Data
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      {" "}
                      Document Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/arbiteraryfileinclusion"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Arbitrary File Inclusion
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/poll-question"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/register-user"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      {" "}
                      Register User
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTMLi via Http Headers</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/backbutton"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Those "Back" Buttons
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/sitefooter"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Site Fotter
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/sitefooter"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Http Responce Splitting(Hint:different)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTMLi via Dom Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML5 Web Storage
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/password-generator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Password Generator
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTMLi via cookie Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Capture Data
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTTP Parameter Pollution</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/poll-question"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>JavaScript Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/backbutton"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Those "Back" Buttons
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/password-generator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Password Generator
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>JavaScript object Notation Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/pentesttoolajax"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup(AJAX)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>LDAP Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/Conferenceroom"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Conference room Lookup
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Parameter Addition</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/repeater"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Repeater
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/viewuserprivilege"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      <b>View User Privileges</b>
                    </Link>
                  </DropdownItem>
                  x
                </DropdownButton>

                <DropdownButton
                  title={<b>XML Externaml Entity Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/xml-validation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      {" "}
                      XML Validation{" "}
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>XML Entity Expansion</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/xml-validation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Xml Validation
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>XML Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/xml-validation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      XML validation
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Xpath Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      user Info (Xpath)
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={
                  <b>
                    A2 - Broken Authentication <br /> and Session Management
                  </b>
                }
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>Authentication ByPass</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Brute Force
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                  <DropdownItem>
                    <Link to="/via-cookies" className=" text-decoration-none fw-bold text-black">Via Cookies</Link>
                  </DropdownItem>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Sql Injection
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/editprofile"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Account Hijacking
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Priviliege Escalation</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                  <DropdownItem>
                    <Link to="/via-cookies" className=" text-decoration-none fw-bold text-black">Via Cookies</Link>
                  </DropdownItem>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/editprofile"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Account Hijacking
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/viewuserprivilege"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via CbC-bite flipping
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>UserName Enumeration</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/Editprofile"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Edit User Profile
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/userlookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Lookup User
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/user-account-management"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Account Management
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Json Web Token(JWT)</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/CurrentUserInformation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Current User Information
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A3 - Sensitive Data Exposure</b>}
                variant="none"
                drop="end"
              >
                <DropdownButton
                  title={<b>Information Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/EditUserProfile">Edit User Profile</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/phpinfopage">PHP Info page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/robots">robots.txt</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/secret">"SECRET" Administartion Page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/webstorage">HTML5 Web Storage</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/client-side-comments">
                      HTML/JavaScript Comments
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/catch-control">Catch-control</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/clickjacking">Click - Jacking</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">Cross-site Framing</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">X-Frame Options (Click - Jacking)</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">X-Frame Options (Cross Frame -Jacking)</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Application path Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/phpinfopage">PHP Info Page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/Robots">Robots.txt</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Platform Path Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/phpinfopage">Php Info Page</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem
                  variant="none"
                  drop="end"
                > <b>
                <Link to="/ssl">SSL Misconfigration</Link>
              </b></DropdownItem>

                <DropdownButton
                  title={<b>JSON WEB Token (JWT)</b>}
                  variant="none"
                  drop="end"
                >
                  {" "}
                  <DropdownItem>
                    <Link>
                      <b> Current User Information</b>
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A4 - XML External Entites</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b> XML External Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <Link to="/xmlvalidator">
                    {" "}
                    <b>XML Validator</b>
                  </Link>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A5 - Broken Access Control</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>Insecure Direct Object Reference(IDOR)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Account Hijacking
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/codeviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Source Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Text File Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownButton
                    title={<b>Local File Inclusion(LFI)</b>}
                    drop="end"
                    variant="none"
                  >
                    <DropdownItem>
                      <Link
                        to="/lfi"
                        className="text-decoration-none text-dark fw-bold"
                      >
                        Local File Inclusion(LFI)
                      </Link>
                    </DropdownItem>
                  </DropdownButton>
                  <DropdownButton
                    title={<b>Remote File Inclusion(RFI)</b>}
                    drop="end"
                    variant="none"
                  >
                    <DropdownItem>
                      <Link
                        to="/rfi"
                        className="text-decoration-none text-dark fw-bold"
                      >
                        Remote File Inclusion(RFI)
                      </Link>
                    </DropdownItem>
                  </DropdownButton>

                  <DropdownButton
                    title={<b>Open Redirect</b>}
                    drop="end"
                    variant="none"
                  >
                    <DropdownItem>
                      <Link
                        to="/credits"
                        className="text-decoration-none text-dark fw-bold"
                      >
                        Credits
                      </Link>
                    </DropdownItem>
                  </DropdownButton>

                  <DropdownButton
                    title={<b>Server-side Request Forgery(SSRF)</b>}
                    drop="end"
                    variant="none"
                  >
                    <DropdownItem>
                      <Link
                        to="/testconnectivityserver"
                        className="text-decoration-none text-dark fw-bold"
                      >
                        Test Connectivity to Server
                      </Link>
                    </DropdownItem>
                  </DropdownButton>
                </DropdownButton>

                <DropdownButton
                  title={<b>Missing Function Level Access Control</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/cookies-as-Tokens"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Cookies as Auth Tokens
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/secret"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Secret Administrative Pages
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/control-challenge"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Client-side Control Challenge
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/robots"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Robots.txt
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>JSON Web Token(JWT)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/CurrentUserInformation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Current User Information
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Cross-Origne Resource Sharing (CORS)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A6 - Security Misconfigration</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>Cross Origin Resource Chain</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/echo-cors"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem>
                  <Link
                    to="/directory-browser"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Directory Browsing
                  </Link>
                </DropdownItem>

                <DropdownButton
                  title={<b>Methord Tampering (Get For Post)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/method-user-info"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(SQL)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(XPath)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/userpoll"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      DNS Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echo-cors"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivity to Server
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Http Headers</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="echo-cors"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Cross-origin Resource Sharing (CORS)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/catch-control"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Cache Control
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/clickjacking"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Click Jacking
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/thirdparty-farming"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Cross-Site Framing(Third-Party Framing)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem>
                  <Link
                    to="/user-agent-impresion"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    User-Agent Impersonation
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/fileupload"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Unrestricted File Upload
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/ssl"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    SSL Misconfiguration
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/phpinfopage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    PHP Info Page
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/robots"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Robots.txt
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>A7 - Cross Site Scripting</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>Reflected(First Order)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      DNS Lookup
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivity to Server
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Text File Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(SQL)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/setbackground"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Set Background Color
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML 5 Web Storage
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Capture Data Page
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/arbiteraryfileinclusion"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Arbitrary File Inclusion
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/xmlvalidator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      XML Validator
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(XPath)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/userpoll"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/registeraccount"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register User
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/backbutton"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Those "Back" Buttons
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/stylemutilidae"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Styling with Mutillidae
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/password-generator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Password Generator
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/control-challenge"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Client-side Control Challenge
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Persistent( Second order)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/view-someone-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Someone's Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/registeraccount"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register User
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/Editprofile"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Edit User Profile
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/weblogs"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Show Log
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>DOM Based</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML 5 Web Storage
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/password-generator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Password Generator
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Cross Site Request Forigen</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/registeraccount"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register User
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/userpoll"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Input</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/view-someone-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Someone's Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/weblogs"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Show Log
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Text File Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      DNS Lookup
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivity to Server
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(SQL)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(XPath)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Missing HTTPOnly Attribute
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/setbackground"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Set Background Color
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Http Headers</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/weblogs"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Show Log
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/sitefooter"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Site Footer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/backbutton"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Those "Back" Buttons
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Http Attribute</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Misconfigration</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Missing HTTPOnly Attribute
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Againest HTML5 Web Storage</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML 5 Web Storage
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Againest JSON</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via cookie Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Captured Data Page
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via XML Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/xmlvalidator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      XML Validator
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Xpath Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="via-xpath-injection-user-info"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(XPath)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Path Relative Style Sheet Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/stylemutilidae"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Styling With Mutilidae
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Beef FrameWork Targets</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  A8 - Insecure Deserialization
                </Link>
              </DropdownItem>

              <DropdownButton
                title={<b>A9 - Using components with known Vulenrablites</b>}
                drop="end"
                variant="none"
              >
                <DropdownItem>
                  <Link
                    to="/phpinfopage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    PHP INFO
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/view-user-privilege"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    CBC Bite Flipping
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/ssl"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    SSL Misconfigration
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  A10 - Insufficent logging and Monitoring
                </Link>
              </DropdownItem>
            </DropdownButton>
          </Dropdown>
        </div>

        {/* 2017 */}
        <div className="sidebar m-1 text-center p-1 col-9 mx-auto">
          <Dropdown>
            <DropdownButton
              variant="none"
              title={<b className="p-0 m-0 text-light">OWASP 2017</b>}
              drop="end"
            >
              <DropdownButton
                title={<b>A1 - Injection(SQL)</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>SQLi - Extract Data</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/userinfo"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info (SQL)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>SQLi - Bipass Authentication</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/bindsqllogin"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>SQLi - Interset Injetion</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/add-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/insertregister"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Capture Data
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Bind SQL via Timing</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/bind-sql-login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/bind-sql-user-info"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>SQLiMAP Practice</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/sqlmap-target"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      SQLMAP Practice Target
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/sqli-map-login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/view-someone-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Someone Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/sqli-map-user-info"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info (SQL)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Java Script Object Notation</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/pentesttoolajax"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup(Ajex)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via SOAP WEB Service</b>}
                  drop="end"
                  variant="none"
                >
                  {" "}
                  <DropdownItem>
                    <Link
                      to="/via-lookup-user"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Lookup User
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Rest Web Service</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/user-account-management"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Account Management
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A1 - Injection (Other)</b>}
                variant="none"
                drop="end"
              >
                <DropdownButton
                  title={<b>Application Log Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Dns Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo message
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo message(CORS)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo message(CSP)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      {" "}
                      Capture Data page
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/application-login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/application-user-register"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register User
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/codeviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Source Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivety Server
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test File Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Buffer Overflow</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/repeater"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Repeater
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Cascading Style Injection </b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/setbackground"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      setbackground
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem variant="none" drop="end">
                  <Link to="/view-user-privilege">
                    <b>View User Privilige</b>
                  </Link>
                </DropdownItem>

                <DropdownButton
                  title={<b>Command Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Dns Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Dns Lookup(SOAP Web Services)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivety Server
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test File Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Frame Source Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/stylemutilidae"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Styling With Mutilidae
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTML Injection(HTMLi)</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/add-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Dns Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echo-cors"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivity to server
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Text File Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/html-injection-user-info"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info (SQL)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/html-injection-user-info-xpath"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info (Xpath)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/setbackground"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      SetbackgroundColor
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML5 Web Storage
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Capture Data
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Capture Data
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      {" "}
                      Document Viewer
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/arbiteraryfileinclusion"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Arbitrary File Inclusion
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/poll-question"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/html-injection-register"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      {" "}
                      Register User
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTMLi via Http Headers</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/backbutton"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Those "Back" Buttons
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/sitefooter"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Site Fotter
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/sitefooter"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Http Responce Splitting(Hint:different)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTMLi via Dom Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML5 Web Storage
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/password-generator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Password Generator
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTMLi via cookie Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Capture Data
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>HTTP Parameter Pollution</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/poll-question"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>JavaScript Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/backbutton"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Those "Back" Buttons
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/password-generator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Password Generator
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>JavaScript object Notation Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/pentesttoolajax"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup(AJAX)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>LDAP Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/Conferenceroom"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Conference room Lookup
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Parameter Addition</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/repeater"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Repeater
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/viewuserprivilege"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      <b>View User Privileges</b>
                    </Link>
                  </DropdownItem>
                  x
                </DropdownButton>

                <DropdownButton
                  title={<b>XML Externaml Entity Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/xml-validation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      {" "}
                      XML Validation{" "}
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>XML Entity Expansion</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/xml-validation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Xml Validation
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>XML Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/xml-validation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      XML validation
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Xpath Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/xpath-injection-userinfo-xpath"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      user Info (Xpath)
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={
                  <b>
                    A2 - Broken Authentication <br /> and Session Management
                  </b>
                }
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>Authentication ByPass</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/via-account-hijacking-login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Brute Force
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                  <DropdownItem>
                    <Link to="/via-cookies" className=" text-decoration-none fw-bold text-black">Via Cookies</Link>
                  </DropdownItem>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Sql Injection
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/editprofile"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Account Hijacking
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Priviliege Escalation</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                  <DropdownItem>
                    <Link to="/via-cookies" className=" text-decoration-none fw-bold text-black">Via Cookies</Link>
                  </DropdownItem>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/priviliege-escalation-login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/editprofile"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Account Hijacking
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/viewuserprivilege"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via CbC-bite flipping
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>UserName Enumeration</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/username-enumeration-login"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Login
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/Editprofile"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Edit User Profile
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/user-enumeration-lookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Lookup User
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/user-account-management"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Account Management
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Json Web Token(JWT)</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/CurrentUserInformation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Current User Information
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A3 - Sensitive Data Exposure</b>}
                variant="none"
                drop="end"
              >
                <DropdownButton
                  title={<b>Information Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/EditUserProfile">Edit User Profile</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/phpinfopage">PHP Info page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/robots">robots.txt</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/secret">"SECRET" Administartion Page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/webstorage">HTML5 Web Storage</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/client-side-comments">
                      HTML/JavaScript Comments
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/catch-control">Catch-control</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/clickjacking">Click - Jacking</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">Cross-site Framing</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">X-Frame Options (Click - Jacking)</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">X-Frame Options (Cross Frame -Jacking)</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Application path Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/phpinfopage">PHP Info Page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/Robots">Robots.txt</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Platform Path Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/phpinfopage">Php Info Page</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem
                  variant="none"
                  drop="end"
                > <b>
                <Link to="/ssl">SSL Misconfigration</Link>
              </b></DropdownItem>

                <DropdownButton
                  title={<b>JSON WEB Token (JWT)</b>}
                  variant="none"
                  drop="end"
                >
                  {" "}
                  <DropdownItem>
                    <Link>
                      <b> Current User Information</b>
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A4 - XML External Entites</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b> XML External Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <Link to="/xmlvalidator">
                    {" "}
                    <b>XML Validator</b>
                  </Link>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A5 - Broken Access Control</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>Insecure Direct Object Reference(IDOR)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/via-account-hijacking"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Via Account Hijacking
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/codeviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Source Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Text File Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownButton
                    title={<b>Local File Inclusion(LFI)</b>}
                    drop="end"
                    variant="none"
                  >
                    <DropdownItem>
                      <Link
                        to="/lfi"
                        className="text-decoration-none text-dark fw-bold"
                      >
                        Local File Inclusion(LFI)
                      </Link>
                    </DropdownItem>
                  </DropdownButton>
                  <DropdownButton
                    title={<b>Remote File Inclusion(RFI)</b>}
                    drop="end"
                    variant="none"
                  >
                    <DropdownItem>
                      <Link
                        to="/rfi"
                        className="text-decoration-none text-dark fw-bold"
                      >
                        Remote File Inclusion(RFI)
                      </Link>
                    </DropdownItem>
                  </DropdownButton>

                  <DropdownButton
                    title={<b>Open Redirect</b>}
                    drop="end"
                    variant="none"
                  >
                    <DropdownItem>
                      <Link
                        to="/credits"
                        className="text-decoration-none text-dark fw-bold"
                      >
                        Credits
                      </Link>
                    </DropdownItem>
                  </DropdownButton>

                  <DropdownButton
                    title={<b>Server-side Request Forgery(SSRF)</b>}
                    drop="end"
                    variant="none"
                  >
                    <DropdownItem>
                      <Link
                        to="/testconnectivityserver"
                        className="text-decoration-none text-dark fw-bold"
                      >
                        Test Connectivity to Server
                      </Link>
                    </DropdownItem>
                  </DropdownButton>
                </DropdownButton>

                <DropdownButton
                  title={<b>Missing Function Level Access Control</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/cookies-as-Tokens"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Cookies as Auth Tokens
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/secret"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Secret Administrative Pages
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/control-challenge"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Client-side Control Challenge
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/robots"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Robots.txt
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>JSON Web Token(JWT)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/CurrentUserInformation"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Current User Information
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Cross-Origne Resource Sharing (CORS)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b>A6 - Security Misconfigration</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>Cross Origin Resource Chain</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/echo-cors"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem>
                  <Link
                    to="/directory-browser"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Directory Browsing
                  </Link>
                </DropdownItem>

                <DropdownButton
                  title={<b>Methord Tampering (Get For Post)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/method-user-info"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(SQL)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/method-user-info-xpath"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(XPath)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/userpoll"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      DNS Lookup
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echo-cors"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivity to Server
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Http Headers</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="echo-cors"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Cross-origin Resource Sharing (CORS)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/catch-control"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Cache Control
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/clickjacking"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Click Jacking
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/thirdparty-farming"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Cross-Site Framing(Third-Party Framing)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem>
                  <Link
                    to="/user-agent-impresion"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    User-Agent Impersonation
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/fileupload"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Unrestricted File Upload
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/ssl"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    SSL Misconfiguration
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/phpinfopage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    PHP Info Page
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/robots"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Robots.txt
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>A7 - Cross Site Scripting</b>}
                drop="end"
                variant="none"
              >
                <DropdownButton
                  title={<b>Reflected(First Order)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      DNS Lookup
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivity to Server
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Text File Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/reflected-register"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(SQL)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/setbackground"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Set Background Color
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML 5 Web Storage
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Capture Data Page
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/arbiteraryfileinclusion"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Arbitrary File Inclusion
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/xmlvalidator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      XML Validator
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/reflected-userinfo-xpath"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(XPath)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/userpoll"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/reflected-register"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register User
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/backbutton"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Those "Back" Buttons
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/stylemutilidae"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Styling with Mutillidae
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/password-generator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Password Generator
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/control-challenge"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Client-side Control Challenge
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Persistent( Second order)</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/view-someone-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Someone's Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/persistent-register"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register User
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/Editprofile"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Edit User Profile
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/weblogs"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Show Log
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>DOM Based</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML 5 Web Storage
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/password-generator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Password Generator
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Cross Site Request Forigen</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/persistent-register"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Register User
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/userpoll"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Poll Question
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Input</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/view-someone-blog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      View Someone's Blog
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/weblogs"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Show Log
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/textfileviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Text File Viewer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      DNS Lookup
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CORS)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/echomessagecsp"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Echo Message(CSP)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/testconnectivityserver"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Test Connectivity to Server
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/via-input-user-info"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(SQL)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/via-input-user-info-xpath"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(XPath)
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Missing HTTPOnly Attribute
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/setbackground"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Set Background Color
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Http Headers</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Browser Info
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/weblogs"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Show Log
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/sitefooter"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Site Footer
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to="/backbutton"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Those "Back" Buttons
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Http Attribute</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/documentviewer"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Document Viewer
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Misconfigration</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to=""
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Missing HTTPOnly Attribute
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Againest HTML5 Web Storage</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/webstorage"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      HTML 5 Web Storage
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Againest JSON</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/pentesttoollookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Pen Test Tool Lookup
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via cookie Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/capturedata"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Captured Data Page
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via XML Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/xmlvalidator"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      XML Validator
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Xpath Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/via-xpath-injection-user-info"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Info(XPath)
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Via Path Relative Style Sheet Injection</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/stylemutilidae"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Styling With Mutilidae
                    </Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Beef FrameWork Targets</b>}
                  drop="end"
                  variant="none"
                >
                  <DropdownItem>
                    <Link
                      to="/addyourblog"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Add to Your Blog
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  A8 - Insecure Deserialization
                </Link>
              </DropdownItem>

              <DropdownButton
                title={<b>A9 - Using components with known Vulenrablites</b>}
                drop="end"
                variant="none"
              >
                <DropdownItem>
                  <Link
                    to="/phpinfopage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    PHP INFO
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/view-user-privilege"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    CBC Bite Flipping
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/ssl"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    SSL Misconfigration
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  A10 - Insufficent logging and Monitoring
                </Link>
              </DropdownItem>
            </DropdownButton>
          </Dropdown>
        </div>

        <div className="sidebar m-1 text-center p-1 col-9 mx-auto">
          <Dropdown>
            <DropdownButton
              drop="end"
              title={
                <b className="p-0 m-0 text-light text-center ">OWASP 2013</b>
              }
              variant="none"
            >
              <DropdownButton
                drop="end"
                title={
                  <b className="p-0 m-0 text-dark text-center ">
                    A8 - Cross Site Request Forgery
                  </b>
                }
                variant="none"
              >
                <DropdownItem>
                  <Link
                    to="/addyourblog"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Add to your blog
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/csrf-register-user"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Register User
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/userpoll"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Poll Question
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                drop="end"
                title={<b>A10 - Unvalided Redirect and Forwards</b>}
                variant="none"
              >
                <DropdownItem>
                  <Link
                    to="/credits"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Credits
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/reset"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Setup/reset the DB(Disabled:Not Admin)
                  </Link>
                </DropdownItem>
              </DropdownButton>
            </DropdownButton>
          </Dropdown>
        </div>

        <div className="sidebar m-1 text-center p-1 col-9  mx-auto">
          <DropdownButton
            drop="end"
            title={
              <b className="p-0 m-0 text-light text-center">OWASP 2010 </b>
            }
            variant="none"
          >
            <DropdownButton
              className="col-6 text-center"
              title={<b>A7 - Insecure Cryptographic Storage</b>}
              variant="none"
              drop="end"
            >
              <DropdownItem>
                <Link
                  to="/insecure-userinfo-xpath"
                  className="text-decoration-none text-dark fw-bold"
                >
                  User Info(SQL)
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/insecure-userinfo-xpath"
                  className="text-decoration-none text-dark fw-bold"
                >
                  User Info(XPath)
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/webstorage"
                  className="text-decoration-none text-dark fw-bold"
                >
                  HTML 5 Web Storage
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/viewuserprivilege"
                  className="text-decoration-none text-dark fw-bold"
                >
                  View User Privilege Level
                </Link>
              </DropdownItem>
            </DropdownButton>

            <DropdownButton
              className="col-6 text-center"
              title={<b>A8 - Failure to Restrict Url access</b>}
              variant="none"
              drop="end"
            >
              <DropdownItem>
                <Link
                  to="/Editprofile"
                  className="text-decoration-none text-dark fw-bold"
                >
                  Edit Profile
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/codeviewer"
                  className="text-decoration-none text-dark fw-bold"
                >
                  Source Viewer
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/secret"
                  className="text-decoration-none text-dark fw-bold"
                >
                  Secret Administrative Pages
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/robots"
                  className="text-decoration-none text-dark fw-bold"
                >
                  Robots.txt
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/arbiteraryfileinclusion"
                  className="text-decoration-none text-dark fw-bold"
                >
                  Arbitrary File Inclusion
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/phpinfopage"
                  className="text-decoration-none text-dark fw-bold"
                >
                  PHP Info Page
                </Link>
              </DropdownItem>
            </DropdownButton>

            <DropdownButton
              className="col-6 text-center"
              title={<b>A9 - Insuficent Transport Layer Protection</b>}
              variant="none"
              drop="end"
            >
              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  SSL Misconfiguration
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/insufficient-login"
                  className="text-decoration-none text-dark fw-bold"
                >
                  Login
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/insufficient-user-info"
                  className="text-decoration-none text-dark fw-bold"
                >
                  User Info(SQL)
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/insufficient-user-info-xpath"
                  className="text-decoration-none text-dark fw-bold"
                >
                  User Info(XPath)
                </Link>
              </DropdownItem>
            </DropdownButton>
          </DropdownButton>
        </div>

        <div className="sidebar m-1 text-center p-1 col-9 mx-auto ">
          <Dropdown>
            <DropdownButton
              title={
                <b className="p-0 m-0 text-light text-center">OWASP 2007</b>
              }
              drop="end"
              variant="none"
            >
              <DropdownButton
                title={<b>A3 - Sensitive Data Exposure</b>}
                variant="none"
                drop="end"
              >
                <DropdownButton
                  title={<b>Information Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/EditUserProfile">Edit User Profile</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/phpinfopage">PHP Info page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/robots">robots.txt</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/secret">"SECRET" Administartion Page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/webstorage">HTML5 Web Storage</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/client-side-comments">
                      HTML/JavaScript Comments
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/catch-control">Catch-control</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/clickjacking">Click - Jacking</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">Cross-site Framing</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">X-Frame Options (Click - Jacking)</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="">X-Frame Options (Cross Frame -Jacking)</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Application path Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/phpinfopage">PHP Info Page</Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/Robots">Robots.txt</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownButton
                  title={<b>Platform Path Disclosure</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link to="/phpinfopage">Php Info Page</Link>
                  </DropdownItem>
                </DropdownButton>

                <DropdownItem
                  variant="none"
                  drop="end"
                ><Link to="/ssl">SSL Misconfigration</Link></DropdownItem>

                <DropdownButton
                  title={<b>JSON WEB Token (JWT)</b>}
                  variant="none"
                  drop="end"
                ></DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={
                  <b className="p-0 m-0 text-center">
                    A6 - Information leakage
                  </b>
                }
                drop="end"
                variant="none"
              >
                <DropdownItem>
                  <Link
                    to="/Editprofile"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Edit User Profile
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/catch-control"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Catche Control
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to=""
                    className="text-decoration-none text-dark fw-bold"
                  >
                    X-Powered-By-HTTP Header
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/client-side-comments"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    HTML/Javascript Comments
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/clickjacking"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Click Jacking
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/thirdparty-farming"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Cross-Site Framing (Third-Party Framing)
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/webstorage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    HTML 5 Web Storage
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/phpinfopage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    PHP Info Page
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="robots"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Robots.txt
                  </Link>
                </DropdownItem>

                <DropdownItem>
                  <Link
                    to="/ssl"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    SSL Misconfiguration
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={
                  <b className="p-0 m-0 text-center">
                    A6 - Improper Error Handiling
                  </b>
                }
                drop="end"
                variant="none"
              >
                <DropdownItem>
                  <Link
                    to="/improper-userinfo"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    User Info(SQL)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/improper-userinfo-xpath"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    User Info(XPath)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/improper-login"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Login
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/improper-register"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Register
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/editprofile"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Edit User Profile
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/pentesttoollookup"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Pen Test Tool Lookup
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/improper-lookupuser"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Pen Test Tool Lookup(AJAX)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/userlookup"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lookup User(Soap Web Service)
                  </Link>
                </DropdownItem>
              </DropdownButton>
            </DropdownButton>
          </Dropdown>
        </div>

        <div className="sidebar m-1 text-center p-1 col-9 mx-auto">
          <Dropdown>
            <DropdownButton
              title={
                <b className="p-0 m-0 text-light text-center">WEB Services</b>
              }
              variant="none"
              drop="end"
            >
              <DropdownButton
                title={<b className="p-0 m-0 text-center">SOAP</b>}
                variant="none"
                drop="end"
              >
                <DropdownButton
                  title={<b className="p-0 m-0 text-center">Test Page</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/helloworld"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Hello World
                    </Link>
                  </DropdownItem>
                </DropdownButton>
                <DropdownButton
                  title={
                    <b className="p-0 m-0 text-center">Command Injection</b>
                  }
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/dnslookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      DNS Lookup
                    </Link>
                  </DropdownItem>
                </DropdownButton>
                <DropdownButton
                  title={<b className="p-0 m-0 text-center">SQL Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/userlookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Lookup User
                    </Link>
                  </DropdownItem>
                </DropdownButton>
                <DropdownButton
                  title={
                    <b className="p-0 m-0 text-center">Username Enumeration</b>
                  }
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/userlookup"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      Lookup User
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>

              <DropdownButton
                title={<b className="p-0 m-0 text-center">REST</b>}
                variant="none"
                drop="end"
              >
                <DropdownButton
                  title={<b className="p-0 m-0 text-center">SQL Injection</b>}
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/user-account-management"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Account Management
                    </Link>
                  </DropdownItem>
                </DropdownButton>
                <DropdownButton
                  title={
                    <b className="p-0 m-0 text-center">Username Enumeration</b>
                  }
                  variant="none"
                  drop="end"
                >
                  <DropdownItem>
                    <Link
                      to="/useraccountmanagement"
                      className="text-decoration-none text-dark fw-bold"
                    >
                      User Account Management
                    </Link>
                  </DropdownItem>
                </DropdownButton>
              </DropdownButton>
            </DropdownButton>
          </Dropdown>
        </div>

        <div className="sidebar m-1 text-center p-1 col-9 mx-auto">
          <Dropdown>
            <DropdownButton
              title={<b className="p-0 m-0 text-light text-center">Others</b>}
              drop="end"
              variant="none"
            >
              <DropdownButton
                className="col-6"
                title={
                  <b>
                    Cross Origen <br /> Resource Sharing (CORS)
                  </b>
                }
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/echomessage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Echo Message(CORS)
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Clint Side "Security" Controls</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/control-challenge"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Client-side Control Challenge
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Cross-Site Framing</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/clickjacking"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Click-Jacking
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/thirdparty-farming"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Cross-Site Framing(Third-Party Framing)
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Tab- Nabbing</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to=""
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Tabby Cat
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Unrestricted File Upload</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/fileupload"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    File Upload
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Denail of Service</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/textfileviewer"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Text File Viewer
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/weblogs"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Show Web Log
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>JavaScript Validation Bypass</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/login"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Login
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/userinfo"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    User Info(SQL)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/userinfo"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    User Info(XPath)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/addyourblog"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Add to Your Blog
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/webstorage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    HTML5 Web Stoage
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/dnslookup"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    DNS Lookup
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/echomessage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Echo Message
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/echomessage"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Echo Message(CORS)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/echomessagecsp"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Echo Message(CSP)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/repeater"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Repeater
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/testconnectivityserver"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Test Connectivity to Server
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>User-Agent Impersonation</b>}
                variant="none"
                drop="end"
              ></DropdownButton>

              <DropdownButton
                title={<b>Data Capture Pages</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/capturedata"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Data Capture
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/capturedata"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    View Captured Data
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Json Web Token(JWT)</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/CurrentUserInformation"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Current User Information
                  </Link>
                </DropdownItem>
              </DropdownButton>
            </DropdownButton>
          </Dropdown>
        </div>

        <div className="sidebar m-1 text-center p-1 col-9 mx-auto">
          <Dropdown>
            <DropdownButton
              title={<b className="p-0 m-0 text-light text-center">Labs</b>}
              variant="none"
              drop="end"
            >
              <DropdownButton
                title={<b>How Do Web Works </b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab1"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab1-Sending Http Requests with Netcat
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab2"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab1-Capturing Http Packets with whireshark
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab3"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab3-Intercepting HTTP Requests and Responses
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab4"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab4-Using Burp-Suite Proxy History
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab5"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab5-Bypassing Client-side Security
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>SQL Injection</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab6"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab6-Testing for SQL Errors
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab7"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab7-Using SQLi to Bypass Authentication
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab8"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab8-Extracting data
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab9"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab9- Finding Number of Columns
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab10"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab10-Pivoting with SQL injection
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab11"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab11-SQLMap
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Comand Injection</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab12"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-12 Extracting User Accounts with Command Injection
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab13"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-13 Testing for Reverse Connection
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab14"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-14 Extracting User Accounts with Command Injection
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab15"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-15 Extracting User Accounts with Command Injection
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab16"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-16 Extracting User Accounts with Command Injection
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={
                  <b>LightWeight Directory Access Protocol (LDAP) Injection</b>
                }
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab17"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-17 Extract User Accounts with LDAP injection
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Insecure Direct Object Reference (IDOR)</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab18"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-18 Edit Another User's Profile
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab19"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-19 Extracting User Accounts with IDOR
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab20"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-20 Extracting User Accounts with Local File
                    Inclusion(LFI)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab21"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-21 Web Shell with Local File Inclusion(LFI)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab22"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-22 Web Shell with Remote File Inclusion (RFI)
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Open Redirect</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab23"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-23 Open Redirects - Part 1
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab24"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-24 Open Redirects - Part 2
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Cross Site Scripting XSS</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab25"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-25 Creating a Cross-site Script Proof of Concept (PoC)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab26"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-26 Cross-Site Scripting - Browser Exploitation
                    Framework(BeEF)
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab27"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-27 Cross-Site Scripting - Bypassing Client-side Defenses
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab28"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-28 Cross-Site Scripting - Defending Against Cross-site
                    Scripting
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Cross Site Request Forgey</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab29"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-29 Voting for NMap
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab30"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-30 Adding a Blog
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>HTML5 Web Storage</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab31"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-31 Capturing HTML 5 Web Storage
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Session Management</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab32"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-32 Capture Session with XSS
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab33"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-33 Intercepting Session Token using Network Sniffer
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab34"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-34 Exfiltrating Data with XSS
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab35"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-35 Capture Session with XSS
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab36"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-36 Using a captured session token
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Cookie Management</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab37"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-37 Show Hints
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab38"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-38 Privilege Escalation
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Password Management</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab39"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-39 Password cracking
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab40"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-40 Intercepting Clear-Text Credentials
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab41"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-41 Hydra
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Input Validation</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab42"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-42 Input Validation
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab43"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-43 File Identification
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Error Handiling</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab44"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-44 Grabbing Server Banners
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab45"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-45 Creating Injection Files
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab46"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-46 Scanning with Nikto
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab47"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-47 Testing for Errors
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton title={<b>Loggin</b>} variant="none" drop="end">
                <DropdownItem>
                  <Link
                    to="/lab48"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-48 Log Poisoning
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab49"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-49 Log Disclosure
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Server Configration</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab50"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-50 Testing HTTP Response Headers
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab51"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-51 Scanning with Nikto
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab52"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-52 Scanning with SSLScan
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Content Security Policy</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab53"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-53 Testing HTTP Response Headers
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab54"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-54 Scanning with SSLScan
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab55"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-55 Testing Content Security Policy (CSP)
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Json Web Token Security</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab56"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-56 Session Timeout
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab57"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-57 Missing Signature Validation
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab58"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-58 Cracking Signature Password
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab59"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-59 SQL Injection via JWT
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Cross Origin Resource Sharing</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab60"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-60 Access Control Allow Origin Header
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab61"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-61 Pre-Flight Requests
                  </Link>
                </DropdownItem>
              </DropdownButton>

              <DropdownButton
                title={<b>Software Composition Analysis</b>}
                variant="none"
                drop="end"
              >
                <DropdownItem>
                  <Link
                    to="/lab62"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-62 Retire.js
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/lab63"
                    className="text-decoration-none text-dark fw-bold"
                  >
                    Lab-63 OWASP Dependency Check
                  </Link>
                </DropdownItem>
              </DropdownButton>
            </DropdownButton>
          </Dropdown>
        </div>

        <div className="sidebar m-1 text-center p-1 col-9 mx-auto">
          <Dropdown>
            <DropdownButton
              title={
                <b className="p-0 m-0 text-light text-center">Documentaion</b>
              }
              variant="none"
              drop="end"
            >
              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  Installation Instruction (Linux)
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/UsageInstruction"
                  className="text-decoration-none text-dark fw-bold"
                >
                  Usage Instructions
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/Vulnerabilities"
                  className="text-decoration-none text-dark fw-bold"
                >
                  List Of Vulnerablitys
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/credits"
                  className="text-decoration-none text-dark fw-bold"
                >
                  Credits
                </Link>
              </DropdownItem>
            </DropdownButton>
          </Dropdown>
        </div>

        <div className="sidebar m-1 text-center p-1 col-9 mx-auto ">
          <Dropdown>
            <DropdownButton
              title={
                <b className="p-0 m-0 text-light text-center">Resources</b>
              }
              variant="none"
              drop="end"
            >
              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  Latest Version of Mutilidae 2
                </Link>
              </DropdownItem>

              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  OWASP Top Ten
                </Link>
              </DropdownItem>

              <DropdownItem>
                <Link to="" className="text-decoration-none text-dark fw-bold">
                  WebPenetration Testing Addons
                </Link>
              </DropdownItem>
            </DropdownButton>
          </Dropdown>
        </div>

        <div className="mx-auto text-center mt-4">
          <div className="btn btn-warning rounded-5 ">
            <b>Donate</b>
          </div>
          <b className="p-0 m-0 d-block">
            {" "}
            <a href="" className="text-decoration-none">
              Want to Help?
            </a>{" "}
          </b>
        </div>

        <div className="mx-auto text-center mt-4">
          <div>
            <YouTubeIcon className="text-danger fs-1" />
            <b className="p-0 m-0 d-block">
              <a href="" className=" text-decoration-none ">
                Video Tutorial
              </a>
            </b>
          </div>
        </div>

        <div className="mx-auto text-center mt-4">
          <div>
            <CampaignIcon className="text-primary fs-1" />
            <b className="p-0 m-0 d-block">
              <a href="" className=" text-decoration-none ">
                Announcements
              </a>
            </b>
          </div>
        </div>

        <div className="mx-auto text-center mt-4">
          <div>
            <PictureAsPdfIcon className="text-danger fs-1" />
            <b className="p-0 m-0 d-block">
              <a href="" className=" text-decoration-none ">
                Geting Started
              </a>
            </b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
