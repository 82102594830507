import React from 'react';
import { Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const Test = () => {
  const description = `Nmap ("Network Mapper") is a free and open-source utility for network discovery and security auditing.

`;

  const truncatedDescription = truncateText(description, 100);

  return (
    <Card style={{ width: '18rem', margin: '1rem auto', textAlign: 'center' }}>
      <Card.Img variant="top" src="https://indianhackerslab.com/tools-images/Nmap.png" />
      <Card.Body>
        <Card.Title>Nmap</Card.Title>
        <Card.Text>
         <div className='d-flex justify-content-between'>
            <div className='d-flex'>
         <strong className='me-1'>Version: </strong>
         <p> 7.94</p>
            </div>
            <div className='d-flex'>
         <strong className='me-1'>Size: </strong>
         <p> 7.94</p>
            </div>
         </div>
        </Card.Text>
        <Card.Text className='text-dark'> 
          {truncatedDescription}
        </Card.Text>
        <Button variant="primary">View Details</Button>
      </Card.Body>
    </Card>
  );
};

export default Test;
