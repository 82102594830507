import axios from 'axios';
import React, { useState } from 'react';

 import logo from '../assets/images/logo2.png'


const Header = ({type}) => {
const [searchType,setSearchType]=useState(null);
const[search,setSearch]=useState(null);

  console.log(searchType);
  
  
    const GetSearchDetails = async()=>{
      const data=new FormData()
      if(searchType==='lab'){
        data.append('type',searchType)
      }
      data.append('title',search);
      const res= await axios.post("https://globalhackersunited.com/ghu-lab.php",data,{ headers:{'content-type':'multipart/form-data'}})

       if(res.data){
        console.log(res);
       }
       
      
  }
 

    
  

 
  return (
    <div className=''>
      <div className='col-12 bg-dark p-3'>
        <div className='d-flex container justify-content-between'>


          <div className=' col-6 d-flex justify-content-between'>
            <div className='col-3 me-4 p-2 my-auto'>
             <img className='w-100' style={{height:30}} src={logo} />
            </div>

            <div className='d-flex col-12 justify-content-between'>
              <a href='/' className=' my-auto text-light'>Home</a>

              <a href='/our-hackers' className='my-auto text-light'>Our Hackers</a>

              <a href='/lab-home' className='my-auto text-light'>Hacking Labs</a>

              <a href='/tools' className='my-auto text-light'>Hacking Tools</a>

              <a href='/features' className='my-auto text-light'>Features</a>

              <a href='/about' className='my-auto d-none text-light'>About</a>
            </div>
          </div>



          <div className='col-4 d-flex justify-content- ps-3'>
            <div className='col-9 bg-white rounded rounded-3 me-3 d-flex justify-content-between d'>
          
              <input className='form-control' placeholder='search....' onChange={(e)=>setSearch(e.target.value)} ></input>
                    <button className='btn btn-warning rounded-start-0 rounded-end-3' onClick={(e)=>{GetSearchDetails();e.preventDefault()}}>Search</button>
   

                 
            </div>
            <button className='rounded-3  rounded p-2 text-light  border-white' style={{ backgroundColor: "transparent" }}>Login</button>
        
          </div>




        </div>








      </div>
    </div>
  )
}

export default Header