import React, { useState } from 'react';
import './OurHackers.css'
import GroupIcon from '@mui/icons-material/Group';

const OurHackers = () => {


  const[view,setview] = useState(false);
  const open=()=>{
    setview(true)
  }

  return (
    <div>
      <div className=' background'>
        <div className='p-5'> </div>

        <div className=' p-5'> </div>

        <div className='  backcolor'>
          <div className='p-1'></div>
          <div className=" container">
            <h1 className='p-1 size text-light'>ALL TOURANMENT</h1>
          </div>
          <div className='p-1'></div>

        </div>



        <div className="container d-flex flex-wrap">
          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>
        

          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>


          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>

          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>

          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>

          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>

          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>

            <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>

          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>

          <div className=' col-lg-6 col-md-6 col-sm-12 col-12 '>
            <div onMouseOver={()=>setview(true)} onMouseLeave={()=>setview(false)}  className="col-11">
              <hr className=' w-100 text-light' />
              <div className=" p-3 d-block d-lg-flex d-md-flex d-flex">

                  <div className="col-lg-2 col-md-3 col-sm-2 m-0  ">
                    <div className="col-10">
                      <img className=' w-100' src="https://skywarriorthemes.com/arcane/wp-content/uploads/2020/12/lol-85x118.jpg" />
                    </div>
                  </div>


                  <div className=" m-0 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className=' col-10 mx-auto'>
                      <div className='p-0 m-0 d-flex'>
                        <b className=' text-warning p-0 m-0'>5vs5</b>
                        <p className=' p-0 m-0'>⭐</p>
                      </div>
                      <div className='pt-0'>
                        <p className=' text-secondary fs-5 p-0 m-0'>League of Legends</p>
                      </div>
                      <div className=' pt-3 d-flex'>
                        <GroupIcon className='' style={{ color: ' orange' }} />
                        <p className=' text-warning'>0/10 Teams Registered</p>
                      </div>

                    </div>
                  </div>
           

                <div className="col-lg-3 col-md-3 col-sm-3 col-3 ">
                  <small className=' d-block text-secondary'>START DATE:</small>
                  <b className=' fs-6 text-secondary'>2023-11-30 12:00</b>
                </div>


              </div>


           
          <div   className={view?' p-3 border-light border col-12 ':'d-none'}>
            <div className=' d-flex flex-wrap'>
              <div className=' col-6 p-3'>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className=' p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>

              <div className='p-3 col-6 '>
                <div className=' d-flex'> 
                 <img src=""/>
                  <small className=' fs-6 text-secondary'>TOURNAMENT TYPE</small>
                </div>
                <b className=' text-light '>League</b>
              </div>
            </div>
          </div>

            </div>
        
        
          </div>









        </div>


      </div>


    </div>
  )
}

export default OurHackers