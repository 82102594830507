
import React from 'react'

import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab31 = () => {
    return (
        <div>
            <Title title={"Lab 31: Attacking Users with other Techniques - HTML 5 Web Storage - Capturing HTML 5 Web Storage"} />
            <StyledLab31>
                <div className='box1 border border-dark col-5 mx-auto rounded'>
                    <h5 className='text-center p-0 m-0'>Complete the lab on Capturing HTML5 Web Storage. Use a </h5>
                    <h5 className='text-center p-0 m-0'>cross-site script to extract the records from the HTML5</h5>
                    <h5 className='text-center p-0 m-0'>database. How many times did Chuck count to infinity? </h5>
                    
                </div>
                <div className='col-5 mx-auto m-3'>
                    <div className=' d-flex'>

                    <input type="radio" className='m-1'></input>

                        <b className='p-0 m-0'>  once
 </b>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio" className='m-1'></input>

                        <b className='p-0 m-0'> twice</b>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio" className='m-1'></input>

                        <b className='p-0 m-0'>thrice
</b>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio" className='m-1'></input>

                        <b className='p-0 m-0'> four times</b>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio" className='m-1'></input>
                        <b className='p-0 m-0'> five times
                        </b>
                    </div>
                </div>
                <div className=' submit col-1 mx-auto '>
                    <button className='rounded pe-3 ps-3 m-2'>
                        Submit
                    </button>
                </div>
                <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
                    <b> Choose the best answer or view Hints and Videos</b>
                </div>
                <div className='d-flex justify-content-center m-2 fs-5'>
                    <a className='  text-decoration-none p-2 '>
                        Previous
                    </a>
                    <div className='p-2'>|</div>
                    <a className='  text-decoration-none p-2 fs-5 '>
                        Next
                    </a>

                </div>
            </StyledLab31>
        </div>
    )
}

export default Lab31

const StyledLab31 = styled.div
    `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`