import React from 'react'
import styled from 'styled-components';


const HelloWorld = () => {
  return (
    <StyledHelloWorld>
    <div>
        <div className='navbar  text-light mt-5 pt-2 pb-2'>
<h3>hellowsd</h3>
        </div>
        <div className='box-1 w-25 mt-4 ms-4  text-dark p-3'>
<h6>View the <a className='highlight'>WSDL</a>  for the service. Click on an  operation name to view it's details.</h6>
<h6 className='ms-5 mt-4' > <a className='highlight'>hello</a></h6>
        </div>
    </div>
    </StyledHelloWorld>
  )
}

export default HelloWorld

const StyledHelloWorld = styled.div`
.highlight:hover{
  color:red;
}
.navbar{
  background-color:grey
}
.box-1{
  background-color:#ccccff;
}
` 
