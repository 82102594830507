import React from 'react'

const UserDetails = ({data}) => {
  return (
    <div className="container col-6 d-flex justify-content-center mt-5">

   <div className="  card-body">
     <table className="table table-bordered">
       <tbody>
         <tr>
           <th scope="row">User ID</th>
           <td>{data.data.id}</td>
         </tr>
         <tr>
           <th scope="row">User Name</th>
           <td>{data.data.user_name}</td>
         </tr>
         <tr>
           <th scope="row">Signature</th>
           <td>{data.data.signature}</td>
         </tr>
         <tr>
           <th scope="row">Password</th>
           <td>{data.data.password}</td>
         </tr>
       </tbody>
     </table>
   </div>

</div>
  )
}

export default UserDetails
