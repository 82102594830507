import React, { useState } from 'react'
import Endpoint from '../../../../assets/Endpoint';
import axios from 'axios';
import SecurityHeaders from '../../../../assets/SecurityHeaders';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import { ToastContainer } from 'react-bootstrap';
import LoginToast from '../../../../components/LoginToast';
import UserDetails from '../../../../components/UserDetails';
import Title from '../../../Others/Title';

const SqliMapLogin = () => {


  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [data, setData] = useState('')
 
  const Submit = async () => {

    try {
      const data = new FormData()
      data.append('user_name', name);
      data.append('password', password);
      const res = await axios.post(Endpoint + "owasp-2017/A1/sqli-map-login.php", data, SecurityHeaders);

      if (res) {
        if (res.data.status === "success") {
          setData(res.data)
        }

        if (res.data.message) {
          setShow(true)
        }
      }
    } catch (error) {
      console.error("Error occurred during login:", error);
      // Handle the error appropriately, e.g., show a message to the user
    }


  }
  // console.log(data.data);

  return (
    <div className="container  ">



      <Title data={{title:"User Login"}} />

      <div className="d-flex mt-4">
        <div className="d-flex align-items-center">
          <div className="col-2">
            <img
              className="w-100"
              src="http://localhost/images/ajax_logo-75-79.jpg"
              alt="ajax logo"
            />
          </div>
          <h5 className="text-primary ms-3">
            {" "}
            Switch to SOAP Web Service version{" "}
          </h5>{" "}
        </div>

        <div className="d-flex align-items-center ms-5">
          <div className="col-2">
            <img
              className="w-100"
              src="http://localhost/images/xml-logo-64-64.png"
              alt="ajax logo"
            />
          </div>
          <h5 className="text-primary ms-3"> Switch to XPath version</h5>{" "}
        </div>
      </div>
      <div className="col-5 mx-auto mt-4">
        <div className="info-box rounded border-black border">
          <h5 className="text-center">
            Please enter username and password to view account details
          </h5>
        </div>
        <form onSubmit={(e) => { e.preventDefault(); Submit(); }}>
          <div className="d-flex align-items-center mt-4 ">
            <div className="col-3">
              <h6>Name</h6>
            </div>
            <div className="col-7">
              <input
                className="form-control ms-3 border border-black"
                placeholder="user_name" value={name} onChange={(e) => setName(e.target.value)}
                required />
            </div>
          </div>
          <div className="d-flex align-items-center mt-3 ">
            <div className="col-3">
              <h6>Password</h6>
            </div>
            <div className="col-7">
              <input
                className="form-control ms-3 border border-black"
                placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)}
                required />
            </div>
          </div>

          <div className="d-flex justify-content-center col-12 mt-3">
            <button className="btn  btn-primary">view account details</button>
          </div>







          <div>
            <p className="text-center mt-3">Don't have an account?<a className="nav-link" href="#"> Please register here</a></p>
          </div>
        </form>
      </div>

      <div className="col-12 mt-3 mx-auto">


        {data.data ?
          <UserDetails data={data} />
          : null}
        <>
          {
            data.message ? <LoginToast toast={show} /> : null
          }</>

      </div>
    </div>

  )









}

export default SqliMapLogin
