import React from 'react'

const TestConnectivityServer = () => {
  return (
    <>
      <div className="container mt-3">
        <div className="col-lg-6 mx-auto">
          <div className='bg-aqua rounded text-center p-2 border border-dark'>
            <h5>Click the Test Connectivity Button to Test Webservice Connection</h5>
          </div>
          <div className='text-center mt-3'>
            <a href='#' className='btn btn-success w-50 rounded'>Test Connectivity</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestConnectivityServer