import React from 'react'
import Title from '../../../../../components/Title'
import styled from "styled-components"

const RemoteFileInclusion = () => {
  return (
    <div>
        <StyledRemoteFileInculsion>
       <Title title={"Arbitrary File Inclusion"}/>
      <div className='col-5 main p-1 mx-auto border border-1 first border-dark rounded text-center mt-5'>
        <h6 className=''>Remote and Local File Inclusion</h6>


      </div>
      <div className='col-5 mx-auto '>

        <div className='mt-4'>
          <h5>Current Page: arbitrary-file-inclusion.php</h5>
          <h5 className='mt-2'>Notice that the page displayed by Mutillidae is decided by the value in the "page" URL parameter. What could possibly go wrong?</h5>
        </div>

      </div>


      <div className='col-5 mx-auto p-1 background border border-1 first border-dark rounded text-center mt-4'>
        <h6> Local File Inclusion</h6>
      


      </div>
      <p className='col-5 mx-auto text-left mt-4'>PHP runs on an account (like any other user). The account has privileges to the local file system with the ability to read, write, and/or execute files. Ideally the account would only have enough privileges to execute php files in a certain, intended directory but sadly this is often not the case. Local File Inclusion occurs when a file to which the PHP account has access is passed as a parameter to the PHP function "include", "include_once", "require", or "require_once". PHP incorporates the content into the page. If the content happens to be PHP source code, PHP executes the file.</p>
        
      <div className='col-5 p-1 mx-auto border border-1 background first border-dark rounded text-center mt-4'>
        <h6> Remote File Inclusion</h6>
      


      </div>
      
    <p className='col-5 mx-auto text-left mt-4'>Remote File Inclusion occurs when the URI of a file located on a different server is passed to as a parameter to the PHP function "include", "include_once", "require", or "require_once". PHP incorporates the content into the page. If the content happens to be PHP source code, PHP executes the file.</p>
     
    <div className='col-5 note  rounded-1 mx-auto border border-1 first border-dark p-1 mt-4'>
        <h6 className='col-9 mx-auto'>Note that on newer PHP servers the configuration parameters "allow_url_fopen" and "allow_url_include" must be set to "On".</h6>
      


      </div>
      <p className='col-5 mx-auto text-left mt-4'>By default, these may or may not be "On" in newer versions. For example, by default in XAMPP 1.8.1, "allow_url_fopen = On" by default but "allow_url_include = Off" by default. If you wish to try remote file inclusion, be sure these configuration parameters are set to "On" by going to the php.ini file, locating the parameters, setting their value to "On", and restarting the Apache service. An example of this configuration appears below.</p>
    
      <pre className='col-5 mx-auto text-danger'>  <b>{`
;;;;;;;;;;;;;;;;;;
;Fopen wrappers;
;;;;;;;;;;;;;;;;;;

;Whether to allow the treatment of URLs (like http:// or ftp://) as files.
;http://php.net/allow-url-fopen
allow_url_fopen=On

;Whether to allow include/require to open URLs (like http:// or ftp://) as files.
;http://php.net/allow-url-include
allow_url_include=On`}</b></pre>
    
    
    </StyledRemoteFileInculsion>

    </div>
  )
}

export default RemoteFileInclusion
const StyledRemoteFileInculsion = styled.div
    `.main{background-color:#bc6c2550;}
    .background{background-color:#dddddd;}
    .note{background-color:#ccccff;}


    `