import React from 'react'

const DocumentViewer = () => {
  return (
    <>
    <div className="container">
        <div className='relative mt-5'>
            <div className='document p-2'>
                  <h6>Document Viewer</h6>
            </div>
            <div className='col-lg-6 mx-auto mt-5'>
                <div className='text-center  background p-2 rounded'>
                <h5 className='fw-bold'>Please Choose Document to View</h5>
                </div>
                <div className='p-2'>
                <div className='d-flex align-items-center'>
                    <input type='radio' /><p className='m-0 p-0 ms-2'>Change Log</p>
                </div>
                <div className='d-flex align-items-center'>
                    <input type='radio' /><p className='m-0 p-0 ms-2'>Robots.txt</p>
                </div>
                <div className='d-flex align-items-center'>
                    <input type='radio' /><p className='m-0 p-0 ms-2'>Installation Instructions: Windows 7 (PDF)</p>
                </div>
                <div className='d-flex align-items-center'>
                    <input type='radio' /><p className='m-0 p-0 ms-2'>How to access Mutillidae over Virtual-Box-network</p>
                </div>
                <div className='text-center mt-3'>
                    <a href='#' className='btn btn-primary w-50 rounded'>View Document</a>
                    <h6 className='mt-3'>Currently viewing document "documentation/change-log.txt"</h6>
                </div>
                </div>
            </div>  
        </div>
    </div>
    </>
  )
}

export default DocumentViewer