import React from "react";

const PasswordGenerator = () => {
  return (
    

    <div>

      <div className=" border border-1 border-black title rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">Password Generator</p>
      </div>

      <div>
        <p className="mx-auto fw-semibold text-center">
          Making strong passwords is important. Click the button below to
          generate a password.
        </p>

        <p className="mx-auto fw-semibold text-center">
          This password is for anonymous
        </p>
      </div>

      <div className=" mt-5 text-center">
        <div className="mx-auto btn buttoncolor border border-1 border-black m-0 p-1 ">
          <b className="m-0 p-0 text-dark">Generate Password</b>
        </div>
      </div>

    </div>


  );
};

export default PasswordGenerator;
