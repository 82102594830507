import axios from 'axios'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom/dist'

const Edit = () => {
    const params = useParams()
    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [monthlyprice, setMonthlyprice] = useState()
    const [yearlyprice, setYearlyprice] = useState()
    const [features, setFeatures] = useState()
    const [recommended, setRecommended] = useState()

    console.log(params.id)
    const getedit = async () => {
        const data = new FormData()
        data.append('id', params.id);
        data.append('title', title);
        data.append('description', description);
        data.append('monthly_price', monthlyprice);
        data.append('yearly_price', yearlyprice);
        data.append('features', features);
        data.append('recommended', recommended);

        const response = await axios.post("https://globalhackersunited.com/displayedit.php", data, { headers: { 'content-type': 'multipart/form-data' } })

        if (response) {
            if (response.data.status === "success") {

                console.log(response.data)

            }
        }

    }

    return (
        <>
            <form>
                <div className='col-lg-3 mx-auto shadow bg-light rounded rounded-3 mt-3 mb-3'>
                    <div className='p-1'>
                        <label className='fw-bold'>title:</label><br />
                        <input onChange={(event) => { setTitle(event.target.value) }} className='form-control' type='text' placeholder='edit title' required></input>
                    </div>
                    <div className='p-1'>
                        <label className='fw-bold'>description:</label><br />
                        <input onChange={(event) => { setDescription(event.target.value) }} className='form-control' type='text' placeholder='edit description' required></input>
                    </div>
                    <div className='p-1'>
                        <label className='fw-bold'>monthly price:</label><br />
                        <input onChange={(event) => { setMonthlyprice(event.target.value) }} className='form-control' type='text' placeholder='edit price' required></input>
                    </div>
                    <div className='p-1'>
                        <label className='fw-bold'>yearly price:</label><br />
                        <input onChange={(event) => { setYearlyprice(event.target.value) }} className='form-control' type='text' placeholder='edit price' required></input>
                    </div>
                    <div className='p-1'>
                        <label className='fw-bold'>features:</label><br />
                        <input onChange={(event) => { setFeatures(event.target.value) }} className='form-control' type='text' placeholder='edit features' required></input>
                    </div>
                    <div className='p-1'>
                        <label className='fw-bold'>recommended:</label><br />
                        <input onChange={(event) => { setRecommended(event.target.value) }} className='form-control' type='number' placeholder='edit value' required></input>
                    </div>
                    <div className='p-1 text-center'>
                        <button type='button' className='btn btn-success' onClick={getedit}>submit</button>
                    </div>
                </div>
            </form>

        </>
    )
}

export default Edit