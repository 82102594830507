import React from 'react'
import Title from '../../../components/Title'

const SSLMisconfiguration = () => {
    return (
        <div>
            
            <Title title={"SSL Misconfiguration"}/>



            <div className='border rounded-2 border-dark mt-5 col-6 mx-auto'>
                <h3 className='text-center ps-5 pe-5'>SSL Misconfiguration</h3>


            </div>
            <div className='mx-auto col-6 mt-3'>
                <p>Some web servers which require SSL to secure transmissions are misconfigured to allow users to browse over HTTP. The application may use redirection code to redirect users from HTTP to HTTPS. Mutillidae uses the following code in index.php.</p>

            </div>
            <pre className='col-6 mx-auto'>  <b>{` if($_SERVER['HTTPS']!="on"){
	 "https://".$_SERVER['HTTP_HOST'].$_SERVER['REQUEST_URI'];
("Location: $lSecureRedirect 	
exit();
}//end if");
	
`}</b></pre> <div className='col-6 mx-auto m-0 p-0'>
                <p className=''>If a mallicious agent is able to set up a MITM connection in between the user browser and the web server, a program such as SSLStrip can detect the redirection from HTTP to HTTPS and downgrade the users connection.</p>
                <p>Besides redirecting users from HTTP to HTTPS, other misconfigurations include using weak ciphers or using vulnerable, unpatched software (i.e. Heartbleed). Part of testing web application security is testing for misconfigured HTTPS.
                </p>
                <p>Open "Hints and Videos" for more information</p>
                </div>


          <h6 className='mt-5 pt-5  text-center  justify-content-around col-12 '>
              <b> Browser: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36 Edg/122.0.0.0
                </b> </h6>
                <h6 className='text-center'> <b>PHP Version: 8.2.12</b> </h6>
             
           





        </div>
    )
}

export default SSLMisconfiguration