import React from "react";
import Tittle from "../../../components/Title";

const OWASPDependencyCheck63 = () => {
  return (
    <div className="col-12 container ">
      <div>
        <Tittle tittle={"Lab 63: Software Composition Analysis - OWASP Dependency Check"} />
      </div>
      <table className="col-12">
        <tbody>
          <tr>
            <td />
          </tr>
          <tr>
            <td className="form-header mt-3">
              Referring to the Summary section of the OWASP Dependency Check
              report that shows an additional vulnerable jQuery package that was
              not flagged by Retire.js, why is OWASP Dependency Check
              potentially able to find more issues than Retire.js?{" "}
            </td>
          </tr>
          <tr>
            <td />
          </tr>
          <tr>
            <td>
              <input type="radio" name="answer" id={1} defaultValue={1} />
              <label className="h6 ms-2" htmlFor={1}>
                OWASP Dependency Check scans all the application source code.
                Retire.js only has access to the packages loaded by the client.
              </label>
              <br />
              <input type="radio" name="answer" id={2} defaultValue={2} />
              <label className="h6 ms-2" htmlFor={2}>
                Retire.js is not good at its intended job
              </label>
              <br />
              <input type="radio" name="answer" id={3} defaultValue={3} />
              <label className="h6 ms-2" htmlFor={3}>
                OWASP Dependency Check taps into social media to crowd source
                vulnerabilities
              </label>
              <br />
              <input type="radio" name="answer" id={4} defaultValue={4} />
              <label className="h6 ms-2" htmlFor={4}>
                Retire.js cannot detect issues in jQuery libraries used in web
                pages
              </label>
              <br />
              <input type="radio" name="answer" id={5} defaultValue={5} />
              <label className="h6 ms-2" htmlFor={5}>
                OWASP Dependency Check is a direct descendant of Chuck Norris,
                so is clairvoyant
              </label>
              <br />
            </td>
          </tr>
          <tr>
            <td />
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <input
                name="submit-button"
                className="button"
                type="submit"
                defaultValue="Submit"
              />
            </td>
          </tr>
          <tr>
            <td />
          </tr>
        </tbody>
      </table>
      <Tittle tittle={"Choose the best answer or view Hints and Videos"} />
      <div className=" text-center mt-4">
        <a
          className="h6 text-decoration-none text-primary"
          href="index.php?page=labs/lab-61.php"
        >
          Previous Lab
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a
          className="h6 text-decoration-none text-primary"
          href="index.php?page=labs/lab-63.php"
        >
          Next Lab
        </a>
      </div>
    </div>
  );
};

export default OWASPDependencyCheck63;
