import React from 'react'
import DownloadSection from '../../../components/DownloadSection';
import Title from '../../../components/Title';


const EchoMessage = (props) => {
    return (
        <div>

            <section className='container-fluid'>

                <Title title='Cross-Origan Resource Searching (CORS)' />
            

                <div className='col-lg-4 m-4'>
                    <DownloadSection />
                </div>

                <div className='col-lg-6 d-flex justify-content-between align-items-center'>
                    <a href="">
                        <img src={props.path} alt="" />
                        <b>{props.text} </b>
                    </a>
                    <a href="">
                        <img src={props.path1} alt="" />
                        <b>{props.text1} </b>
                    </a>

                </div>
                <div className='col-lg-6 mx-auto'>
                    <div className='col-lg-9 mx-auto'>
                        <div className='col-lg-12 bg-warning border border-dark rounded text-center'>
                            <h6>Enter message to echo</h6>
                        </div>
                        <div className='col-lg-12  p-3 '>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Message</b></p>
                               <div className=' col-6'>
                               <input type="text" className='rounded' />
                               </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between py-1'>
                                <p><b>HTTP Method</b></p>
                                <div className=' col-6'>
                                    <div className='d-flex align-items-center'>
                                        <input type="radio" checked />
                                        <p className='m-0 ps-2'>GET</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <input type="radio" />
                                        <p className='m-0 ps-2'>POST</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <input type="radio" />
                                        <p className='m-0 ps-2'>PUT</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <input type="radio" />
                                        <p className='m-0 ps-2'>PATCH</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <input type="radio" />
                                        <p className='m-0 ps-2'>DELETE</p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between py-1'>
                                <p><b>Response Headers to Send</b></p>
                                <div className=' col-6'>
                                    <div className='d-flex align-items-center'>
                                        <input type="checkbox" checked/>
                                        <p className='m-0 ps-2'>Access-Control-Allow-Origin</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <input type="checkbox" checked/>
                                        <p className='m-0 ps-2'>Access-Control-Allow-Methods</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <input type="checkbox" checked/>
                                        <p className='m-0 ps-2'>Access-Control-Max-Age</p>
                                    </div>

                                </div>
                            </div>
                            <div className='text-center'>
                            <button className='btn btn-outline-secondary py-0 px-3 '>Echo Message</button>
                            </div>
                        </div>
                    </div>

                </div>


            </section>

        </div>
    )
}

export default EchoMessage