import React from 'react'
import User from '../../components/User'

const InstallationLinux = () => {
  return (
    <div className='container'>
      <>
        {/* Begin Content */}
        <div>
          <User user={"Installation Instruction"}/>
        </div>
        
        <br />
        <br />
        <h5 className="user col-lg-1 text-center rounded-2">Overview</h5>
        <br />
        <br />
        <img
          alt="YouTube"
          src="http://localhost/images/youtube-play-icon-40-40.png"
          style={{ marginRight: 10 }}
        />
        Several videos provide comprehensive, step-by-step instructions
        <br />
        <br />
        <a href="https://www.youtube.com/watch?v=Cazzls2sZVk" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">
            How to Create Ubuntu Virtual Machine (VirtualBox)
          </span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=8VCeFRwRmRU" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">
            How to Install VirtualBox Guest Additions (Linux)
          </span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=33CAgRKztqU" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">How to Create Ubuntu Virtual Machine (VMware)</span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=cZtkVOHRYts" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">
            LAMP Stack: Part 1 - How to Install Apache Web Server
          </span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=Z6IhGGxJidM" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">LAMP Stack: Part 2 - How to Install PHP</span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=PsfuaRySts4" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">
            LAMP Stack: Part 3 - How to Install MySQL Server
          </span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=TcUOaeL5SJU" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">How to Install PHP Curl Library</span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=e37RtQEnUhU" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">How to Install PHP XML Library</span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=ZNAmmiEQuCM" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">How to Install PHP mbstring Library</span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=0wIUci7s3gM" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">How to Display Errors in PHP Pages</span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=TcgeRab7ayM" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">How to Install Mutillidae on Linux</span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=sJd0ir9-jSc" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">
            How to Create Self-Signed Certificate in Apache
          </span>
        </a>
        <br />
        <a href="https://www.youtube.com/watch?v=79mOiU3GfnQ" target="_blank" className='text-decoration-none'>
          <img
            style={{ marginRight: 5 }}
            src="http://localhost/images/youtube-play-icon-40-40.png"
            alt="YouTube"
          />
          <span className="label">How to Create Virtual Hosts in Apache</span>
        </a>
        <br />
        <br />
        <br />
        <h5 className="user border border-2 rounded-2 col-lg-3">Other Installation Options</h5>
        <br />
        <br />
        <div style={{ margin: 20 }}>
          <h5 className="label">Samurai Web Testing Framework</h5>
          <div style={{ margin: 20 }}>
            Samurai WTF is a free virtual environment. Within Samurai is several
            vulnerable web applications pre-configured to test for vulnerabilities.
            One of the applications is Mutillidae.
          </div>
          <h5 className="label">XAMPP (Windows , Linux , Mac OS X )</h5>
          <div style={{ margin: 20 }}>
            1. XAMPP is a single installation package which bundles Apache web server,
            PHP application server, and MySQL database. XAMPP installs Apache and
            MySQL as either an executable or services and can optionally start these
            services automatically. Once installed XAMPP provides an "htdocs"
            directory. This directory is "root" meaning that if you browse to
            http://localhost/, the web site in that "htdocs" folder is what will be
            served. Mutillidae is installed by placing the multillidae folder into the
            htdocs folder. The result is that mutillidae is a sub-site served from the
            mutillidae folder. This makes the URL for mutillidae
            http://localhost/mutillidae.
          </div>
          <div style={{ margin: 20 }}>
            The mutillidae files are already in a folder called "mutillidae" when the
            project is zipped. All that is required is to put the mutillidae folder
            into the htdocs directory.
          </div>
          <div style={{ margin: 20 }}>
            The Mutillidae package can be unzipped into htdocs to install Mutillidae.
            Simply unzip the compressed mutillidae folder right into the htdocs
            folder. When you are done, the "mutillidae" folder will be inside the
            "htdocs" folder of XAMMP. All the Mutillidae files are inside that
            "mutillidae" fodler. Assuming Apache and MySQL are running, the user can
            open a browser and immediately begin using Mutillidae at
            http://localhost/mutillidae. Apache automatically serves "index.php" which
            is located in the mutillidae folder.
          </div>
          <div style={{ margin: 20 }}>
            2. Download and install "XAMPP" or "XAMPP Lite" for Windows or Linux. If
            installing on Windows, when the installation asks if you want to install
            Apache and MySQL as services, answer "YES". This allows both to run as
            Windows services and be controlled via services.msc. Run services.msc by
            typing "services.msc" at the command line. (Start - Run - services.msc -
            Enter)
          </div>
          <div style={{ margin: 20 }}>3. Download Mutillidae</div>
          <div style={{ margin: 20 }}>
            4. Unzip Mutillidae. Note the mutillidae project is in a folder called
            "mutillidae"
          </div>
          <div style={{ margin: 20 }}>
            5. Place the entire "mutillidae" directory into XAMPP " htdocs" directory
          </div>
          <div style={{ margin: 20 }}>
            6. Browse to mutillidae at http://localhost/mutillidae
          </div>
          <div style={{ margin: 20 }}>
            7. Click the "Setup/reset the DB" link in the main menu.
          </div>
          <div style={{ margin: 20 }}>
            Important note: If you use XAMPP Lite or various version of XAMPP on
            various operating systems, the path for your php.ini file may vary. You
            may even have multiple php.ini files in which case try to modify the one
            in the Apache directory first, then the one in the PHP file if that doesnt
            do the trick.
          </div>
          <div style={{ margin: 20 }}>
            Windows possible default location C:\xampp\php\php.ini,
            C:\XamppLite\PHP\php.ini, others Linux possible default locations:
            /XamppLite/PHP/php.ini, /XamppLite/apache/bin/php.ini, others
          </div>
          <div style={{ margin: 20 }}>
            8. By default, Mutillidae tries to connect to MySQL on the localhost with
            the username "root" and a password of "mutillidae". To change this, edit
            "includes/database-config.php" with the correct information for your
            environment.
          </div>
          <div style={{ margin: 20 }}>
            9. NOTE: Once PHP 6.0 arrives in XAMPP, E_ALL will include E_STRICT so the
            line to change will probably read "error_reporting = E_ALL". In any case,
            change the error_reporting line to "error_reporting = E_ALL &amp;
            ~E_NOTICE &amp; ~E_DEPRECIATED".
          </div>
          <div style={{ margin: 20 }}>
            10. NOTE: Be sure magic quotes is disabled. In XAMMP it seems to be but
            using MMAP for Apple OS/X seems to have it enabled by default. Just make
            sure magic quotes is set to off in whatever framework is being used. This
            setting is in PHP.ini. This includes magic_quotes_gpc,
            magic_quotes_runtime, and magic_quotes_sybase.
          </div>
          <h5 className="label">Custom Linux ISO</h5>
          <div style={{ margin: 20 }}>
            Using the Samurai Web Testing Framework as the base operating system, any
            version of Mutillidae can be installed in addition to the version which
            comes standard with Samurai. From this custom set-up, a custom ISO can be
            generated using the Remastersys package.
          </div>
          <div style={{ margin: 20 }}>
            With Samurai, Mutillidae is installed into the /srv/mutillidae directory.
            To install different versions of Mutillidae and make a custom Linux ISO,
            the following recipe can be followed:
          </div>
          <div style={{ margin: 20 }}>
            1. Locate the default installation directory of Mutillidae
            <br />
            2. Rename the current installation. For example rename the "mutillidae"
            folder to "mutillidae.bak".
            <br />
            3. Download the latest version of mutillidae
            <br />
            4. Unzip the "mutillidae" folder from the latest version to the
            installation directory.
            <br />
            5. Test that mutillidae is updated by browsing to
            http://localhost/mutillidae
            <br />
            6. Make any changes to Linux, Firefox, or other software desired
            <br />
            7. Ensure the current Remastersys installation is clean by running the
            command "sudo remastersys clean"
            <br />
            8. When ready to create the new ISO, run the command "sudo remastersys
            backup"
            <br />
            9. The custom ISO will be found in the /home/remastersys/remastersys
            directory
            <br />
          </div>
          <h5 className="label">Virtual Machine</h5>
          <div style={{ margin: 20 }}>
            Any of the previously mentioned installation options work equally well in
            virtual environments
          </div>
        </div>
        {/* I think the database password is set to blank or perhaps samurai.
			It depends on whether you installed this web app from irongeeks site or
			are using it inside Kevin Johnsons Samurai web testing framework.
			It is ok to put the password in HTML comments because no user will ever see
			this comment. I remember that security instructor saying we should use the
			framework comment symbols (ASP.NET, JAVA, PHP, Etc.)
			rather than HTML comments, but we all know those
			security instructors are just making all this up. */}{" "}
        {/* End Content */}
      </>


    </div>
  )
}

export default InstallationLinux