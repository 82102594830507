import React from 'react'
import styled from 'styled-components'
const Title = ({title}) => {
    return (
        <div>
            <StyledTitle>
            <div className='title w-80 mt-3 me-5 ms-5 text-dark text-center rounded border border-dark'>
                {title}
            </div>
 
   </StyledTitle>
        </div>

    )
}

export default Title
const StyledTitle=styled.div
`
.title{
    background-color: #e4e4e4;
  }

`
