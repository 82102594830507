import React from "react";

const ConferenceRoomLookup = () => {
  return (
    <div>
      <div className=" border border-1 border-black title rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">
          Available Conference Room Lookup
        </p>
      </div>

      <div className=" mt-5 text-center ">
        <div className="mx-auto buttoncolor btn border border-1 border-black m-0 p-1 ">
          <b className="m-0 p-0 text-dark ">Find Avalible Rooms</b>
        </div>
      </div>
    </div>
  );
};

export default ConferenceRoomLookup;
