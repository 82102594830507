import React from 'react'

import DownloadSection from '../DownloadSection'
import Title from '../Title'


const ControlChallenge = (props) => {
    return (




        <div>

            <section className='container-fluid'>
                <Title title='Client-side Control Challenge' />

                <div className='col-lg-4 m-4'>
                    <DownloadSection/>
                </div>

                <div className='col-lg-6 d-flex justify-content-between align-items-center'>
                    <a href="">
                        <img src={props.path} alt="" />
                        <b> {props.text}</b>
                    </a>
                    <a href="">
                        <img src={props.path1} alt="" />
                        <b>{props.text1}</b>
                    </a>

                </div>
                <div className='col-lg-6 mx-auto'>
                    <div className='col-lg-9 mx-auto'>

                        <div className='col-lg-12 bg-warning border border-dark rounded text-center mb-4'>
                            <h6>Please enter flag into all form fields</h6>
                        </div>
                        <p>Please enter the following flag into each field and choose the flag in each control. For example enter the flag into all text fields and choose the flag in the drop down, check the box next to the flag, and select the radio button for the flag</p>
                        <p>Be certain every control contains the value of the flag</p>
                        <p>When all controls have the value of the flag submit the form</p>
                        <div className='col-lg-12  p-3 '>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Text Box</b></p>
                                <div className=' col-6'>
                                    <input type="text box" className='rounded' />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Read-Only Text Box</b></p>
                                <div className=' col-6'>
                                    <input type="text" className='rounded' name="readonly_textbox" id="id_readonly_textbox" required="required" autofocus="autofocus" readonly="readonly" value="42" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Short Text Box</b></p>
                                <div className=' col-6'>
                                    <input type="text" className='rounded' name="short_textbox" id="id_short_textbox" required="required" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Disabled Text Box</b></p>
                                <div className=' col-6'>
                                    <input type="text" className='rounded' name="disabled_textbox" id="id_disabled_textbox" required="required" disabled="disabled" />
                                </div>
                            </div><div className='d-flex align-items-center justify-content-between'>
                                <p><b>Hidden Text Box</b></p>
                                <div className=' col-6'>
                                    <input type="hidden" className='rounded' name="hidden_textbox" id="id_hidden_textbox" required="required" />
                                </div>
                            </div><div className='d-flex align-items-center justify-content-between'>
                                <p><b>"Secured by JavaScript" Text Box</b></p>
                                <div className=' col-6'>
                                    <input type="text" className='rounded' name="tricky_textbox" id="id_tricky_textbox" required="required" onfocus="javascript:this.blur();" />
                                </div>
                            </div><div className='d-flex align-items-center justify-content-between'>
                                <p><b>Vanishing Text Box</b></p>
                                <div className=' col-6'>
                                    <input type="hidden" className='rounded' name="vanishing_textbox" id="id_vanishing_textbox" required="required" onmouseover="javascript:this.type='hidden';" />
                                </div>
                            </div><div className='d-flex align-items-center justify-content-between'>
                                <p><b>Shy Text Box</b></p>
                                <div className=' col-6'>
                                    <input type="text" className='rounded' name="shy_textbox" id="id_shy_textbox" required="required" class="box" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Search Textbox</b></p>
                                <div className=' col-6'>
                                    <input type="search" className='rounded' name="search" id="id_search" pattern="[a-zA-z]" required="required" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Password</b></p>
                                <div className=' col-6'>
                                    <input type="password" className='rounded' name="password" id="id_password" required="required" value="Hint: If you use Burp-Suite this exercise is much easier" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Drop-down Box</b></p>
                                <div className=' col-6'>
                                    <select name="select" id="id_select" required="required">
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Buckle my shoe</option>
                                    </select>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Search Textbox</b></p>
                                <div className=' col-6'>
                                    <input type="search" className='rounded' name="search" id="id_search" pattern="[a-zA-z]" required="required" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between py-1'>
                                <p><b>Checkbox</b></p>
                                <div className=' col-6'>
                                    <div className='d-flex align-items-center'>
                                        <input type="checkbox" className='rounded' name="checkbox" id="id_checkbox" value="714073873" required="required" disabled="disabled" />
                                        <span>Select 714073873?</span>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between py-1'>
                                <p><b>Radio Button</b></p>
                                <div className=' col-6'>

                                    <div className='d-flex align-items-center'>
                                        <input type="radio" className='rounded' name="radio" id="id_radio" value="1" required="required" checked="checked" />
                                        <p className='m-0 ps-2'>1</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <input type="radio" className='rounded' name="radio" id="id_radio" value="2" required="required" checked="checked" />
                                        <p className='m-0 ps-2'>2</p>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <input type="radio" className='rounded' name="radio" id="id_radio" value="714073873" required="required" disabled="disabled" />
                                        <p className='m-0 ps-2'>7142526546</p>
                                    </div>

                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Email Control</b></p>
                                <div className=' col-6'>
                                    <input type="email" className='rounded' name="email" id="id_email" required="required" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>File Upload</b></p>
                                <div className=' col-6'>
                                    <input type="file" className='rounded' name="file" id="id_file" required="required" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Number</b></p>
                                <div className=' col-6'>
                                    <input type="number" className='rounded' name="number" id="id_number" min="0" max="999" step="1" required="required" />
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <p><b>Range</b></p>
                                <div className=' col-6'>
                                    <input type="range" className='rounded' name="range" id="id_range" min="0" max="999" step="1" required="required" />
                                </div>
                            </div>



                            <div className='text-center'>
                                <input type="submit" value="Submit" className='rounded px-2' />
                            </div>
                        </div>
                    </div>

                </div>


            </section>

        </div>
    )
}

export default ControlChallenge