import React from 'react'
import styled from 'styled-components'


const EditUserProfile = () => {
    return (
        <StyledEditUserProfile>
            <div>
                <div className='navbar justify-content-center rounded border border-dark w-75 mx-auto mt-5'>
                    <h3>Edit Profile</h3>
                </div>
                <div className='d-flex mt-5 ms-4'>
                    <div>
                        <img src="http://localhost/images/ajax_logo-75-79.jpg" alt="" />
                    </div>
                    <div>
                        <h5 className='text-primary mt-4'>Switch to RESTful Web Service Version of this Page</h5>
                    </div>
                </div>
                <div>
                    <div className=' text-center mt-5'>
                        <h5>User profile not found. You may <a href="#"> Login here</a></h5>
                        <p>Dont have an account? <a href="#"> Please Register Here here</a></p>
                        
                    </div>
                </div>
            </div>
        </StyledEditUserProfile>
    )
}

export default EditUserProfile
const StyledEditUserProfile = styled.div`
.navbar{
    background-color:#dddddd;
}
.text{
    color:blue
}


`