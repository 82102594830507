import React from 'react'
import styled from "styled-components"
import Title from '../../../../components/Title';


const ViaCookies = () => {
    return (
        <div>
            <Title title={"Privilege Escalation"}/>
            
            <StyledViaCookies>
            <div className='col-5 mx-auto border border-1 first border-dark rounded text-center mt-5'>
                <h3>Privilege Escalation</h3>


            </div>
            <div className='col-5 mx-auto '>
                <h4 className='mt-4'>Cookies</h4>
                <p className='mt-4'>
                    Some sites keep authentication and/or authorization tokens in the user-agent (i.e. browser, phone, tablet). This gives the user (and XSS) large amounts of control over these tokens.

                </p>

            </div>

            <div className='col-5 mx-auto '>
                <p className='mt-4'>
                    For privilege escalation via cookies, alter the cookie values and monitor the effect. Also, regsiter for two (or more) accounts, log into both, and note any differences between the respective cookies.
                </p>

            </div>
            <div className='col-5 mx-auto '>
                <h4 className='mt-4'>SQL Injection</h4>
                <p className='mt-4'>
                Login pages can be vulnerable to SQL injection such that a password or possibly a username is required to authenticate.
                </p>

            </div>

            <div className='col-5 mx-auto '>
                <h4 className='mt-4'>Brute Force</h4>
                <p className='mt-4'>
                THC Hydra (http://www.thc.org/thc-hydra) and Burp Suite can be used to guess usernames and passwords quickly. Both tools can attempt to log into sites and report the result.</p>

            </div>

            <div className='col-5 mx-auto '>
                <h4 className='mt-4'>Secret Adminnistrative Pages</h4>
                <p className='mt-4'>
                Built in pages can sometimes be accessed without a login or using privilege escalation. These pages can grant administrative authority to create other admin accounts.</p>

            </div>
            </StyledViaCookies>

            


        </div>
    )
}

export default ViaCookies
const StyledViaCookies = styled.div
`.first{background-color:#bc6c2550;}`