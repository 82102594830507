import React from 'react'
import styled from 'styled-components'

const UserAccountManagement = () => {
    return (
        <StyledUserAccountManagement>
            <div className='container'>
                <a className='highlight' >Back to Home Page</a>
                <div className='d-flex'>
                    <h6>Help:</h6>
                    <p className='ms-1'> This service exposes GET, POST, PUT, DELETE methods. This service is vulnerable to SQL injection in security level 0.</p>
                </div>
                <hr></hr>
                <div className='d-flex'>
                    <h6>DEFAULT GET:</h6>
                    <p className='ms-1'> (without any parameters) will display this help plus a list of accounts in the system.</p>
                </div>
                <div className='d-flex'>
                    <h6> Optional params:</h6>
                    <p className='ms-1'> None.</p>
                </div>

                <hr></hr>
                <div className='d-flex'>
                    <h6>GET:</h6>
                    <p className='ms-1'> Either displays usernames of all accounts or the username and signature of one account.</p>
                </div>
                <div className='d-flex'>
                    <h6>Optional params:</h6>
                    <p className='ms-1'>  username AS URL parameter. If username is "*" then all accounts are returned.</p>
                </div>

                <h6>Example(s):</h6>

                <p className='ms-1'> Get a particular user:<a className='highlight'> /rest/ws-user-account.php?username=adrian</a></p>
                <p className='ms-1'> Get all users:<a className='highlight'> /webservices/rest/ws-user-account.php?username=*</a></p>

                <h6>Example Exploit(s):</h6>

                <p className='ms-1'> SQL injection:<a className='highlight'>/webservices/rest/ws-user-account.php?username=jeremy'+union+select+concat('The+password+for+',username,'+is+',+password),mysignature+from+accounts+--+</a> </p>
                <hr></hr>
                <div className='d-flex'>
                    <h6>POST:</h6>
                    <p className='ms-1'> Creates new account.</p>
                </div>
                <div className='d-flex'>
                    <h6>Required params:</h6>
                    <p className='ms-1'> username, password AS POST parameter.</p>
                </div>
                <div className='d-flex'>
                    <h6> Optional params:</h6>
                    <p className='ms-1'> signature AS POST parameter.</p>
                </div>
                <hr></hr>
                <div className='d-flex'>
                    <h6>PUT:</h6>
                    <p className='ms-1'> Creates or updates account.</p>
                </div>
                <div className='d-flex'>
                    <h6> Required params:</h6>
                    <p className='ms-1'>  username, password AS POST parameter.</p>
                </div>
                <div className='d-flex'>
                    <h6> Optional params:</h6>
                    <p className='ms-1'> signature AS POST parameter.</p>
                </div>
                <hr></hr>
                <div className='d-flex' >
                    <h6>DELETE:</h6>
                    <p className='ms-1'> Deletes account.</p>
                </div>
                <div className='d-flex'>
                    <h6> Required params:</h6>
                    <p className='ms-1'> username, password AS POST parameter.</p>
                </div>
                <div className='d-flex'>
                    <h6> Optional params:</h6>
                    <p className='ms-1'> None</p>
                </div>
            </div>
        </StyledUserAccountManagement>
    )
}

export default UserAccountManagement

const StyledUserAccountManagement = styled.div`
.highlight:hover{
    color:red
}
`