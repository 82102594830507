import styled from 'styled-components'
import React from 'react'

const XMLValidator = () => {
    return (
        <StyledXMLValidator>
        <div>
            <div className='rounded bg-danger text-center col-5  w-25 mx-auto'>
                <h5>Please Enter XML to Validate</h5>
            </div>
            <div className='d-flex justify-content-center'>
                <div>
                    <h6>Example :</h6>
                </div>
                <div>
                   
                        {`
                        <p> <somexml><message>Hello World</message></somexml></p>
                `}
                   
                </div>
            </div>
            <div className='d-flex mx-auto col-4 justify-content-center'>
            <div className='me-3 mt-5 text-center'>
                <h5>XML</h5>
            </div>
            <div className='ms-5'>
            <textarea name="xml" rows="8" cols="50" id="idXMLTextArea" title="Please enter XML to validate" autofocus="autofocus"></textarea>
            </div>
            </div>
        </div >
        </StyledXMLValidator>
    )
}

export default XMLValidator
const StyledXMLValidator = styled.div`
`