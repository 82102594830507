import React from 'react'
import { Link } from 'react-router-dom'
import UndoIcon from '@mui/icons-material/Undo';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import TextField from '@mui/material/TextField';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Tittle from '../../../../components/Title';



const AddBlog = () => {
    return (
        <>
            <div className='container '>
                <div className=''>
                   <Tittle tittle={"Welcome To The Blog"}/>
                  
                    <div className='border p-3 rounded rounded-3 mt-4'>
                        <div className='p-3'>
                        <p><u><b>Add New Blog Entry</b></u></p>
                        <div className='d-flex'>
                    <h5 className='text-primary'> 🔍View Blogs</h5>
                        </div>
                        </div>
                        <div className='col-lg-7 mx-auto'>
                            
                            <h4 className='info-box  rounded p-1 text-center border border-black'>Add blog for anonymous</h4>
                            <h5 className='text-center title-bg  rounded p-1 border border-black'>Note: b,i,u tags are now allowed in blog entries</h5>
                            <textarea className='w-100' ></textarea>
                            <div className='text-center p-2'>
                                <button className='btn btn-primary'>Save Blog Entry</button>
                            </div>
                         

                        </div>

                    </div>
                    
                    <div className='mt-3 table-responsive'>
                    <div className='d-flex'>
                        <h5 className='text-primary'>🔍View Blogs</h5>
                        </div>
                        <table className='table table-bordered mt-3'>
                            <thead>
                                <tr className='text-center'>
                                    <th colSpan={4}>1 Current Blog Entries</th>
                                </tr>
                                
                                <tr className='bg-secondary'>
                                    <th className='title-bg'>Sno</th>
                                    <th className='title-bg'>Name</th>
                                    <th className='title-bg'>Date</th>
                                    <th className='title-bg'>Comment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>anonymous</td>
                                    <td>	2009-03-01 22:27:11</td>
                                    <td>An anonymous blog? Huh?</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AddBlog