import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import Endpoint from '../../../../assets/Endpoint'
import SecurityHeaders from '../../../../assets/SecurityHeaders'
import { TextField } from '@mui/material'

const BeefRegister = () => {

  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [conPassword, setConfPassword] = useState('')
  const [signature, setSignature] = useState('')
  const [error, setError] = useState(false)
  const [checkPassword, setCheckPassword] = useState('')


  const handelSubmit = async () => {


    const data = new FormData()
    data.append('user_name', name);
    data.append('password', password);
    data.append('signature', signature);
    const response = await axios.post(Endpoint + "oswap-2013/A8/cross-site-request-register.php", data, SecurityHeaders)
    if (response) {

      if (response.data.status === "success") {
        localStorage.setItem("user_id", JSON.stringify(response.data.user_id))

        window.location.reload()
        setError(false)
        


      } else {

        setError(true)
        setCheckPassword("Password doesn't match check your password")

      }


    }

    // values(name,password,signature)

  }

  return (
    <>
   <div className='container'>
                <div className='col-lg-6'>
                    <div className='d-flex align-items-center'>
                        <div className='ajax'>
                            <img className='w-100' src='https://scottiestech.info/wp-content/uploads/2009/12/form_ajax-150x150.jpg' />
                        </div>
                        <div>
                            <h5 className='text-primary ms-3'>Switch to RESTful Web Service Version of this Page</h5>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 mx-auto'>
                    <h5 className='bg-warning rounded text-center p-1'>Please choose your username, password and signature</h5>
                    <form onSubmit={(event) => { event.preventDefault(); handelSubmit() }} className='mt-5'>
                        <TextField className='w-100'
                            id="outlined-multiline-static"
                            label="User Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            className='w-100 mt-3'
                            id="outlined-multiline-static"
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            className='w-100 mt-3'
                            id="outlined-multiline-static"
                            label="Confirm Password"
                            value={conPassword}
                            onChange={(e) => setConfPassword(e.target.value)}
                        />
                        <TextField className='w-100 mt-3'
                            id="outlined-multiline-static"
                            label="Signature"
                            multiline
                            rows={4}
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)}
                        />
                        {
                            error ? <p className=' mt-3 text-center text-danger'>{checkPassword}</p> : null
                        }
                        <div className='text-center mt-3'>
                            <button className='btn btn-primary w-50'>Create Account</button>
                        </div>
                    </form>
                </div>
            </div>
    </>
  )
}

export default BeefRegister