import React from 'react'
import icon from '../assets/images/bootstrap.png'
const Signup = () => {
  return (
<>
            <div className='col-lg-3 mx-auto mt-5 p-4'>
                <form>
                    <img src={icon} className='icon'></img>
                    <h4 className='mt-3'>Please Sign up</h4>
                    <input type='text' className='form-control mt-2' placeholder='First Name' />
                    <input type='text' className='form-control mt-2' placeholder='Last Name' />
                    <input type="email" className='form-control mt-2' placeholder='Email address' required />
                    <input type='password' className='form-control mt-2' placeholder='Password' required />
                    <input type='number' className='form-control mt-2' placeholder='Phone' required />
                    <input type='checkbox' className=' me-3 mt-3' />Remember me
                    <button className='btn btn-primary w-100 mt-3'>Sign up</button>
                </form>
            </div>
        </>
  )
}

export default Signup