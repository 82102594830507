import React, { useEffect, useState } from "react";
import "./labs.css";
import Rating from '@mui/material/Rating';
import CallIcon from "@mui/icons-material/Call";
import LanguageIcon from "@mui/icons-material/Language";
import SmsIcon from "@mui/icons-material/Sms";
import privacy from "../../assets/images/Privacy.svg";
import network from "../../assets/images/Network.svg";
import malware from "../../assets/images/Malware.svg";
import check from '../../assets/images/check.svg'
import axios from "axios";
const Labs = () => {


  const [labData, setLabData] = useState([]);

  const [Value, setValue] = useState();


  const lab = async () => {

    try {
      const data = new FormData();
      const res = await axios.post("https://globalhackersunited.com/labs.php", data, { headers: { 'content-type': 'multipart/form-data' } })
      if (res.data.status === "success") {

        setLabData(res.data.data);

      }
      else {

      }

    }
    catch (error) {
      console.log("labs Error", error)
    }
  }
  console.log(labData)


  useEffect(() => {
    lab();
  }, [])

  return (
    <>
      <div className="bg-dark video ">
        <div className=" container">
          <div className=" container pt-5 pb-5  ">
            <div className="pt-5 pb-5 mt-5 col-sm-12 col-lg-4 text-start">
              <h1 className="text-light" style={{ fontSize: "50px" }}>
                Securing Your Digital World, One Byte at a Time
              </h1>

              <p className=" text-start text-light pt-2">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>

              <a
                className="text-start btn rounded border-0 p-2 text-light mt-3"
                style={{ backgroundColor: "#6366f1" }}
              >
                <span className="p-4">GET STARTED</span>
              </a>
            </div>
          </div>

          <div className=" col-12  flex-wrap mx-auto d-flex  justify-content-between">



            <div className=" mx-auto col-lg-4 col-md-6 col-sm-12  p-3">
              <div
                className="col-12 ps-5 pe-5 pt-4 pb-3 mx-auto rounded-4"
                style={{ backgroundColor: "#192230" }}
              >
                <div className="m-3">
                  <div
                    style={{ backgroundColor: "#6366f1" }}
                    className=" col-xl-3  col-lg-3 col-md-4 col-sm-3 col-4 p-3  rounded-circle  "
                  >
                    <img src={privacy} className="w-100 " />
                  </div>
                </div>

                <div className="pt-3 col-lg-12 ">
                  <p className="text-light p-0 m-0 fs-4 fw-semibold">
                    Privacy Protection
                  </p>
                  <p className=" text-secondary fs-6 fw-semibold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                </div>
              </div>
            </div>

            <div className=" mx-auto  col-lg-4 col-md-6 col-sm-12  p-3">
              <div
                className="col-12 ps-5 pe-5 pt-4 pb-3 mx-auto rounded-4"
                style={{ backgroundColor: "#6366f1" }}
              >
                <div className="m-3">
                  <div className=" col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4 p-3 rounded-circle bg-white  ">
                    <img src={network} className="w-100 " />
                  </div>
                </div>

                <div className="pt-3 mt-4 ">
                  <p className="text-light p-0 m-0 fs-4 fw-semibold">
                    Secure Network
                  </p>
                  <p className=" text-light fs-6 fw-semibold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                </div>
              </div>
            </div>

            <div className=" mx-auto  col-lg-4 col-md-6 col-sm-12 p-3">
              <div
                className="col-12 ps-5 pe-5 pt-4 pb-3 mx-auto rounded-4"
                style={{ backgroundColor: "#192230" }}
              >
                <div className="m-3">
                  <div
                    style={{ backgroundColor: "#6366f1" }}
                    className=" col-xl-3 col-lg-3 col-md-4 col-sm-3 col-4 p-3 p-3  rounded-circle  "
                  >
                    <img src={malware} className="w-100 " />
                  </div>
                </div>

                <div className="pt-4 ">
                  <p className="text-light p-0 m-0 fs-4 fw-semibold">
                    Malware & Virus Protection
                  </p>
                  <p className=" text-secondary fs-6 fw-semibold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                    leo.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className=" container d-flex flex-wrap">


          {labData.map((items, index) => (

            <>
              <div className=" col-4 p-3">
                <div className=" bg-light border-3 rounded-4 mx-auto p-3 max-height" >
                  <h2 className=" mx-auto d-block">{items.title}</h2>

                  <b className=" mx-auto">{items.description}</b>
                 
                <div className=" d-flex mt-2">
                <Rating
                    name="readOnly"
                    value={items.rating}
                    readOnly
                  />
                  
                  <b className=" ms-4">Rating</b> <p className="ms-2"> {items.rating}</p>
                </div>
                </div>
              </div>
            </>

          ))
          }

        </div>
        <div className=" " style={{ backgroundColor: "#1F2937" }}>
          <div className=" mb-5 pb-5 col-md-12 flex-wrap col-lg-12  mt-5 pt-5 col-12 d-flex justify-content-between align-items-center container">
            <div className="col-12 col-lg-6 col-md-12  p-4 mt-5 ">
              <div
                className="row negitive rounded-circle position-absolute  d-flex justify-content-center align-items-center"
                style={{ backgroundColor: "#6366f1" }}
              >
                <div className="d-none d-lg-block d-sm-block d-md-block">
                  <h1 className="text-light text-center mb-0">25+ </h1>
                  <h5 className="text-light text-center">
                    {" "}
                    years of experiencce
                  </h5>
                </div>
                <div className=" d-lg-none d-md-none d-sm-none d-block">
                  <h5 className="text-light text-center">
                    <b>25+ </b>
                  </h5>
                  <h6 className="text-light text-center ">
                    {" "}
                    years of experiencce
                  </h6>
                </div>
              </div>
              <div className="row col-lg-11 col-sm-12 mx-auto">
                <img
                  className="w-100 text-start h-100"
                  src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/10/server-room-portrait-or-happy-technician-for-online-cybersecurity-update-or-machine-system-it-sup-e1698778026884.jpg"
                />
              </div>
            </div>

            <div className="col-12 col-lg-6 col-md-12 col-sm-12 p-4 mt-5">
              <div className="col-12 text-start">
                <b className=" fs-5  " style={{ color: "#6366f1" }}>
                  WHO WE ARE
                </b>
                <h2
                  className="text-light mt-3 fw-bold fs-1"
                  style={{ fontFamily: " Sans-serif" }}
                >
                  Protecting you from the dangers of cyberspace.
                </h2>
                <p
                  className="text-secondary fw-semibold fs-6 pt-3"
                  style={{ fontFamily: "Sans-serif" }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                  leo.{" "}
                </p>
                <hr className="text-light mt-5 mb-4" />

                <div className=" col-12 d-flex flex-wrap  justify-content-between">
                  <div className=" col-lg-6  col-md-12 col-sm-12 col-12">
                    <div className="d-flex gap-2 ">
                      <div className="col-1">
                        <img src={check} className="w-75" />
                      </div>

                      <p className="fs-6 p-0 m-0 text-secondary">
                        Tempor lacus inceptos auctor
                      </p>
                    </div>
                    <div className="d-flex gap-2 ">
                      <div className="col-1">
                        <img src={check} className="w-75" />
                      </div>

                      <p className="fs-6 p-0 m-0 text-secondary">
                        Tempor lacus inceptos auctor
                      </p>
                    </div>
                    <div className="d-flex gap-2 ">
                      <div className="col-1">
                        <img src={check} className="w-75" />
                      </div>

                      <p className="fs-6 p-0 m-0 text-secondary">
                        Tempor lacus inceptos auctor
                      </p>
                    </div>
                    <div className="d-flex gap-2 ">
                      <div className="col-1">
                        <img src={check} className="w-75" />
                      </div>

                      <p className="fs-6 p-0 m-0 text-secondary">
                        Tempor lacus inceptos auctor
                      </p>
                    </div>
                  </div>

                  <div className=" col-lg-6  col-md-12 col-sm-12 col-12 ">
                    <div className="d-flex gap-2 ">
                      <div className="col-1">
                        <img src={check} className="w-75" />
                      </div>

                      <p className="fs-6 p-0 m-0 text-secondary">
                        Tempor lacus inceptos auctor
                      </p>
                    </div>
                    <div className="d-flex gap-2 ">
                      <div className="col-1">
                        <img src={check} className="w-75" />
                      </div>

                      <p className="fs-6 p-0 m-0 text-secondary">
                        Tempor lacus inceptos auctor
                      </p>
                    </div>
                    <div className="d-flex gap-2 ">
                      <div className="col-1">
                        <img src={check} className="w-75" />
                      </div>

                      <p className="fs-6 p-0 m-0 text-secondary">
                        Tempor lacus inceptos auctor
                      </p>
                    </div>
                    <div className="d-flex gap-2 ">
                      <div className="col-1">
                        <img src={check} className="w-75" />
                      </div>

                      <p className="fs-6 p-0 m-0 text-secondary">
                        Tempor lacus inceptos auctor
                      </p>
                    </div>
                  </div>
                </div>

                <hr className="text-light mt-4" />

                <div className=" col-lg-12 col-md-12  flex-wrap  d-flex justify-content-evenly">
                  <img
                    className="w-25 col-md-12 "
                    src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/rating_1.png"
                  />

                  <img
                    className="w-25 col-md-12 "
                    src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/rating_4.png"
                  />

                  <img
                    className="w-25 col-md-12 "
                    src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/rating_5.png"
                  />
                </div>
              </div>
            </div>
          </div>

          <div style={{ backgroundColor: "#192230" }} className=" col-lg-12  ">
            <div className="pt-5 pb-3 container">
              <div className=" d-flex flex-wrap  justify-content-between p-4 my-auto">
                <div className="col-lg-4 col-md-12 col-sm-12 text-start">
                  <h3 className=" text-white text-start fw-bold ">
                    Trusted world-class brands and organizations of all sizes
                  </h3>
                  <p className=" pt-3 text-secondary text-start">
                    Congue bibendum adipiscing suspendisse velit ipsum convallis
                    dolor habitant semper ut inceptos
                  </p>
                </div>

                <div className="col-lg-7 col-md-12 d-flex flex-wrap gap-1">
                  <div className=" d-flex justify-content-between g-col-lg-7 flex-wrap g-md-12 ">
                    <div className=" col-lg-3 col-md-4 col-sm-12 col-12 p-2">
                      <div
                        className=" shadow rounded-3 my-auto p-4 "
                        style={{ backgroundColor: "#1F2937" }}
                      >
                        <img
                          className="w-100 p-2"
                          src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/logo_8.png"
                        />
                      </div>
                    </div>

                    <div className=" col-lg-3 col-md-4 col-sm-12 p-2">
                      <div
                        className=" shadow rounded-3 my-auto p-4 "
                        style={{ backgroundColor: "#1F2937" }}
                      >
                        <img
                          className="w-100 p-2"
                          src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/logo_8.png"
                        />
                      </div>
                    </div>

                    <div className=" col-lg-3 col-md-4 col-sm-12 p-2">
                      <div
                        className=" shadow rounded-3 my-auto p-4 "
                        style={{ backgroundColor: "#1F2937" }}
                      >
                        <img
                          className="w-100 p-2"
                          src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/logo_8.png"
                        />
                      </div>
                    </div>

                    <div className=" col-lg-3 col-md-4 col-sm-12 p-2">
                      <div
                        className=" shadow rounded-3 my-auto p-4 "
                        style={{ backgroundColor: "#1F2937" }}
                      >
                        <img
                          className="w-100 p-2"
                          src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/logo_8.png"
                        />
                      </div>
                    </div>

                    <div className=" col-lg-3 col-md-4 col-sm-16 col-12 p-2">
                      <div
                        className=" shadow rounded-3 my-auto p-4 "
                        style={{ backgroundColor: "#1F2937" }}
                      >
                        <img
                          className="w-100 p-2"
                          src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/logo_8.png"
                        />
                      </div>
                    </div>

                    <div className=" col-lg-3 col-md-4 col-sm-12 col-12 p-2">
                      <div
                        className=" shadow rounded-3 my-auto p-4 "
                        style={{ backgroundColor: "#1F2937" }}
                      >
                        <img
                          className="w-100 p-2"
                          src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/logo_8.png"
                        />
                      </div>
                    </div>

                    <div className=" col-lg-3 col-md-4 col-sm-12 col-12 p-2">
                      <div
                        className=" shadow rounded-3 my-auto p-4 "
                        style={{ backgroundColor: "#1F2937" }}
                      >
                        <img
                          className="w-100 p-2"
                          src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/logo_8.png"
                        />
                      </div>
                    </div>

                    <div className=" col-lg-3 col-md-4 col-sm-12 col-12 p-2">
                      <div
                        className=" shadow rounded-3 my-auto p-4 "
                        style={{ backgroundColor: "#1F2937" }}
                      >
                        <img
                          className="w-100 p-2"
                          src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/11/logo_8.png"
                        />
                      </div>
                    </div>

                    <div className=" col-lg-3 col-md-4 col-sm-12 col-12 p-2">
                      <div className=" rounded-3 my-auto p-4 ">
                        <img className="w-100 p-2" src="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="backimg mx-auto pt-5 pb-5">
            <div className="p-5 my-auto text-light container ">
              <h1 className="  size fw-bold ">
                Secure your data, <br />
                secure your future.{" "}
              </h1>
              <p className=" fs-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus
                <br /> nec ullamcorper mattis, pulvinar dapibus leo.
              </p>

              <a className=" btn btn-primary rounded-2 ps-4 pe-4 mt-3 ">
                {" "}
                <p className=" my-auto p-1 m-1">GET STARTED</p>{" "}
              </a>
            </div>
          </div>

          <div className=" backimg2 pt-5 container ">
            <div className="d-flex flex-wrap mb-5 pb-5 pt-5 mt-5 justify-content-between align-items-lg-center">
              <div className="col-12 col-sm-12 col-lg-5 col-md-12  p-4 text-start">
                <b className="pb-3" style={{ color: "#6366f1" }}>
                  HOMEPAGE
                </b>
                <h1 className=" mb-3 text-light">
                  We provide innovative solutions for your security needs.
                </h1>
                <p className=" text-secondary fw-semibold">
                  Et laoreet cubilia turpis ipsum dui nostra amet ridiculus. Eu
                  dapibus proin nec tincidunt aenean taciti. Justo mus ad
                  quisque nam imperdiet urna sem laoreet mi interdum molestie.
                </p>
                <a
                  className=" btn p-2 mt-4 text-light"
                  style={{ backgroundColor: "#6366f1" }}
                >
                  <p className="m-0 ps-3 pe-3">LEARN MORE</p>
                </a>
              </div>

              <div className=" col-12 col-sm-12 col-md-12 col-lg-6 position-relative ">
                <img
                  className="w-100 rounded-4 "
                  src="https://kit.creativemox.com/shieldpro/wp-content/uploads/sites/8/2023/10/portrait-of-software-engineer-writing-code-arranging-glasses-and-being-happy-about-parsing-results-e1698777675815.jpg"
                />
                <div
                  className=" col-lg-6 col-md-5 col-sm-6 col-12 d-lg-block rounded-3 pt-2 pb-2 ps-4 pe-4 adjust position-absolute "
                  style={{ backgroundColor: "#6366f1" }}
                >
                  <div className=" mb-3 mx-auto pt-2     d-flex justify-content-center align-items-center ">
                    <CallIcon
                      sx={{ color: "#6366f1", fontSize: 65 }}
                      className=" bg-light col-lg-4 col-3 bg-white p-3  rounded-circle "
                    />
                  </div>
                  <div className="col-12 text-sm-center d-flex  justify-content-center">
                    <div className=" ">
                      <b className=" text-light   pt-5  ">CUSTOMER SUPPORT</b>
                      <b className=" d-block text-light fs-4 ">
                        (888) 4000-2234
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-12 col-md-12 d-sm-none d-lg-block d-md-block d-none pt-5 mt-5 text-center">
              <b
                className="  mt-5 pt-5 pb-0 mb-0 premium"
                style={{ color: "#192230", lineHeight: "0px" }}
              >
                Premium Services
              </b>
            </div> */}
          </div>

          <div
            className="pb-3  "
            style={{ backgroundColor: "#192230", marginTop: "-9px" }}
          >
            <div className="p-5">
              <div className=" col-12 col-lg-4 col-md-6 col-sm-12 mb-5 pt-5 mx-auto text-center">
                <p className=" mt-5 fw-bold text-light fs-1">
                  No matter where you go. Be security smart.
                </p>
              </div>

              <div className="   d-flex flex-wrap col-12 container ">
                <div className="  col-lg-4  col-md-6 col-sm-12 col-12 mx-auto p-3 ">
                  <div
                    style={{ backgroundColor: "#111827" }}
                    className=" shadow p-4 rounded-4"
                  >
                    <div>
                      <LanguageIcon
                        style={{
                          fontSize: 65,
                          color: "white",
                          backgroundColor: "#6366f1",
                        }}
                        className="rounded-circle col-xl-3 col-lg-4 col-md-3 col-sm-2 col-4  p-2 "
                      />
                    </div>
                    <div
                      className=" ps-4 text-start pe-3 mt-4 "
                      style={{ color: " white" }}
                    >
                      <b className="  fs-4"> Network Protections </b>
                      <p className="">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                      <hr className="mt-5 " />
                      <a className=" btn btn-light ps-2 pe-2 mt-3 mb-1 text-start">
                        <p className=" m-0 ps-2 pe-2 text-primary">
                          LEARN MORE
                        </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className=" col-12 col-lg-4 col-md-6 col-sm-12 mx-auto p-3 ">
                  <div
                    style={{ backgroundColor: "#111827" }}
                    className=" shadow p-4 rounded-4"
                  >
                    <div>
                      <LanguageIcon
                        style={{
                          fontSize: 65,
                          color: "white",
                          backgroundColor: "#6366f1",
                        }}
                        className="rounded-circle col-xl-3 col-lg-4 col-md-3 col-sm-2 col-4  p-2 "
                      />
                    </div>
                    <div
                      className=" ps-4 text-start pe-3 mt-4 "
                      style={{ color: " white" }}
                    >
                      <b className=" fs-4"> Network Protections </b>
                      <p className="">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                      <hr className="mt-5" />
                      <a className=" btn btn-light ps-2 pe-2 mt-3 mb-1 text-start">
                        <p className=" m-0 ps-2 pe-2 text-primary">
                          LEARN MORE
                        </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className=" col-12 col-lg-4  col-md-6 col-sm-12 mx-auto p-3 ">
                  <div
                    style={{ backgroundColor: "#111827" }}
                    className=" shadow p-4 rounded-4"
                  >
                    <div>
                      <LanguageIcon
                        style={{
                          fontSize: 65,
                          color: "white",
                          backgroundColor: "#6366f1",
                        }}
                        className="rounded-circle col-xl-3 col-lg-4 col-md-3 col-sm-2 col-4  p-2 "
                      />
                    </div>
                    <div
                      className=" ps-4 text-start pe-3 mt-4 "
                      style={{ color: " white" }}
                    >
                      <b className=" fs-4"> Network Protections </b>
                      <p className="">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                      <hr className="mt-5" />
                      <a className=" btn btn-light ps-2 pe-2 mt-3 mb-1 text-start">
                        <p className=" m-0 ps-2 pe-2 text-primary">
                          LEARN MORE
                        </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className=" col-12 col-lg-4 col-md-6 col-sm-12 mx-auto p-3 ">
                  <div
                    style={{ backgroundColor: "#111827" }}
                    className=" shadow p-4 rounded-4"
                  >
                    <div>
                      <LanguageIcon
                        style={{
                          fontSize: 65,
                          color: "white",
                          backgroundColor: "#6366f1",
                        }}
                        className="rounded-circle col-xl-3 col-lg-4 col-md-3 col-sm-2 col-4  p-2 "
                      />
                    </div>
                    <div
                      className=" ps-4 text-start pe-3 mt-4 "
                      style={{ color: " white" }}
                    >
                      <b className=" fs-4"> Network Protections </b>
                      <p className="">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                      <hr className="mt-5" />
                      <a className=" btn btn-light ps-2 pe-2 mt-3 mb-1 text-start">
                        <p className=" m-0 ps-2 pe-2 text-primary">
                          LEARN MORE
                        </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className=" col-12 col-lg-4 col-md-6  col-sm-12 mx-auto p-3 ">
                  <div
                    style={{ backgroundColor: "#111827" }}
                    className=" shadow p-4 rounded-4"
                  >
                    <div>
                      <LanguageIcon
                        style={{
                          fontSize: 65,
                          color: "white",
                          backgroundColor: "#6366f1",
                        }}
                        className="rounded-circle col-xl-3 col-lg-4 col-md-3 col-sm-2 col-4  p-2 "
                      />
                    </div>
                    <div
                      className=" ps-4 text-start pe-3 mt-4 "
                      style={{ color: " white" }}
                    >
                      <b className=" fs-4"> Network Protections </b>
                      <p className="">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                      <hr className="mt-5" />
                      <a className=" btn btn-light ps-2 pe-2 mt-3 mb-1 text-start">
                        <p className=" m-0 ps-2 pe-2 text-primary">
                          LEARN MORE
                        </p>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="  col-lg-4 col-md-6  col-sm-12 col-12 mx-auto p-3 ">
                  <div
                    style={{ backgroundColor: "#111827" }}
                    className=" col-lg-12 col-md-12 col-sm-12  col-12 shadow p-4 rounded-4"
                  >
                    <div>
                      <LanguageIcon
                        style={{
                          fontSize: 65,
                          color: "white",
                          backgroundColor: "#6366f1",
                        }}
                        className="rounded-circle col-xl-3 col-lg-4 col-md-3 col-sm-2 col-4  p-2 "
                      />
                    </div>
                    <div
                      className="  col-lg-12 col-md-12 col-sm-12 col-12 ps-4 text-start pe-3 mt-4 "
                      style={{ color: " white" }}
                    >
                      <b className=" fs-4"> Network Protections </b>
                      <p className="">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                        dapibus leo.
                      </p>
                      <hr className="mt-5" />
                      <a className=" btn btn-light ps-2 pe-2 mt-3 mb-1 text-start">
                        <p className=" m-0 ps-2 pe-2 text-primary">
                          LEARN MORE
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" backimg3 col-12 d-flex justify-content-center ">
            <div className="pt-5  pb-5 ">
              <b className="  text-white fs-1 p-0 m-0 ">
                Protecting you from the <br /> dangers of cyberspace.
              </b>
              <p className=" mt-3 text-secondary fs-6 fw-medium ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus
                <br /> nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
              <a className=" btn btn-primary p-2 mt-4">
                <p className="m-0 ps-4 pe-4">GET STARTED</p>
              </a>
            </div>
          </div>

          <div className=" backimg2 pt-5 container ">
            <div className="d-flex mb-5 pb-5 pt-5 mt-5 flex-wrap justify-content-between">
              <div className="col-12 col-lg-5 col-md-7 col-sm-12 my-auto p-4 text-start">
                <b className="pb-3" style={{ color: "#6366f1" }}>
                  WHY CHOOSE US
                </b>
                <h1 className=" mb-3 pb-3 pt-4 text-light">
                  Protecting your assets and your brand.
                </h1>
                <p className=" text-secondary fw-semibold">
                  Et laoreet cubilia turpis ipsum dui nostra amet ridiculus. Eu
                  dapibus proin nec tincidunt aenean taciti. Justo mus ad
                  quisque nam imperdiet urna sem laoreet mi interdum molestie.
                </p>
                <a
                  className=" btn p-2 mt-4 text-light"
                  style={{ backgroundColor: "#6366f1" }}
                >
                  <p className="m-0 ps-3 pe-3">DISCOVER MORE</p>
                </a>
              </div>

              <div className=" col-lg-6 col-12 m-0 d-flex  flex-wrap">
                <div className=" col-lg-6 col-12 col-sm-6 col-md-12 p-3 ">
                  <div
                    className="text-start  rounded-4 p-4"
                    style={{ backgroundColor: "#6366f1" }}
                  >
                    <div className="mb-4 bg-white rounded-circle col-xl-3 col-lg-5 col-md-1 col-sm-5 col-2 d-flex justify-content-center align-items-center p-3 ">
                      <SmsIcon
                        className=" mx-auto my-auto "
                        sx={{ fontSize: 30, color: "#6366f1" }}
                      />
                    </div>

                    <div>
                      <b className=" fs-5 text-white overflow-hidden">
                        Free Consultations
                      </b>

                      <p className=" text-light overflow-hidden">
                        Eleifend lacus proin maximus ornare bibendum vulputate
                        sed montes in
                      </p>
                    </div>
                  </div>
                </div>

                <div className="  col-lg-6 col-12 col-sm-6 col-md-12 p-3 ">
                  <div
                    className=" text-start rounded-4 p-4"
                    style={{ backgroundColor: "#111827" }}
                  >
                    <div className="bg-white rounded-circle col-xl-3 col-lg-5 col-md-1 col-sm-5 col-2 d-flex justify-content-center align-items-center p-3 ">
                      <SmsIcon
                        className=" mx-auto my-auto "
                        sx={{ fontSize: 30, color: "#6366f1" }}
                      />
                    </div>

                    <div>
                      <b className=" overflow-hidden fs-5 text-white">
                        Free Consultations
                      </b>

                      <p className=" overflow-hidden text-light">
                        Eleifend lacus proin maximus ornare bibendum vulputate
                        sed montes in
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-12 col-sm-6 col-md-12 p-3 ">
                  <div
                    className=" text-start rounded-4  p-4"
                    style={{ backgroundColor: "#111827" }}
                  >
                    <div className=" mb-4 bg-white rounded-circle col-xl-3 col-lg-5 col-md-1 col-sm-5 col-2 d-flex justify-content-center align-items-center p-3 ">
                      <SmsIcon sx={{ fontSize: 30, color: "#6366f1" }} />
                    </div>

                    <div>
                      <b className=" overflow-hidden fs-5 text-white">
                        Free Consultations
                      </b>

                      <p className=" overflow-hidden text-light">
                        Eleifend lacus proin maximus ornare bibendum vulputate
                        sed montes in
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-12 col-sm-6 col-md-12  p-3 ">
                  <div
                    className=" text-start rounded-4  p-4"
                    style={{ backgroundColor: "#111827" }}
                  >
                    <div className=" mb-4 bg-white rounded-circle col-xl-3 col-lg-5 col-md-1 col-sm-5 col-2 d-flex justify-content-center align-items-center p-3   ">
                      <SmsIcon
                        className="mx-auto my-auto"
                        sx={{ fontSize: 30, color: "#6366f1" }}
                      />
                    </div>

                    <div>
                      <b className=" overflow-hidden fs-5 text-white">
                        Free Consultations
                      </b>

                      <p className=" overflow-hidden text-light">
                        Eleifend lacus proin maximus ornare bibendum vulputate
                        sed montes in
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className=" backimg4 col-lg-12 col-md-12 "
            style={{ backgroundColor: "#192230" }}
          >
            <div className=" pt-5 pb-5">
              <p className=" fs-1 fw-bold text-light text-center">
                Security monitoring and malware <br />
                protection for Personal Home{" "}
              </p>

              <p className=" text-light fs-6 fw-bold text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis,
                <br /> pulvinar dapibus leo.
              </p>
            </div>

            <div className=" pt-5 col-lg-7 col-md-7 col-12 d-flex mx-auto pb-5">
              <div className=" mx-auto">
                <b
                  className="d-block fs-1 fw-bold"
                  style={{ color: "#6366f1" }}
                >
                  14M+
                </b>
                <small className=" text-light">
                  <b> Happy Client </b>
                </small>
              </div>

              <div className=" mx-auto">
                <b
                  className="d-block fs-1 fw-bold"
                  style={{ color: "#6366f1" }}
                >
                  99%
                </b>
                <small className=" text-light">
                  <b> Happy Client </b>
                </small>
              </div>

              <div className=" text-center mx-auto">
                <p
                  className="text-center d-block fs-1 fw-bold p-0 m-0"
                  style={{ color: "#6366f1" }}
                >
                  81K+
                </p>
                <small className="text-center p-0 m-0 text-light">
                  <b> Happy Client </b>
                </small>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default Labs;
