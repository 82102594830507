import React from 'react'

const SiteFooter = () => {
    return (
        <>
            <div className="container mt-5">
                <div className="col-lg-11 mx-auto">
                    <div className='bg-aqua p-2'>
                        <h5 className='text-center rounded fw-bold'>Browser Version</h5>
                    </div>
                    <div>
                        <p className='mt-5 text-center'><b>Browser Version:</b>	Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/121.0.0.0 Safari/537.36</p>
                        <h6 className='text-center mt-5 fw-bold'>Notice the browser version (shown above) being displayed in the site footer on every page.</h6>
                        <h6 className='text-center mt-1 fw-bold'>What could possibly go wrong?</h6>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SiteFooter