import React from 'react'

const EchoMessageCsp = () => {
    return (
        <>
            <div className="container">
                <div className='col-lg-10'>
                    <div className='relative mt-3 p-3'>
                        <div className='absolute bg-white'>
                            <h6 className='p-2'>Current Content Security Policy (CSP) Report To Endpoints</h6>
                        </div>
                        <div className='p-1'>
                            <p>Report-To: "group": "csp-endpoint", "max_age": 10886400, "endpoints":"url": "includes/capture-data.php"</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-3 mt-5">
                <div className='col-lg-10'>
                    <div className='relative mt-3 p-3'>
                        <div className='absolute bg-white'>
                            <h6 className='p-2'>Current Content Security Policy (CSP)</h6>
                        </div>
                        <div className='p-1'>
                            <p>Report-To: "group": "csp-endpoint", "max_age": 10886400, "endpoints":"url": "includes/capture-data.php"</p>
                            <p>Content-Security-Policy:</p>
                            <p>script-src 'self' 'nonce-7a42d87ad29312204865ad16b96c1bb17ee9a2243d7cac36c707f13aa784eddb' mutillidae.localhost;</p>
                            <p>style-src 'unsafe-inline' 'self' mutillidae.localhost;</p>
                            <p>img-src 'self' mutillidae.localhost www.paypalobjects.com;</p>
                            <p>connect-src 'self' mutillidae.localhost;</p>
                            <p>form-action 'self' mutillidae.localhost;</p>
                            <p>font-src 'none';</p>
                            <p>frame-src 'self' mutillidae.localhost;</p>
                            <p>media-src 'none';</p>
                            <p>object-src 'none';</p>
                            <p>default-src 'self';</p>
                            <p>frame-ancestors 'none';</p>
                            <p>report-uri includes/capture-data.php;</p>
                            <p>report-to csp-endpoint;</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EchoMessageCsp