import { TextField } from '@mui/material'
import React from 'react'

const RegisterAccount = () => {
    return (
        <>
            <div className='container'>
                <div className='col-lg-6'>
                    <div className='d-flex align-items-center'>
                        <div className='ajax'>
                            <img className='w-100' src='https://scottiestech.info/wp-content/uploads/2009/12/form_ajax-150x150.jpg' />
                        </div>
                        <div>
                            <h5 className='text-primary ms-3'>Switch to RESTful Web Service Version of this Page</h5>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 mx-auto'>
                    <h5 className='bg-warning rounded text-center p-1'>Please choose your username, password and signature</h5>
                    <form className='mt-5'>
                        <TextField className='w-100'
                            id="outlined-multiline-static"
                            label="User Name"
                        />
                        <TextField
                            className='w-100 mt-3'
                            id="outlined-multiline-static"
                            label="Password"
                        />
                        <TextField
                            className='w-100 mt-3'
                            id="outlined-multiline-static"
                            label="Confirm Password"
                        />
                        <TextField className='w-100 mt-3'
                            id="outlined-multiline-static"
                            label="Signature"
                            multiline
                            rows={4}
                        />
                        <div className='text-center mt-3'>
                            <button className='btn btn-primary w-50'>Create Account</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default RegisterAccount