import React, { useState } from "react";
import SideBar from "./SideBar";

const Common = ({children}) => {



  const [paths,setpaths] = useState([
    "/","/our-hackers"
  ])
  return (
    <div style={{backgroundColor:'white',color:'#212529'}}>
      <div className="d-flex">


        <div className="col-2">
          <SideBar/>
        </div>


        <div className="col-10">

          {children}

        </div>


      </div>
    </div>
  );
};

export default Common;
