import React from "react";

const TestingHttpResponce = () => {
  return (
    <div className="col-12 mt-2 p-4 ">
      <table className="col-12">
        <tbody>
          <tr>
            <td />
          </tr>

          <tr>
            <td className="form-header mt-3 title">
              The value of the of the X-XSS-Protection header is incorrect. What
              should the value be?{" "}
            </td>
          </tr>

          <tr>
            <td />
          </tr>

          <tr>
            <td>
              <input type="radio" name="answer" id={2} defaultValue={2} />
              <label className="h6 ms-2" htmlFor={2}>
                Deny
              </label>
              <br />
              <input type="radio" name="answer" id={3} defaultValue={3} />
              <label className="h6 ms-2" htmlFor={3}>
                1; mode=block
              </label>
              <br />
              <input type="radio" name="answer" id={4} defaultValue={4} />
              <label className="h6 ms-2" htmlFor={4}>
                31546000; includeSubdomains
              </label>
              <br />
              <input type="radio" name="answer" id={5} defaultValue={5} />
              <label className="h6 ms-2" htmlFor={5}>
                42
              </label>
              <br />
              <input type="radio" name="answer" id={5} defaultValue={5} />
              <label className="h6 ms-2" htmlFor={5}>
                no-store, no-cache
              </label>
              <br />
            </td>
          </tr>

          <tr>
            <td />
          </tr>

          <tr>
            <td style={{ textAlign: "center" }}>
              <input
                name="submit-button"
                className="button rounded-3 ps-3 pe-3"
                type="submit"
                defaultValue="Submit"
              />
            </td>
          </tr>

          <tr>
            <td />
          </tr>
        </tbody>
      </table>

      <div className=" text-center mt-4">
        <a
          className="h6 text-decoration-none text-primary"
          href="index.php?page=labs/lab-61.php"
        >
          Previous Lab
        </a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a
          className="h6 text-decoration-none text-primary"
          href="index.php?page=labs/lab-63.php"
        >
          Next Lab
        </a>
      </div>
    </div>
  );
};

export default TestingHttpResponce;
