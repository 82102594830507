import React, { useState } from "react";


import styled from "styled-components";
import axios from "axios";
import Endpoint from "../../../../assets/Endpoint";
import SecurityHeaders from "../../../../assets/SecurityHeaders";
import { json } from "react-router-dom";
import UserDetails from "../../../../components/UserDetails";
import LoginToast from "../../../../components/LoginToast";
import Title from "../../../Others/Title";
const ViaLookupUser = () => {

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

const [show,setShow] = useState(false)

  const [data,setdata] = useState('')
  const HandleSubmit = async () => {

    try {

      const data = new FormData()
      data.append('user_name', name)
      data.append('password', password)

      const res = await axios.post(Endpoint + "owasp-2017/A1/soap-web-lookup-user.php", data, SecurityHeaders);
      if(res){

      if(res.data.status==='success'){
        setdata(res.data)
      }
      if(res.data.message){
        setShow(true)

      }
     
      
      }
      // if (res && res.data.status === "success") {
      //   if (res.data.id !== null && res.data.id !== "" && res.data.id !== undefined) {
      //     localStorage.setItem("user_id", JSON.stringify(res.data.id))
      //     window.location.reload()
      //   }
      // }
    } catch (error) {
      console.error("Error occurred during login:", error);
      // Handle the error appropriately, e.g., show a message to the user
    }


  }
  // console.log(data.data);
  return (
    <>
      <Style>
        <div className="container  ">
        <Title data={{title:"Lookup User"}} />


       
          <div className="col-5 mx-auto mt-4">
            <div className="info-box rounded border-black border">
              <h5 className="text-center">
                Please enter username and password to view account details
              </h5>
            </div>
            <form onSubmit={(e) => { e.preventDefault(); HandleSubmit(); }}>
              <div className="d-flex align-items-center mt-4 ">
                <div className="col-3">
                  <h6>Name</h6>
                </div>
                <div className="col-7">
                  <input
                    className="form-control ms-3 border border-black"
                    placeholder="User name" value={name} onChange={(e) => setName(e.target.value)}
                    required />
                </div>
              </div>
              <div className="d-flex align-items-center mt-3 ">
                <div className="col-3">
                  <h6>Password</h6>
                </div>
                <div className="col-7">
                  <input
                    className="form-control ms-3 border border-black"
                    placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}
                    required />
                </div>
              </div>

              <div className="d-flex justify-content-center col-12 mt-3">
                <button className="btn  btn-primary">view account details</button>
              </div>

              </form>





              <div>
                <p className="text-center mt-3">Don't have an account?<a className="nav-link" href="#"> Please register here</a></p>
              </div>
            
          </div>
          <div className="col-12 mt-3 mx-auto">


{data.data ?
  <UserDetails data={data} />
  : null}
<>
  {
    data.message ? <LoginToast toast={show} /> : null
  }</>

</div>
        </div>
      </Style>
    </>
  );
};

export default ViaLookupUser;

export const Style = styled.div`
  .title-bg {
    background-color: #dddddd !important;
  }
  .info-box {
    background-color: #bc6c2550 !important;
  }
`;
