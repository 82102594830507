import React from "react";

const StylingToMutilidae = () => {
  return (
    <div className="col-6 mx-auto mt-5">
      <div className=" border border-1 border-black title rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">I've been framed!</p>
      </div>
      <div className="">
        <p className=" m-0 text-start">I've been framed by /mutillidae-main/styling.php</p>
      </div>
    </div>
  );
};

export default StylingToMutilidae;
