
import React from 'react'

import styled from 'styled-components'
import Title from '../../../components/Title';
const Lab29 = () => {
    return (





        <div>
            <Title title={"Lab 29: Cross-site Request Forgery - Voting for NMap"} />
            <StyledLab29>
                <div className='box1 border border-dark col-5 mx-auto rounded'>
                    <h5 className='text-center p-0 m-0'>Does the Cross-site Request Forgery (CSRF) attack on the </h5>
                    <h5 className='text-center p-0 m-0'>user-poll.php page still work if the request method is </h5>
                    <h5 className='text-center p-0 m-0'>changed to 'POST'? </h5>
                </div>
                <div className='col-5 mx-auto m-3'>
                    <div className=' d-flex'>

                    <input type="radio"></input>

                        <b className='p-0 m-0'> Yes
                        </b>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <b className='p-0 m-0'>No</b>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <b className='p-0 m-0'>  It depends on the timing of the attack
</b>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>

                        <b className='p-0 m-0'>It depends on whether HTTP or HTTPS is used</b>
                    </div>
                    <div className=' d-flex p-0 m-0'>
                    <input type="radio"></input>
                        <b className='p-0 m-0'>There is no way to tell
                        </b>
                    </div>
                </div>
                <div className=' submit col-1 mx-auto '>
                    <button className='rounded pe-3 ps-3 m-2'>
                        Submit
                    </button>
                </div>
                <div className='janu w-60 ms-5 me-5 mt-2 border border-dark rounded text-center'>
                    <b> Choose the best answer or view Hints and Videos</b>
                </div>
                <div className='d-flex justify-content-center m-2 fs-5'>
                    <a className='  text-decoration-none p-2 '>
                        Previous
                    </a>
                    <div className='p-2'>|</div>
                    <a className='  text-decoration-none p-2 fs-5 '>
                        Next
                    </a>

                </div>
            </StyledLab29>
        </div>
    )
}

export default Lab29

const StyledLab29 = styled.div
    `
.box1{
  background-color:#ffe5bc;
  margin-top: 8%;
}
.janu{
  background-color: #d8d8d5;}
  
`