import React from "react";

const Lab54 = () => {
  return (
    <div className="mt-2 p-4">
      <div className=" border border-1 border-black title rounded-3">
        <p className=" text-center fs-6 fw-bold p-1 m-0">
          Which version of SSL does the Mutillidae web server implement?
        </p>
      </div>

      <div>
        <tr>
          <input type="radio" />
          <th> SSL version 0.5</th>
        </tr>

        <tr>
          <input type="radio" />
          <th>SSL version 1</th>
        </tr>

        <tr>
          <input type="radio" />
          <th> SSL version 2</th>
        </tr>

        <tr>
          <input type="radio" />
          <th>SSL version 3</th>
        </tr>

        <tr>
          <input type="radio" />
          <th>The web server does not implement any version of SSL; only TLS</th>
        </tr>
      </div>

      <div className="text-center">
        <div className="btn btn-secondary border border-1 border-black mx-auto ps-3 pe-3 m-0 ">
          Submite
        </div>
      </div>

      <div className="bg-secondary text-center border border-1 border-black rounded-3 mt-4">
        <b>Choose the best answer or view Hints and Videos</b>
      </div>

      <div className="d-flex  mt-2 justify-content-center">
        <a className=" text-decoration-none fw-semibold " href="#">
          Privew Lab
        </a>{" "}
        |
        <a className=" text-decoration-none fw-semibold " href="#">
          Next Lab
        </a>
      </div>
    </div>
  );
};

export default Lab54;
